import { ElencoAssetDto, ProjectElencoAssetDto } from '@api/client';
import { Dispatch, SetStateAction } from 'react';

const useHandleAssetEdit = (
  assets: ElencoAssetDto[],
  setProjectAssets: Dispatch<SetStateAction<ProjectElencoAssetDto[]>>
) => {
  const handleSelectChange = (e: number, index: number) => {
    setProjectAssets((prev) => {
      return prev?.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            idElencoAsset: e,
            titoloAsset: assets.find((asset) => asset.id === e)?.title,
          };
        }
        return item;
      });
    });
  };

  const handleChangeImporto = (e: any, id: number | undefined) => {
    if (!id) return;
    setProjectAssets((prev) => {
      return prev?.map((item) => {
        if (item.idElencoAsset === id) {
          return {
            ...item,
            importo: e,
          };
        }
        return item;
      });
    });
  };

  return {
    handleSelectChange,
    handleChangeImporto,
  };
};

export default useHandleAssetEdit;

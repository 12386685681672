/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AziendaFromJSON, AziendaToJSON, } from './Azienda';
import { FornitoreFromJSON, FornitoreToJSON, } from './Fornitore';
import { OrderCategoryFromJSON, OrderCategoryToJSON, } from './OrderCategory';
import { OrderContractStatusFromJSON, OrderContractStatusToJSON, } from './OrderContractStatus';
import { OrderExpenseItemFromJSON, OrderExpenseItemToJSON, } from './OrderExpenseItem';
import { OrderProcessingStatusFromJSON, OrderProcessingStatusToJSON, } from './OrderProcessingStatus';
import { OrderTypeFromJSON, OrderTypeToJSON, } from './OrderType';
import { ProjectExternalCostFromJSON, ProjectExternalCostToJSON, } from './ProjectExternalCost';
import { ProjectLightInfoDtoFromJSON, ProjectLightInfoDtoToJSON, } from './ProjectLightInfoDto';
import { SedeFromJSON, SedeToJSON, } from './Sede';
import { StrutturaFunzioneFromJSON, StrutturaFunzioneToJSON, } from './StrutturaFunzione';
/**
 * Check if a given object implements the ContractOrderOutputDto interface.
 */
export function instanceOfContractOrderOutputDto(value) {
    return true;
}
export function ContractOrderOutputDtoFromJSON(json) {
    return ContractOrderOutputDtoFromJSONTyped(json, false);
}
export function ContractOrderOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'contractStatus': json['contractStatus'] == null ? undefined : OrderContractStatusFromJSON(json['contractStatus']),
        'processingStatus': json['processingStatus'] == null ? undefined : OrderProcessingStatusFromJSON(json['processingStatus']),
        'orderType': json['orderType'] == null ? undefined : OrderTypeFromJSON(json['orderType']),
        'contractValue': json['contractValue'] == null ? undefined : json['contractValue'],
        'resaleProjectCode': json['resaleProjectCode'] == null ? undefined : json['resaleProjectCode'],
        'resaleDetachmentRate': json['resaleDetachmentRate'] == null ? undefined : json['resaleDetachmentRate'],
        'automaticRenewal': json['automaticRenewal'] == null ? undefined : json['automaticRenewal'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'signatureDate': json['signatureDate'] == null ? undefined : (new Date(json['signatureDate'])),
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'lastmodifiedDate': json['lastmodifiedDate'] == null ? undefined : (new Date(json['lastmodifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'projectExternalCost': json['projectExternalCost'] == null ? undefined : (json['projectExternalCost'].map(ProjectExternalCostFromJSON)),
        'associatedProjectActiveOrder': json['associatedProjectActiveOrder'] == null ? undefined : (json['associatedProjectActiveOrder'].map(ProjectLightInfoDtoFromJSON)),
        'associatedFattureProgetto': json['associatedFattureProgetto'] == null ? undefined : json['associatedFattureProgetto'],
        'expenseItems': json['expenseItems'] == null ? undefined : (json['expenseItems'].map(OrderExpenseItemFromJSON)),
        'remainingBalance': json['remainingBalance'] == null ? undefined : json['remainingBalance'],
        'note': json['note'] == null ? undefined : json['note'],
        'isDeleted': json['isDeleted'] == null ? undefined : json['isDeleted'],
        'idFornitore': json['idFornitore'] == null ? undefined : FornitoreFromJSON(json['idFornitore']),
        'linkDocumentation': json['linkDocumentation'] == null ? undefined : json['linkDocumentation'],
        'category': json['category'] == null ? undefined : OrderCategoryFromJSON(json['category']),
        'legalEntity': json['legalEntity'] == null ? undefined : AziendaFromJSON(json['legalEntity']),
        'cancellationWithin': json['cancellationWithin'] == null ? undefined : (new Date(json['cancellationWithin'])),
        'cancelAtAnyTime': json['cancelAtAnyTime'] == null ? undefined : json['cancelAtAnyTime'],
        'withdrawalWithin': json['withdrawalWithin'] == null ? undefined : (new Date(json['withdrawalWithin'])),
        'cancellationProcedure': json['cancellationProcedure'] == null ? undefined : json['cancellationProcedure'],
        'object': json['object'] == null ? undefined : json['object'],
        'withdrawalWithinLiteral': json['withdrawalWithinLiteral'] == null ? undefined : json['withdrawalWithinLiteral'],
        'idExternalContract': json['idExternalContract'] == null ? undefined : json['idExternalContract'],
        'fkUtente': json['fkUtente'] == null ? undefined : json['fkUtente'],
        'idSede': json['idSede'] == null ? undefined : SedeFromJSON(json['idSede']),
        'linkTicket': json['linkTicket'] == null ? undefined : json['linkTicket'],
        'pkOrderSF': json['pkOrderSF'] == null ? undefined : json['pkOrderSF'],
        'fkCliente': json['fkCliente'] == null ? undefined : json['fkCliente'],
        'denominazioneCliente': json['denominazioneCliente'] == null ? undefined : json['denominazioneCliente'],
        'linkOrdine': json['linkOrdine'] == null ? undefined : json['linkOrdine'],
        'fde': json['fde'] == null ? undefined : json['fde'],
        'ordStato': json['ordStato'] == null ? undefined : json['ordStato'],
        'fkStrutturaBusiness': json['fkStrutturaBusiness'] == null ? undefined : json['fkStrutturaBusiness'],
        'ordFattureEmesse': json['ordFattureEmesse'] == null ? undefined : json['ordFattureEmesse'],
        'strutturaFunzione': json['strutturaFunzione'] == null ? undefined : StrutturaFunzioneFromJSON(json['strutturaFunzione']),
        'ordARicavi': json['ordARicavi'] == null ? undefined : json['ordARicavi'],
        'ordImportoRti': json['ordImportoRti'] == null ? undefined : json['ordImportoRti'],
        'ordQuotaLinks': json['ordQuotaLinks'] == null ? undefined : json['ordQuotaLinks'],
        'ordNoteRTI': json['ordNoteRTI'] == null ? undefined : json['ordNoteRTI'],
        'ordOggetto': json['ordOggetto'] == null ? undefined : json['ordOggetto'],
        'ordIban': json['ordIban'] == null ? undefined : json['ordIban'],
        'ordNote': json['ordNote'] == null ? undefined : json['ordNote'],
        'ordIva': json['ordIva'] == null ? undefined : json['ordIva'],
        'ordCig': json['ordCig'] == null ? undefined : json['ordCig'],
        'ordCup': json['ordCup'] == null ? undefined : json['ordCup'],
        'ordSdi': json['ordSdi'] == null ? undefined : json['ordSdi'],
        'ordTermGG': json['ordTermGG'] == null ? undefined : json['ordTermGG'],
        'ordPrjTotAmount': json['ordPrjTotAmount'] == null ? undefined : json['ordPrjTotAmount'],
        'ordPoNumber': json['ordPoNumber'] == null ? undefined : json['ordPoNumber'],
        'dataPo': json['dataPo'] == null ? undefined : (new Date(json['dataPo'])),
        'fkUtenteEsterno': json['fkUtenteEsterno'] == null ? undefined : json['fkUtenteEsterno'],
        'tariffa': json['tariffa'] == null ? undefined : json['tariffa'],
        'quantita': json['quantita'] == null ? undefined : json['quantita'],
        'ordCPTotFattureRicevute': json['ordCPTotFattureRicevute'] == null ? undefined : json['ordCPTotFattureRicevute'],
        'ordCPResiduo': json['ordCPResiduo'] == null ? undefined : json['ordCPResiduo'],
        'ordCPFdR': json['ordCPFdR'] == null ? undefined : json['ordCPFdR'],
        'ordCPACostiEsterni': json['ordCPACostiEsterni'] == null ? undefined : json['ordCPACostiEsterni'],
    };
}
export function ContractOrderOutputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'contractStatus': OrderContractStatusToJSON(value['contractStatus']),
        'processingStatus': OrderProcessingStatusToJSON(value['processingStatus']),
        'orderType': OrderTypeToJSON(value['orderType']),
        'contractValue': value['contractValue'],
        'resaleProjectCode': value['resaleProjectCode'],
        'resaleDetachmentRate': value['resaleDetachmentRate'],
        'automaticRenewal': value['automaticRenewal'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'signatureDate': value['signatureDate'] == null ? undefined : ((value['signatureDate']).toISOString()),
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'lastmodifiedDate': value['lastmodifiedDate'] == null ? undefined : ((value['lastmodifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
        'projectExternalCost': value['projectExternalCost'] == null ? undefined : (value['projectExternalCost'].map(ProjectExternalCostToJSON)),
        'associatedProjectActiveOrder': value['associatedProjectActiveOrder'] == null ? undefined : (value['associatedProjectActiveOrder'].map(ProjectLightInfoDtoToJSON)),
        'associatedFattureProgetto': value['associatedFattureProgetto'],
        'expenseItems': value['expenseItems'] == null ? undefined : (value['expenseItems'].map(OrderExpenseItemToJSON)),
        'remainingBalance': value['remainingBalance'],
        'note': value['note'],
        'isDeleted': value['isDeleted'],
        'idFornitore': FornitoreToJSON(value['idFornitore']),
        'linkDocumentation': value['linkDocumentation'],
        'category': OrderCategoryToJSON(value['category']),
        'legalEntity': AziendaToJSON(value['legalEntity']),
        'cancellationWithin': value['cancellationWithin'] == null ? undefined : ((value['cancellationWithin']).toISOString()),
        'cancelAtAnyTime': value['cancelAtAnyTime'],
        'withdrawalWithin': value['withdrawalWithin'] == null ? undefined : ((value['withdrawalWithin']).toISOString()),
        'cancellationProcedure': value['cancellationProcedure'],
        'object': value['object'],
        'withdrawalWithinLiteral': value['withdrawalWithinLiteral'],
        'idExternalContract': value['idExternalContract'],
        'fkUtente': value['fkUtente'],
        'idSede': SedeToJSON(value['idSede']),
        'linkTicket': value['linkTicket'],
        'pkOrderSF': value['pkOrderSF'],
        'fkCliente': value['fkCliente'],
        'denominazioneCliente': value['denominazioneCliente'],
        'linkOrdine': value['linkOrdine'],
        'fde': value['fde'],
        'ordStato': value['ordStato'],
        'fkStrutturaBusiness': value['fkStrutturaBusiness'],
        'ordFattureEmesse': value['ordFattureEmesse'],
        'strutturaFunzione': StrutturaFunzioneToJSON(value['strutturaFunzione']),
        'ordARicavi': value['ordARicavi'],
        'ordImportoRti': value['ordImportoRti'],
        'ordQuotaLinks': value['ordQuotaLinks'],
        'ordNoteRTI': value['ordNoteRTI'],
        'ordOggetto': value['ordOggetto'],
        'ordIban': value['ordIban'],
        'ordNote': value['ordNote'],
        'ordIva': value['ordIva'],
        'ordCig': value['ordCig'],
        'ordCup': value['ordCup'],
        'ordSdi': value['ordSdi'],
        'ordTermGG': value['ordTermGG'],
        'ordPrjTotAmount': value['ordPrjTotAmount'],
        'ordPoNumber': value['ordPoNumber'],
        'dataPo': value['dataPo'] == null ? undefined : ((value['dataPo']).toISOString()),
        'fkUtenteEsterno': value['fkUtenteEsterno'],
        'tariffa': value['tariffa'],
        'quantita': value['quantita'],
        'ordCPTotFattureRicevute': value['ordCPTotFattureRicevute'],
        'ordCPResiduo': value['ordCPResiduo'],
        'ordCPFdR': value['ordCPFdR'],
        'ordCPACostiEsterni': value['ordCPACostiEsterni'],
    };
}

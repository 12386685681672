import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'shared/utils/common.utils';
import { format } from 'date-fns';
import NoData from 'shared/design-system/components/no-data-box';
import useGetEmittedBills from './_hooks/useGetEmittedBills';

interface Props {
  projectId: number;
}

const EmittedBills: React.FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const { emittedBills } = useGetEmittedBills(projectId);

  return (
    <>
      {emittedBills?.length ? (
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 315px)' }}>
          <Table bordered>
            <thead>
              <tr>
                <th className="align-content-center">{t('Row bill ID')}</th>
                <th className="align-content-center">{t('Bill ID')}</th>
                <th className="align-content-center">{t('N. bill')}</th>
                <th className="align-content-center">{t('Amount')}</th>
                <th className="align-content-center">{t('Date')}</th>
                <th className="align-content-center">{t('Expiring date')}</th>
                <th className="align-content-center">{t('Payment date')}</th>
                <th className="align-content-center">{t('Type')}</th>
                <th className="align-content-center">{t('Account')}</th>
                <th className="align-content-center">{t('Description')}</th>
                <th className="align-content-center">{t('Accounting status')}</th>
                <th className="align-content-center">{t('Status')}</th>
              </tr>
            </thead>
            <tbody>
              {emittedBills?.map((bill) => (
                <tr key={bill.idRowFattura}>
                  <td>
                    <p>{bill.idRowFattura}</p>
                  </td>
                  <td>
                    <p>{bill.pkFattura}</p>
                  </td>
                  <td>
                    <p>{bill.numeroFattura}</p>
                  </td>
                  <td className="text-end">
                    <p>{`${formatCurrency(bill.imponibile)}€`}</p>
                  </td>
                  <td>
                    <p>{bill.dataFattura ? format(bill.dataFattura, 'dd/MM/yy') : '-'}</p>
                  </td>
                  <td>
                    <p>{bill.dataScadenza ? format(bill.dataScadenza, 'dd/MM/yy') : '-'}</p>
                  </td>
                  <td>
                    <p>{bill.dataPagamento ? format(bill.dataPagamento, 'dd/MM/yy') : '-'}</p>
                  </td>
                  <td>
                    <p>{bill.tipoFattura}</p>
                  </td>
                  <td>
                    <p>{bill.cliente}</p>
                  </td>
                  <td>
                    <p className="my-auto" title={bill.descrizione || '-'}>
                      {bill.descrizione
                        ? `${bill.descrizione.slice(0, 100)}${bill.descrizione.length > 100 ? '…' : ''}`
                        : '-'}
                    </p>
                  </td>
                  <td>
                    <p>{bill.fattStatoContabile}</p>
                  </td>
                  <td>
                    <p>{bill.fattStato}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <NoData msg={t('project-detail.billing-plan.no-invoices')} />
        </div>
      )}
    </>
  );
};

export default EmittedBills;

import {
  CicloPassivoFieldsDto,
  ContractOrderOutputDto,
  ContractOrderSearchDto,
  FornitoreDto,
  ProjectCicloPassivoOutputDto,
} from '@api/client';
import {
  createOrder,
  getOtherAssociatibleCosts,
  getPassiveCycleOptions,
  getSupplierById,
  getSuppliers,
  saveFiltersOrdersUsed,
  searchOrders,
  searchOrdersForBills,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';
interface InitialState {
  passiveCycleOptions: CicloPassivoFieldsDto | undefined;
  orders: ContractOrderOutputDto[];
  currentPassiveCycleOrder: ContractOrderOutputDto | undefined;
  ordersForBills: ContractOrderOutputDto[];
  filtersOrdersUsed: ContractOrderSearchDto | undefined;
  associableOtherCostsProjects: ProjectCicloPassivoOutputDto[];
  suppliers: FornitoreDto[];
}

const initialState: InitialState = {
  passiveCycleOptions: undefined,
  orders: [],
  currentPassiveCycleOrder: undefined,
  ordersForBills: [],
  filtersOrdersUsed: undefined,
  associableOtherCostsProjects: [],
  suppliers: [],
};

export const passiveCycleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPassiveCycleOptions.fulfilled, (state, action) => {
      state.passiveCycleOptions = action.payload;
    })
    .addCase(createOrder.fulfilled, (state, action) => {
      // AppToastService.success('Order created successfully');
    })
    .addCase(searchOrders.fulfilled, (state, action) => {
      const { skipSave, currentOrderId } = action.meta.arg; // Recupera il flag
      if (skipSave) return;
      if (currentOrderId) {
        state.currentPassiveCycleOrder = action.payload?.contractOrderOutputDtoList?.[0];
      } else {
        state.orders = action.payload?.contractOrderOutputDtoList || [];
      }
    })
    .addCase(searchOrdersForBills.fulfilled, (state, action) => {
      state.ordersForBills = action.payload?.contractOrderOutputDtoList || [];
    })
    .addCase(saveFiltersOrdersUsed, (state, action) => {
      state.filtersOrdersUsed = action.payload;
    })
    .addCase(getOtherAssociatibleCosts.fulfilled, (state, action) => {
      state.associableOtherCostsProjects = action.payload?.projectCicloPassivoOutputDtoList || [];
    })
    .addCase(getSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload || [];
    })
    .addCase(getSupplierById.fulfilled, (state, action) => {
      state.suppliers = action.payload ? [action.payload] : [];
    });
});

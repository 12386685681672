/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ElencoAssetInputDto interface.
 */
export function instanceOfElencoAssetInputDto(value) {
    return true;
}
export function ElencoAssetInputDtoFromJSON(json) {
    return ElencoAssetInputDtoFromJSONTyped(json, false);
}
export function ElencoAssetInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['title'] == null ? undefined : json['title'],
        'pricing': json['pricing'] == null ? undefined : json['pricing'],
    };
}
export function ElencoAssetInputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'title': value['title'],
        'pricing': value['pricing'],
    };
}

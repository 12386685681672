import { useAppDispatch } from 'core/store/hooks';
import { deleteProjectAsset } from '../_redux/actions';
import { DeleteProgettoAssetRequest } from '@api/client';
import { useState } from 'react';

const useDeleteProjectAsset = (getProjectAssets: () => void) => {
  const dispatch = useAppDispatch();
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const deleteAssetFromProject = () => {
    if (!idToDelete) return;
    const paylaod: DeleteProgettoAssetRequest = {
      id: idToDelete,
    };
    dispatch(deleteProjectAsset(paylaod)).then(() => getProjectAssets());
  };

  return { deleteAssetFromProject, setIdToDelete };
};

export default useDeleteProjectAsset;

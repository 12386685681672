import { BillingDto, ModificaStatoAvanzamentoRequest } from '@api/client';
import { useAppDispatch } from 'core/store/hooks';
import { getBillingPlan, updateStatoAvanzamentoBilling } from '../_redux/actions';
import { useState } from 'react';

const useSaveBill = (projectId: number, setIsBillingDirty: React.Dispatch<React.SetStateAction<boolean>>) => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<Record<string, { date: boolean; block: boolean; amount: boolean }>>({});

  const handleSaveBill = (billing: BillingDto) => {
    const payload: ModificaStatoAvanzamentoRequest = {
      statoAvanzamentoLavoriDto: {
        idInvoiceMilestone: billing.id,
        isInviato: billing.statoAvanzamentoLavori?.isInviato,
        dataDiInvio: billing.statoAvanzamentoLavori?.dataDiInvio,
        tipologiaBlocco: billing.statoAvanzamentoLavori?.idTipologiaBlocco?.value,
        importo: Number(billing.statoAvanzamentoLavori?.importo),
      },
    };
    dispatch(updateStatoAvanzamentoBilling(payload))
      .unwrap()
      .then(() => {
        setIsBillingDirty(false);
        dispatch(getBillingPlan(projectId));
      });
  };

  const handleSaveWithValidation = (billing: BillingDto) => {
    const newErrors = {
      date: !billing.statoAvanzamentoLavori?.dataDiInvio,
      block: !billing.statoAvanzamentoLavori?.idTipologiaBlocco?.value,
      amount: !billing.statoAvanzamentoLavori?.importo,
    };

    setErrors((prev) => ({ ...prev, [billing.id as string]: { ...newErrors } }));

    if (!newErrors.date && !newErrors.block && !newErrors.amount) {
      handleSaveBill(billing);
    }
  };

  return { errors, handleSaveWithValidation };
};

export default useSaveBill;

import { FatturaEmessaTabDTO } from '@api/client';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { emittedBillsSelector } from '../../../_redux/selectors';
import { getEmittedBills } from '../../../_redux/actions';

const useGetEmittedBills = (projectId: number) => {
  const dispatch = useAppDispatch();
  const billsSelector = useAppSelector(emittedBillsSelector);
  const [emittedBills, setEmittedBills] = useState<FatturaEmessaTabDTO[] | undefined>(undefined);

  useEffect(() => {
    if (!billsSelector) {
      dispatch(getEmittedBills(projectId));
    }
  }, []);

  useEffect(() => {
    if (billsSelector) {
      setEmittedBills(billsSelector);
    }
  }, [billsSelector]);

  return { emittedBills };
};

export default useGetEmittedBills;

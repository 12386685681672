import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { chipsData } from 'enums/chipsData';
import { allUsersSelector } from 'pages/private/project-list/project-detail/_redux/selectors';
import { filtersDashboardProjectSelector } from 'pages/private/dashboard/_redux/selectors';
import useGetSessionActions from 'pages/private/management-tools/_hooks/useGetSessionActions';
import { FilterProjectMonthValuesDto } from '@api/client';
import useRenderChipContent from './useRenderChipContent';
import useRenderChipTooltip from './useRenderChipTooltip';
import useRemoveChipAndSave from './useRemoveChipAndSave';

export function useHeader(formData: any, location?: any, reset?: any, setChipsFormData?: any) {
  const dispatch = useAppDispatch();
  const { filtersSelector, saveFiltersUsedAction } = useGetSessionActions(location);
  const filtriMT: FilterProjectMonthValuesDto | undefined | null = useAppSelector(filtersSelector());
  const filtriProjectDashboard = useAppSelector(filtersDashboardProjectSelector);
  const allUsers = useAppSelector(allUsersSelector);
  const {
    renderCustomerContent,
    renderDateRangeContent,
    renderDateContent,
    renderMesiAnniContent,
    renderPersonContent,
    renderPriceRangeContent,
    renderUnitContent,
    renderMultiSelectContent,
  } = useRenderChipContent({ filtriMT, allUsers, filtriProjectDashboard });

  const {
    renderCollaboratorListTooltip,
    renderStatusTooltip,
    renderTipologyTooltip,
    renderContractStatusTooltip,
    renderOrderProcessingStatusTooltip,
    renderExpenseItemTooltip,
    renderSupplierTooltip,
    renderCategoriesTooltip,
    renderLegalEntitiesTooltip,
  } = useRenderChipTooltip({
    allUsers,
  });

  const { handleRemoveChip } = useRemoveChipAndSave({
    setChipsFormData,
    formData,
    filtriMT,
    reset,
    location,
    dispatch,
    saveFiltersUsedAction,
  });

  const getChipNameByKey = (chipKey: string) => {
    const chipEntries = Object.entries(chipsData);
    for (const [_, chipInfo] of chipEntries) {
      if (chipInfo.key === chipKey) {
        return chipInfo.name;
      }
    }
    return chipKey;
  };

  const renderDescription = (chips: any[]) => {
    const chipName = getChipNameByKey(chips[0]);
    if (chips[1] === null || chips[1] === undefined || chips[0] === 'paging') return null;
    let description = `${chipName} - ${chips[1]}`;
    switch (chips[0]) {
      case chipsData.status.key:
      case chipsData.stati.key:
      case chipsData.projectStatus.key:
        description = renderMultiSelectContent(chips[1], 'project-list.status-selected');
        break;
      case chipsData.typology.key:
      case chipsData.projectType.key:
        description = renderMultiSelectContent(chips[1], 'project-list.typologies-selected');
        break;
      case chipsData.businessUnit.key:
      case chipsData.deliveryUnit.key:
        description = renderUnitContent(chips[0], chips[1]);
        break;
      case chipsData.personaId.key:
        description = renderPersonContent(chips[0], chips[1]);
        break;
      case chipsData.plUserId.key:
        description = renderPersonContent(chipsData.plUserId.name, chips[1]);
        break;
      case chipsData.mesiAnni.key:
        description = renderMesiAnniContent(chips[1], true);
        break;
      case chipsData.priceRange.key:
        description = renderPriceRangeContent(chips[1]);
        break;
      case chipsData.dateRange.key:
        description = renderDateRangeContent(chips[1], 'project-list.date-last-modify');
        break;
      case chipsData.personaIdList.key:
        description = renderMultiSelectContent(chips[1], 'project-list.collaborators-selected');
        break;
      case chipsData.idCliente.key:
        description = renderCustomerContent(chips[1]);
        break;
      case chipsData.legalEntities.key:
        description = renderMultiSelectContent(chips[1], 'passive-cycle.filter-chips.legal-entities-selected');
        break;
      case chipsData.contractStatus.key:
        description = renderMultiSelectContent(chips[1], 'passive-cycle.filter-chips.contract-status-selected');
        break;
      case chipsData.orderProcessingStatus.key:
        description = renderMultiSelectContent(chips[1], 'passive-cycle.filter-chips.order-processing-status-selected');
        break;
      case chipsData.suppliers.key:
        description = renderMultiSelectContent(chips[1], 'passive-cycle.filter-chips.suppliers-selected');
        break;
      case chipsData.expenseItems.key:
        description = renderMultiSelectContent(chips[1], 'passive-cycle.filter-chips.expense-items-selected');
        break;
      case chipsData.categories.key:
        description = renderMultiSelectContent(chips[1], 'passive-cycle.filter-chips.categories-selected');
        break;
      case chipsData.signatureRange.key:
        description = renderDateRangeContent(chips[1], 'passive-cycle.filter-chips.signature-date');
        break;
      case chipsData.startEndRange.key:
        description = renderDateRangeContent(chips[1], 'passive-cycle.filter-chips.start-end-range');
        break;
      case chipsData.dataPo.key:
        description = renderDateContent(chips[1], 'PO date');
        break;
      default:
        description = `${chipName} - ${chips[1]}`;
        break;
    }
    return description;
  };

  const getTooltipContent = (chips: any) => {
    switch (chips[0]) {
      case chipsData.status.key:
      case chipsData.stati.key:
      case chipsData.projectStatus.key:
        return renderStatusTooltip(chips[1]);
      case chipsData.typology.key:
      case chipsData.projectType.key:
        return renderTipologyTooltip(chips[1]);
      case chipsData.personaIdList.key:
        return renderCollaboratorListTooltip(chips[1]);
      case chipsData.dateRange.key:
        return renderDateRangeContent(chips[1], 'project-list.date-last-modify');
      case chipsData.mesiAnni.key:
        return renderMesiAnniContent(chips[1]);
      case chipsData.contractStatus.key:
        return renderContractStatusTooltip(chips[1]);
      case chipsData.orderProcessingStatus.key:
        return renderOrderProcessingStatusTooltip(chips[1]);
      case chipsData.expenseItems.key:
        return renderExpenseItemTooltip(chips[1]);
      case chipsData.suppliers.key:
        return renderSupplierTooltip(chips[1]);
      case chipsData.categories.key:
        return renderCategoriesTooltip(chips[1]);
      case chipsData.legalEntities.key:
        return renderLegalEntitiesTooltip(chips[1]);
      default:
        return chips[1];
    }
  };

  return {
    handleRemoveChip,
    getChipNameByKey,
    renderDescription,
    getTooltipContent,
  };
}

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { DashboardProjectFilterInputDto, FilterDtoStatoEnum, GetProjectListRequest } from '@api/client';
import { StorageKeysEnum } from 'enums/storageKeys.enum';
import {
  filtersDashboardProjectSelector,
  filtersDashboardProjectUsedSelector,
} from 'pages/private/dashboard/_redux/selectors';
import {
  getDashboardProjectFilters,
  getDashboardProjects,
  getDashboardProjectsCurrentYear,
  saveDashboardProjectFilters,
} from 'pages/private/dashboard/_redux/actions';
import { DashboardProjectsPeriod } from 'enums/project.enum';

const useFormDashboardProjects = (initialData: any, period: DashboardProjectsPeriod) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const filters = useAppSelector(filtersDashboardProjectSelector);
  const filtersUsed = useAppSelector(filtersDashboardProjectUsedSelector);
  const [chipsFormData, setChipsFormData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);

  useEffect(() => {
    if (!filters) {
      dispatch(getDashboardProjectFilters());
    }
  }, [dispatch, filters]);

  const { reset, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  const onSubmit = (data: any) => {
    sessionStorage.setItem(StorageKeysEnum.CURRENT_PAGE_DASHBOARD_PROJECTS, String(0));
    isSubmitting.current = true;
    const formDataArray = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormData([...formDataArray]);

    const filtersDashboard: DashboardProjectFilterInputDto = {};

    if (data.id) {
      filtersDashboard.id = Number(data.id);
    }
    if (data.nome) {
      filtersDashboard.nome = data.nome;
    }
    if (data.businessUnit) {
      filtersDashboard.idStrutturaBusiness = filters?.businessUnit?.find((item) => item.name === data.businessUnit)?.id;
    }
    if (data.idCliente) {
      filtersDashboard.idCliente = data.idCliente;
    }
    if (data.plUserId) {
      filtersDashboard.plUserId = data.plUserId;
    }
    if (data.projectStatus) {
      filtersDashboard.projectStatus = data.projectStatus
        .map((status: string) => status.toUpperCase().replace(/\s+/g, '_'))
        .flatMap((status: string) =>
          status === FilterDtoStatoEnum.WorkInProgress
            ? [status, FilterDtoStatoEnum.ControlloCl, FilterDtoStatoEnum.ControlloCdg]
            : [status]
        );
    }

    const payload: GetProjectListRequest = {
      dashboardProjectFilterInputDto: { ...filtersDashboard, size: 10000 },
    };

    dispatch(saveDashboardProjectFilters(filtersDashboard));
    const getProjectAction =
      period === DashboardProjectsPeriod.ALL ? getDashboardProjects : getDashboardProjectsCurrentYear;
    dispatch(getProjectAction(payload))
      .unwrap()
      .then((res: any) => {});

    handleSubmit(data);
  };

  const onReset = () => {
    reset(initialData);
    setChipsFormData([]);
    dispatch(saveDashboardProjectFilters(initialData));
  };

  return { onSubmit, onReset, filterParams, chipsFormData, setChipsFormData, reset };
};

export default useFormDashboardProjects;

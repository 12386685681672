import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { useTranslation } from 'react-i18next';
import AppCard from 'shared/design-system/components/app-card';
import LinksButton from 'shared/design-system/components/links-button';
import { useEffect, useState } from 'react';
import AppAccordion from 'shared/design-system/components/app-accordion';
import AccordionForm, {
  projectListFormData,
} from 'shared/design-system/components/app-accordion/_components/accordion-form';
import useAccordionSubmit, {
  FilterProjectsLocation,
} from 'shared/design-system/components/app-accordion/_hooks/useFormSubmit';
import OrderData from './_components/order_data';
import { currentPassiveCycleOrderSelector, ordersSelector } from '../../_redux/selectors';
import { ContractOrderOutputDto } from '@api/client';
import NoData from 'shared/design-system/components/no-data-box';
import { searchOrders } from '../../_redux/actions';
import PassiveCycleProjectCard from './_components/passive-cycle-project-card';
import React from 'react';
import PassiveCycleProjectAssociatedCard from './_components/passive-cycle-project-associated-card';
import useHandleAssociableProjects from './_hooks/useHandleAssociableProjects';
import { LocationFilterEnum } from 'enums/global.enum';
import useGetAllUsers from 'shared/_hooks/useGetAllUsers';
import { Button } from 'react-bootstrap';
import PassiveOrderAssociatedInvoicesTable from './_components/passive-order-associated-invoices-table';
import { getFattureRicevute } from 'pages/private/active-cycle/active-cycle-bills/_redux/actions';
import useFormAssociablePassiveInvoices from 'shared/design-system/components/app-accordion/_hooks/useFormAssociablePassiveInvoices';
import {
  fattureRicevuteAssociableSelector,
  fattureRicevuteAssociatedSelector,
} from 'pages/private/active-cycle/active-cycle-bills/_redux/selectors';
import AssociablePassiveInvoicesFilterForm, {
  associablePassiveInvoicesFormData,
} from './_components/associable-passive-invoices-filter-form';
import PassiveOrderEconomicsData from './_components/passive-order-economics-data';

const PassiveCycleOrderDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: any }>();
  const allUsers = useGetAllUsers();
  const [currentOrder, setCurrentOrder] = useState<ContractOrderOutputDto | undefined>(undefined);
  const [isAssociateNewInvoiceVisible, setIsAssociateNewInvoiceVisible] = useState(false);
  const filteredOrders = useAppSelector(ordersSelector);
  const currentOrderSelector = useAppSelector(currentPassiveCycleOrderSelector);
  const fattureRicevuteAssociated = useAppSelector(fattureRicevuteAssociatedSelector);
  const fattureRicevuteAssociable = useAppSelector(fattureRicevuteAssociableSelector);
  const [isEconomicsSectionVisible, setIsEconomicsSectionVisible] = useState(true);
  const [isInvoiceSectionVisible, setIsInvoiceSectionVisible] = useState(true);
  const [isProjectSectionVisible, setIsProjectSectionVisible] = useState(true);

  useEffect(() => {
    if (currentOrderSelector) {
      setCurrentOrder(currentOrderSelector);
    } else if (filteredOrders.length) {
      const order = filteredOrders.find((order) => order.id === Number(id));
      setCurrentOrder(order);
    } else {
      dispatch(
        searchOrders({
          request: { contractOrderSearchDto: { orderId: Number(id), paging: { page: 0, size: 10 } } },
          currentOrderId: Number(id),
        })
      );
    }
  }, [filteredOrders, currentOrderSelector]);

  useEffect(() => {
    const orderId = currentOrder?.id;
    if (orderId) {
      dispatch(
        getFattureRicevute({
          request: { fatturaRicevutaSearchDto: { numeroOrdine: String(orderId), paging: { size: 9999, page: 0 } } },
          type: 'associated',
        })
      );
    }
  }, [currentOrder]);

  const [isAssociateNewProjectVisible, setIsAssociateNewProjectVisible] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useAccordionSubmit(
    projectListFormData,
    setIsOpen,
    FilterProjectsLocation.PASSIVE_CYCLE,
    currentOrder?.id
  );

  const [controlledDate, setControlledDate] = useState<any>(null);

  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };

  // INIZIO - Form filter invoices
  const [isOpenInvoice, setIsOpenInvoice] = useState<boolean>(true);
  const { onSubmitInvoices, onResetInvoices, chipsFormInvoicesData, setChipsFormInvoicesData, resetInvoice } =
    useFormAssociablePassiveInvoices(
      associablePassiveInvoicesFormData,
      setIsOpenInvoice,
      currentOrder?.startDate || new Date()
    );
  const handleResetInvoices = () => {
    onResetInvoices();
    chipsFormInvoicesData && setChipsFormInvoicesData([]);
  };
  // FINE - Form filter invoices

  useEffect(() => {
    if (isAssociateNewProjectVisible) {
      window.scrollBy({ top: 600, behavior: 'smooth' });
    }
  }, [isAssociateNewProjectVisible]);

  useEffect(() => {
    if (isAssociateNewInvoiceVisible) {
      window.scrollBy({ top: 250, behavior: 'smooth' });
    }
  }, [isAssociateNewInvoiceVisible]);

  const {
    associableProjects,
    externalCostsToAdd,
    handleAssociateCost,
    handleChangeAmount,
    associateExternalCosts,
    handleChangeDescription,
  } = useHandleAssociableProjects(currentOrder, setIsAssociateNewProjectVisible);

  return (
    <>
      <h4 className="text-gray-800 my-0">{t('Order')}</h4>

      {!currentOrder && (
        <AppCard title={t('Order data')} className="border-left-primary mt-3">
          <div className="mt-3">
            <NoData msg={t('No order found')} />
          </div>
        </AppCard>
      )}

      {currentOrder && (
        <>
          <OrderData order={currentOrder} allUsers={allUsers.allUsers} />
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <b className="text-blue-links">{t('Economics data')}</b>
            <Button
              className="btn text-blue-links border-none p-0"
              type="button"
              variant="icon"
              onClick={() => {
                setIsEconomicsSectionVisible(!isEconomicsSectionVisible);
              }}
            >
              {isEconomicsSectionVisible ? (
                <i className="fas fa-chevron-up text-blue-links" />
              ) : (
                <i className="fas fa-chevron-down text-blue-links" />
              )}
            </Button>
          </div>

          <hr className="solid mt-2" />
          {isEconomicsSectionVisible && <PassiveOrderEconomicsData order={currentOrder} />}

          {currentOrder.projectExternalCost?.length && (
            <>
              {/* ASSOCIATE INVOICE BUTTON */}
              <div className="d-flex justify-content-between my-3">
                <b className="text-blue-links">{t('Invoices received')}</b>
                <div>
                  {isInvoiceSectionVisible && (
                    <LinksButton
                      className="btn-sm btn-primary btn-teal px-4 ms-auto"
                      onClick={() => {
                        setIsAssociateNewInvoiceVisible(!isAssociateNewInvoiceVisible);
                      }}
                    >
                      {t('+ Associate invoice')}
                    </LinksButton>
                  )}
                  <Button
                    className="btn text-blue-links border-none p-0"
                    type="button"
                    variant="icon"
                    onClick={() => {
                      setIsInvoiceSectionVisible(!isInvoiceSectionVisible);
                      setIsAssociateNewInvoiceVisible(false);
                    }}
                  >
                    {isInvoiceSectionVisible ? (
                      <i className="fas fa-chevron-up text-blue-links" />
                    ) : (
                      <i className="fas fa-chevron-down text-blue-links" />
                    )}
                  </Button>
                </div>
              </div>
              <hr className="solid mt-2" />

              {/* ASSOCIATED INVOICES */}
              {isInvoiceSectionVisible && (
                <PassiveOrderAssociatedInvoicesTable
                  invoices={fattureRicevuteAssociated}
                  readOnly={true}
                  associatedProjects={currentOrder.projectExternalCost}
                  idOrdine={currentOrder.id}
                />
              )}

              {/* ASSOCIATE NEW INVOICE CARD */}
              {isAssociateNewInvoiceVisible && (
                <AppCard
                  title="Associate new invoice"
                  className="border-left-primary mt-3"
                  headerClassname="d-flex justify-content-between"
                  headerElement={
                    <Button
                      className="btn text-blue-links p-0"
                      type="button"
                      variant="icon"
                      onClick={() => setIsAssociateNewInvoiceVisible(false)}
                    >
                      <i className="fas fa-x text-blue-links" />
                    </Button>
                  }
                >
                  <AppAccordion
                    formData={associablePassiveInvoicesFormData}
                    location={LocationFilterEnum.PASSIVE_CYCLE_INVOICES}
                    setIsOpen={setIsOpenInvoice}
                    isOpen={isOpenInvoice}
                    chipsFormData={chipsFormInvoicesData}
                    setChipsFormData={setChipsFormInvoicesData}
                    reset={resetInvoice}
                  >
                    <AssociablePassiveInvoicesFilterForm
                      onSubmitInvoices={onSubmitInvoices}
                      handleResetInvoices={handleResetInvoices}
                    />
                  </AppAccordion>
                  <PassiveOrderAssociatedInvoicesTable
                    invoices={fattureRicevuteAssociable}
                    idOrdine={currentOrder.id}
                    associatedProjects={currentOrder.projectExternalCost}
                    readOnly={false}
                  />
                </AppCard>
              )}
            </>
          )}
          {/* ASSOCIATE COST BUTTON */}
          <div className="d-flex justify-content-between my-3">
            <b className="text-blue-links">{t('Projects and related external costs')}</b>
            <div>
              {isProjectSectionVisible && (
                <LinksButton
                  className="btn-sm btn-primary btn-teal px-4 ms-auto"
                  onClick={() => {
                    setIsAssociateNewProjectVisible(true);
                  }}
                >
                  {t('+ Associate')}
                </LinksButton>
              )}
              <Button
                className="btn text-blue-links border-none p-0"
                type="button"
                variant="icon"
                onClick={() => {
                  setIsProjectSectionVisible(!isProjectSectionVisible);
                  setIsAssociateNewProjectVisible(false);
                }}
              >
                {isProjectSectionVisible ? (
                  <i className="fas fa-chevron-up text-blue-links" />
                ) : (
                  <i className="fas fa-chevron-down text-blue-links" />
                )}
              </Button>
            </div>
          </div>
          <hr className="solid mt-2" />

          {/* ASSOCIATED COSTS */}
          {isProjectSectionVisible && (
            <>
              {currentOrder.projectExternalCost?.map((project) => {
                return (
                  <React.Fragment key={`associate_project_${project.projectId}`}>
                    <PassiveCycleProjectAssociatedCard associatedProject={project} contractOrderId={currentOrder.id} />
                  </React.Fragment>
                );
              })}
            </>
          )}

          {/* ASSOCIATE NEW PROJECT CARD */}
          {isAssociateNewProjectVisible && (
            <AppCard title="Associate new project" className="border-left-primary mt-3">
              <AppAccordion
                formData={projectListFormData}
                location={LocationFilterEnum.PROJECT_LIST}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                chipsFormData={chipsFormData}
                setChipsFormData={setChipsFormData}
                reset={reset}
              >
                <AccordionForm
                  onSubmit={onSubmit}
                  handleReset={handleReset}
                  setControlledDate={setControlledDate}
                  // onReset={onReset} control={control} register={register}
                />
              </AppAccordion>
              {associableProjects.map((project) => {
                return (
                  <React.Fragment key={`associable_project_${project.projectId}`}>
                    <PassiveCycleProjectCard
                      project={project}
                      handleChangeAssociated={handleAssociateCost}
                      handleChangeAmount={handleChangeAmount}
                      handleChangeDescription={handleChangeDescription}
                    />
                  </React.Fragment>
                );
              })}
              {associableProjects.length > 0 && (
                <div className="d-flex justify-content-end">
                  <LinksButton className="btn btn-links-outline" onClick={() => setIsAssociateNewProjectVisible(false)}>
                    {t('common.cancel')}
                  </LinksButton>
                  <LinksButton
                    className="btn-primary btn-links"
                    onClick={associateExternalCosts}
                    isDisabled={externalCostsToAdd.length === 0}
                  >
                    {t('common.associate')}
                  </LinksButton>
                </div>
              )}
            </AppCard>
          )}
        </>
      )}
    </>
  );
};

export default PassiveCycleOrderDetail;

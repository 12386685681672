import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PassiveCycleTabs } from 'enums/project.enum';
import { TabsProps } from 'antd';

const usePassiveCycleTabs = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>(PassiveCycleTabs.ORDERS);

  const passiveCycleTabs: TabsProps['items'] = [
    {
      key: PassiveCycleTabs.ORDERS,
      label: t('Orders'),
    },
    { key: PassiveCycleTabs.BILLS, label: t('Bills') },
    { key: PassiveCycleTabs.SUPPLIERS_CRUD, label: t('Suppliers crud') },
  ];

  const onChangeTab = (key: string) => {
    setSelectedTab(key);
  };

  return { selectedTab, passiveCycleTabs, onChangeTab };
};

export default usePassiveCycleTabs;

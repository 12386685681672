import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import {
  CicloPassivoFieldsDto,
  Configuration,
  ContractOrderOutputDto,
  ContractOrderOutputListDto,
  ContractOrderSearchDto,
  ControllerPerLeFunzioniDedicateAlCicloPassivoApi,
  CreateCicloPassivoRequest,
  CreateFornitoreRequest,
  FornitoreDto,
  GetProjectsRequest,
  ProjectCicloPassivoOutputListDto,
  RemoveExternalCosts1Request,
  SearchContractOrdersRequest,
  UpdateContractAndAssociateExternalCostsRequest,
  UpdateFornitoreRequest,
} from '@api/client';
import getConfiguration, { errorHandler } from 'core/store/config';

export enum PassiveCycleActions {
  getPassiveCycleOptions = '[PassiveCycle] Get PassiveCycle Options',
  createOrder = '[PassiveCycle] Create Order',
  searchOrders = '[PassiveCycle] Search Orders',
  searchOrdersForBills = '[PassiveCycle] Search Orders For Bills',
  updateOrder = '[PassiveCycle] Update Order',
  deleteOrder = '[PassiveCycle] Delete Order',
  getOtherAssociatibleCosts = '[PassiveCycle] Get Other Associatible Costs',
  saveFiltersOrdersUsed = '[PassiveCycle] Save Filters Orders Used',
  removeOtherCosts = '[PassiveCycle] Remove Other Costs',
  /* SUPPLIER CRUD */
  getSuppliers = '[PassiveCycle] Get Suppliers',
  getSupplierById = '[PassiveCycle] Get Supplier By Id',
  createSupplier = '[PassiveCycle] Create Supplier',
  updateSupplier = '[PassiveCycle] Update Supplier',
  deleteSupplier = '[PassiveCycle] Delete Supplier',
}

export const getPassiveCycleOptions = createAsyncThunk<CicloPassivoFieldsDto | undefined, void, ThunkApiConfig>(
  PassiveCycleActions.getPassiveCycleOptions,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).getcicloPassivoFields();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const createOrder = createAsyncThunk<
  ContractOrderOutputDto | undefined,
  CreateCicloPassivoRequest,
  ThunkApiConfig
>(PassiveCycleActions.createOrder, async (request, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).createCicloPassivo(request);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const searchOrders = createAsyncThunk<
  ContractOrderOutputListDto | undefined,
  { request: SearchContractOrdersRequest; skipSave?: boolean; currentOrderId?: number },
  ThunkApiConfig
>(PassiveCycleActions.searchOrders, async ({ request }, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).searchContractOrders1(request);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const searchOrdersForBills = createAsyncThunk<ContractOrderOutputListDto | undefined, void, ThunkApiConfig>(
  PassiveCycleActions.searchOrdersForBills,
  async (_, { dispatch, extra, getState }) => {
    const payload: SearchContractOrdersRequest = {
      contractOrderSearchDto: {},
    };
    try {
      return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).searchContractOrders1(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const updateOrder = createAsyncThunk<
  ContractOrderOutputDto | undefined,
  UpdateContractAndAssociateExternalCostsRequest,
  ThunkApiConfig
>(PassiveCycleActions.updateOrder, async (request, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).updateContractAndAssociateExternalCosts(request);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const deleteOrder = createAsyncThunk<number | undefined, number, ThunkApiConfig>(
  PassiveCycleActions.deleteOrder,
  async (id, { dispatch, extra, getState }) => {
    try {
      await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).deleteContractAndAssociations({ contractOrderId: id });
      return id;
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getOtherAssociatibleCosts = createAsyncThunk<
  ProjectCicloPassivoOutputListDto | undefined,
  GetProjectsRequest,
  ThunkApiConfig
>(PassiveCycleActions.getOtherAssociatibleCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).getProjects(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const removeOtherCosts = createAsyncThunk<
  ContractOrderOutputDto | undefined,
  RemoveExternalCosts1Request,
  ThunkApiConfig
>(PassiveCycleActions.removeOtherCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).removeExternalCosts1(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

/* SUPPLIER CRUD */
export const getSuppliers = createAsyncThunk<FornitoreDto[] | undefined, void, ThunkApiConfig>(
  PassiveCycleActions.getSuppliers,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).getAllFornitori();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getSupplierById = createAsyncThunk<FornitoreDto | undefined, number, ThunkApiConfig>(
  PassiveCycleActions.getSupplierById,
  async (id, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).getFornitoreById({ id });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const createSupplier = createAsyncThunk<FornitoreDto | undefined, CreateFornitoreRequest, ThunkApiConfig>(
  PassiveCycleActions.createSupplier,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).createFornitore(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const updateSupplier = createAsyncThunk<FornitoreDto | undefined, UpdateFornitoreRequest, ThunkApiConfig>(
  PassiveCycleActions.updateSupplier,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).updateFornitore(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const deleteSupplier = createAsyncThunk<void, number, ThunkApiConfig>(
  PassiveCycleActions.deleteSupplier,
  async (id, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).deleteFornitore({ id });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);
/* FINE SUPPLIER CRUD */

export const saveFiltersOrdersUsed = createAction<ContractOrderSearchDto>(PassiveCycleActions.saveFiltersOrdersUsed);

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoriaNoteDtoFromJSON, CategoriaNoteDtoToJSON, } from './CategoriaNoteDto';
/**
 * Check if a given object implements the CategoriaNoteListDto interface.
 */
export function instanceOfCategoriaNoteListDto(value) {
    return true;
}
export function CategoriaNoteListDtoFromJSON(json) {
    return CategoriaNoteListDtoFromJSONTyped(json, false);
}
export function CategoriaNoteListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'categoriaNoteDtoList': json['categoriaNoteDtoList'] == null ? undefined : (json['categoriaNoteDtoList'].map(CategoriaNoteDtoFromJSON)),
    };
}
export function CategoriaNoteListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'categoriaNoteDtoList': value['categoriaNoteDtoList'] == null ? undefined : (value['categoriaNoteDtoList'].map(CategoriaNoteDtoToJSON)),
    };
}

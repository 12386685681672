/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ElencoAssetDtoFromJSON, ElencoAssetInputDtoToJSON, ElencoAssetListDtoFromJSON, ProjectElencoAssetDtoFromJSON, ProjectElencoAssetDtoListFromJSON, ProjectElencoAssetInputToJSON, } from '../models/index';
/**
 *
 */
export class ControllerDedicatoAlleFunzioniDellaSezioneProductsAndSubscriptionsApi extends runtime.BaseAPI {
    /**
     * Prende in input l\'ID e restituisce l\'output dell\'elemento con quell\'ID
     * Recupera l\'asset tramite id
     */
    assetDetailRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['elencoAssetId'] == null) {
                throw new runtime.RequiredError('elencoAssetId', 'Required parameter "elencoAssetId" was null or undefined when calling assetDetail().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/asset/{elencoAssetId}`.replace(`{${"elencoAssetId"}}`, encodeURIComponent(String(requestParameters['elencoAssetId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ElencoAssetDtoFromJSON(jsonValue));
        });
    }
    /**
     * Prende in input l\'ID e restituisce l\'output dell\'elemento con quell\'ID
     * Recupera l\'asset tramite id
     */
    assetDetail(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.assetDetailRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    deleteProgettoAssetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteProgettoAsset().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/projectAsset/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    deleteProgettoAsset(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteProgettoAssetRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Inserisce i dati non null del asset passati in input, basandosi sui permessi dell\'utente loggato
     * Inserisce nuovo Asset
     */
    elencoAssetInsertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['elencoAssetInputDto'] == null) {
                throw new runtime.RequiredError('elencoAssetInputDto', 'Required parameter "elencoAssetInputDto" was null or undefined when calling elencoAssetInsert().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/asset/editAsset`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ElencoAssetInputDtoToJSON(requestParameters['elencoAssetInputDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ElencoAssetDtoFromJSON(jsonValue));
        });
    }
    /**
     * Inserisce i dati non null del asset passati in input, basandosi sui permessi dell\'utente loggato
     * Inserisce nuovo Asset
     */
    elencoAssetInsert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoAssetInsertRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restituisce l\'elenco di tutti gli asset
     * Recupera la lista di tutti gli asset
     */
    listAssetDetailRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/asset/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ElencoAssetListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce l\'elenco di tutti gli asset
     * Recupera la lista di tutti gli asset
     */
    listAssetDetail(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.listAssetDetailRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    listProjectAssetDetailRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling listProjectAssetDetail().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/projectAsset/project/{idProject}`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectElencoAssetDtoListFromJSON(jsonValue));
        });
    }
    /**
     */
    listProjectAssetDetail(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.listProjectAssetDetailRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    listProjectAssetDetail1Raw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idAsset'] == null) {
                throw new runtime.RequiredError('idAsset', 'Required parameter "idAsset" was null or undefined when calling listProjectAssetDetail1().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/projectAsset/asset/{idAsset}`.replace(`{${"idAsset"}}`, encodeURIComponent(String(requestParameters['idAsset']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectElencoAssetDtoListFromJSON(jsonValue));
        });
    }
    /**
     */
    listProjectAssetDetail1(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.listProjectAssetDetail1Raw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    listProjectAssetDetail2Raw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/projectAsset/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectElencoAssetDtoListFromJSON(jsonValue));
        });
    }
    /**
     */
    listProjectAssetDetail2(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.listProjectAssetDetail2Raw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectAssetDetailRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idAsset'] == null) {
                throw new runtime.RequiredError('idAsset', 'Required parameter "idAsset" was null or undefined when calling projectAssetDetail().');
            }
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling projectAssetDetail().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/projectAsset/{idAsset}/{idProject}`.replace(`{${"idAsset"}}`, encodeURIComponent(String(requestParameters['idAsset']))).replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectElencoAssetDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    projectAssetDetail(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectAssetDetailRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Inserisce i dati non null del asset passati in input, basandosi sui permessi dell\'utente loggato
     * Inserisce nuovo Project Elenco Asset
     */
    projectElencoAssetInsertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectElencoAssetInput'] == null) {
                throw new runtime.RequiredError('projectElencoAssetInput', 'Required parameter "projectElencoAssetInput" was null or undefined when calling projectElencoAssetInsert().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/projectAsset/insert`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ProjectElencoAssetInputToJSON(requestParameters['projectElencoAssetInput']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Inserisce i dati non null del asset passati in input, basandosi sui permessi dell\'utente loggato
     * Inserisce nuovo Project Elenco Asset
     */
    projectElencoAssetInsert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.projectElencoAssetInsertRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Inserisce i dati non null del asset passati in input, basandosi sui permessi dell\'utente loggato
     * Inserisce nuovo Project Elenco Asset
     */
    projectElencoAssetUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectElencoAssetInput'] == null) {
                throw new runtime.RequiredError('projectElencoAssetInput', 'Required parameter "projectElencoAssetInput" was null or undefined when calling projectElencoAssetUpdate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/productsandsubscriptions/projectAsset/edit`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['projectElencoAssetInput'].map(ProjectElencoAssetInputToJSON),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Inserisce i dati non null del asset passati in input, basandosi sui permessi dell\'utente loggato
     * Inserisce nuovo Project Elenco Asset
     */
    projectElencoAssetUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.projectElencoAssetUpdateRaw(requestParameters, initOverrides);
        });
    }
}

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { FilterDtoStatoEnum, FilterProjectMonthDto, ProjectMonthSearchRequest } from '@api/client';
import { Session } from 'enums/project.enum';
import { StorageKeysEnum } from 'enums/storageKeys.enum';
import useGetSessionActions from 'pages/private/management-tools/_hooks/useGetSessionActions';

const useFormMTPage = (initialData: any, session: Session) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const { filtersSelector, filtersUsedSelector, getFiltersAction, getProjectsAction, saveFiltersUsedAction } =
    useGetSessionActions(session);
  const filters = useAppSelector(filtersSelector());
  const filtersUsed = useAppSelector(filtersUsedSelector());
  const [chipsFormData, setChipsFormData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);

  useEffect(() => {
    if (!filters) {
      dispatch(getFiltersAction());
    }
  }, [dispatch, filters, session]);

  useEffect(() => {
    if (filters && !isSubmitting.current) {
      const payload: ProjectMonthSearchRequest = {
        year: filters?.meseAnnoDaConsolidare?.anno || new Date().getFullYear(),
        month: filters?.meseAnnoDaConsolidare?.mese || new Date().getMonth() + 1,
        filterProjectMonthDto: filtersUsed || {},
      };
      dispatch(getProjectsAction(payload));
    }
  }, [dispatch, filters, session]);

  const { reset, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  const onSubmit = (data: any) => {
    sessionStorage.setItem(StorageKeysEnum.CURRENT_PAGE_MANAGEMENT_TOOLS, String(0));
    isSubmitting.current = true;
    const formDataArray = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          const isRangeModified = data[key].some((value: any, index: any) => value !== initialData[key][index]);
          if (isRangeModified) {
            formDataArray.push([key, data[key]]);
          }
        } else if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormData([...formDataArray]);

    const filtersProjectMonth: FilterProjectMonthDto = {};

    if (data) {
      if (data.projectId) {
        filtersProjectMonth.projectId = Number(data.projectId);
      }
      if (data.projectName) {
        filtersProjectMonth.projectName = data.projectName;
      }
      if (data.businessUnit) {
        filtersProjectMonth.businessUnit = filters?.businessUnit?.find((item) => item.name === data.businessUnit)?.id;
      }
      if (data.deliveryUnit) {
        filtersProjectMonth.deliveryUnit = filters?.deliveryUnit?.find((item) => item.name === data.deliveryUnit)?.id;
      }
      if (data.projectType) {
        filtersProjectMonth.projectType = data.projectType;
      }
      if (data.stati) {
        filtersProjectMonth.stati = data.stati
          .map((status: string) => status.toUpperCase().replace(/\s+/g, '_'))
          .flatMap((status: string) =>
            status === FilterDtoStatoEnum.WorkInProgress
              ? [status, FilterDtoStatoEnum.ControlloCl, FilterDtoStatoEnum.ControlloCdg]
              : [status]
          );
      }
      if (data.priceRange && data.priceRange.length >= 1) {
        filtersProjectMonth.amountFrom = data.priceRange[0];
        filtersProjectMonth.amountTo = data.priceRange[1];
      }
    }

    const payload: ProjectMonthSearchRequest = {
      year: filters?.meseAnnoDaConsolidare?.anno || new Date().getFullYear(),
      month: filters?.meseAnnoDaConsolidare?.mese || new Date().getMonth() + 1,
      filterProjectMonthDto: filtersProjectMonth,
    };

    dispatch(saveFiltersUsedAction(filtersProjectMonth));
    dispatch(getProjectsAction(payload))
      .unwrap()
      .then((res: any) => {});

    handleSubmit(data);
  };

  const onReset = () => {
    reset(initialData);
    setChipsFormData([]);
    dispatch(saveFiltersUsedAction(initialData));
  };

  return { onSubmit, onReset, filterParams, chipsFormData, setChipsFormData, reset };
};

export default useFormMTPage;

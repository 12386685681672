import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Select, Tooltip } from 'antd';
import { Table } from 'react-bootstrap';
import { SearchFattureEmesseRequest } from '@api/client';
import { filtersEmesseUsedSelector, projectsForActiveBillsSelector } from './_redux/selectors';
import useGetAndMapEmittedBills from './_hooks/useGetAndMapEmittedBills';
import useHandleChangeActiveCycleAssociation from './_hooks/useHandleChangeActiveCycleAssociation';
import useSaveActiveCycleAssociation from './_hooks/useSaveActiveCycleAssociation';
import { getFattureEmesse, getProjectsForActiveBills } from './_redux/actions';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import useGetPassiveCycleOptions from 'pages/private/passive-cycle/_hooks/useGetPassiveCycleOptions';
import { trackUserIdGa4 } from 'shared/utils/gtag';
import { LocationFilterEnum, TrackPagesGA4Enum } from 'enums/global.enum';
import useFormFatture from 'shared/design-system/components/app-accordion/_hooks/useFormFatture';
import FattureForm, { fattureFiltersFormData } from 'shared/_components/fatture-filters';
import AppAccordion from 'shared/design-system/components/app-accordion';
import { DataTable } from 'pages/private/project-list/project-list-table';
import AppCard from 'shared/design-system/components/app-card';
import ServerSidePaginator from 'shared/design-system/components/app-server-side-paginator';
import NoData from 'shared/design-system/components/no-data-box';
import { activeCycleProjectsAssociatedForBillsSelector } from '../active-cycle-order-list/_redux/selectors';
import { searchActiveOrders } from '../active-cycle-order-list/_redux/actions';

const ActiveCycleBills: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const activeOrders = useAppSelector(activeCycleProjectsAssociatedForBillsSelector);
  const projectsForActiveBills = useAppSelector(projectsForActiveBillsSelector);
  const filtriUsed = useAppSelector(filtersEmesseUsedSelector);
  const { emittedBills, setEmittedBills, filteredBills } = useGetAndMapEmittedBills(
    activeOrders,
    projectsForActiveBills
  );
  const { handleChangeValue } = useHandleChangeActiveCycleAssociation(setEmittedBills);
  const { saveAssociation } = useSaveActiveCycleAssociation();
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions('active');

  useEffect(() => {
    trackUserIdGa4(TrackPagesGA4Enum.ACTIVE_CYCLE);
    dispatch(searchActiveOrders({ contractOrderSearchDto: { isLight: true } }));
    dispatch(getProjectsForActiveBills());
  }, []);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useFormFatture(
    fattureFiltersFormData,
    'active'
  );

  const [controlledDate, setControlledDate] = useState<any>(null);

  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };

  const isActionDisabled = (indexBill: number) => {
    return (
      emittedBills[indexBill]['numeroOrdine'].value === undefined ||
      emittedBills[indexBill]['progetti'].value === undefined
    );
  };

  const changePage = (page: number) => {
    let filters = { ...filtriUsed };
    if (filters.paging) {
      filters.paging = { ...filters.paging, page: page };
    }
    const payload: SearchFattureEmesseRequest = {
      fatturaEmessaSearchDto: filters!,
    };
    dispatch(getFattureEmesse(payload));
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-3">
        <h4 className="text-gray-800 my-0">{t('Active cycle bills')}</h4>
      </div>

      <AppAccordion
        formData={fattureFiltersFormData}
        location={LocationFilterEnum.BILLS_ACTIVE_CYCLE}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        chipsFormData={chipsFormData}
        setChipsFormData={setChipsFormData}
        reset={reset}
      >
        <FattureForm
          onSubmit={onSubmit}
          handleReset={handleReset}
          setControlledDate={setControlledDate}
          mappedPassiveCycleOptions={mappedPassiveCycleOptions}
          type="active"
        />
      </AppAccordion>

      <AppCard title={t('Bills')}>
        {emittedBills.length === 0 ? (
          <NoData msg={t('No bill found')} />
        ) : (
          <div className="table-responsive table-bordered">
            <Table className={'mt-0 mb-0'}>
              <thead className="bg-red">
                <tr>
                  {Object.keys(emittedBills[0]).map((el) => {
                    if (emittedBills[0][el].visible) {
                      return (
                        <th className={`${emittedBills[0][el].className}`} key={el} scope="col">
                          <span>{emittedBills[0][el].label}</span>
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {emittedBills.map((e: DataTable, indexBill: number) => (
                  <tr key={'row-' + indexBill}>
                    {Object.keys(emittedBills[0]).map((h) => {
                      if (emittedBills[0][h].visible) {
                        return (
                          <td
                            className={`${emittedBills[0][h].className}`}
                            key={`${h}-${indexBill}`}
                            style={{ maxWidth: e[h].width, minWidth: e[h].width }}
                          >
                            {' '}
                            {e[h].isButton && (
                              <div className="d-flex align-items-center justify-content-center">
                                <Tooltip placement="top" color="white" title={e[h]?.tooltip}>
                                  <button
                                    onClick={() => {
                                      saveAssociation(
                                        e.id.value as string,
                                        e.numeroOrdine.value as number,
                                        e.progetti.value as number
                                      );
                                    }}
                                    className="btn-icon"
                                    disabled={isActionDisabled(indexBill)}
                                  >
                                    <i
                                      className={`${e[h].icon} ${
                                        isActionDisabled(indexBill) ? 'text-disabled' : 'text-blue-links'
                                      } me-1`}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            )}
                            {e[h].isSelect && (
                              <Select
                                className="w-100"
                                placeholder={e[h].tooltip}
                                showSearch
                                optionFilterProp="children"
                                filterOption={true}
                                value={e[h].value}
                                allowClear
                                variant="borderless"
                                onChange={(event) => handleChangeValue(event, e[h].id, indexBill)}
                              >
                                {e[h].options?.map((el) => (
                                  <Select.Option
                                    key={`${el.projectId ?? el.id}-${indexBill}`}
                                    value={el.projectId ?? el.id}
                                  >
                                    {el.projectId ?? el.idExternalContract}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                            {!e[h].isButton && !e[h].isSelect && (
                              <span className="d-inline-flex">
                                <p className="my-auto">{e[h].value ?? ''}</p>
                              </span>
                            )}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {emittedBills?.length > 0 ? (
          <ServerSidePaginator
            currentPage={filteredBills?.page || 0}
            totalPages={filteredBills?.totalPages || 0}
            elementsToShow={10}
            totalElements={filteredBills?.totalElements}
            onPageChange={(e) => changePage(e)}
          />
        ) : (
          ''
        )}
      </AppCard>
    </>
  );
};

export default ActiveCycleBills;

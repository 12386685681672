import { Route, Routes } from 'react-router-dom';
import LoginPage from './login';
import LoginPageSSO from './login-sso';
import { HOSTNAME_PRODUCTION, HOSTNAME_WEB_SVIL } from 'env';

const AuthRouter: React.FC = () => {
  return (
    <Routes>
      {window.location.hostname !== HOSTNAME_PRODUCTION && window.location.hostname !== HOSTNAME_WEB_SVIL && (
        <Route path="" element={<LoginPage />} />
      )}
      <Route path="" element={<LoginPageSSO />} />
    </Routes>
  );
};
export default AuthRouter;

import { useTranslation } from 'react-i18next';
import ModalAction from '../modal-action';
import MoveDays from '../move-days';
import { Dispatch, SetStateAction } from 'react';
import useMoveDays from '../../_hooks/team-table/useMoveDays';
import { ProfiloProfessionaleDto, StaffExtendedDto, UserDto } from '@api/client';
import { filterOption } from 'shared/utils/common.utils';

interface Props {
  showModalMoveDays: boolean;
  collaborators: UserDto[];
  currentCollaborator: Partial<StaffExtendedDto> | null | undefined;
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined;
  fullAllocationsShown: Partial<StaffExtendedDto>[] | null | undefined;
  currentYear: number | 'all';
  currentCollaboratorName: string;
  currentRole: string;
  currentGu: number;
  roleList: ProfiloProfessionaleDto[];
  setFullAllocations: React.Dispatch<React.SetStateAction<Partial<StaffExtendedDto>[] | null | undefined>>;
  filterAllocationsByYear: (year: any, allocations: Partial<StaffExtendedDto>[]) => void;
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalMoveDays: Dispatch<SetStateAction<boolean>>;
  getOptions: (
    idPps: number | string | undefined,
    idPpt: number | string | undefined
  ) => {
    label: string | undefined;
    value: number | undefined;
  }[];
}

const MoveDaysModal: React.FC<Props> = ({
  showModalMoveDays,
  collaborators,
  currentCollaborator,
  fullAllocations,
  fullAllocationsShown,
  currentYear,
  currentCollaboratorName,
  currentRole,
  currentGu,
  roleList,
  setFullAllocations,
  filterAllocationsByYear,
  setDraftValidation,
  setShowModalMoveDays,
  getOptions,
}) => {
  const { t } = useTranslation();

  const {
    moveDaysToNewCollaborator,
    moveDaysToExistingCollaborator,
    newCollaborator,
    moveDaysProps,
    setNewCollaborator,
  } = useMoveDays(
    collaborators,
    currentCollaborator,
    fullAllocations,
    setFullAllocations,
    filterAllocationsByYear,
    setDraftValidation,
    setShowModalMoveDays,
    currentYear
  );

  const handleShowModalMoveDays = (value: boolean) => {
    setShowModalMoveDays(value);
    setNewCollaborator(false);
  };

  return (
    <ModalAction
      title={t('project-detail.move-days.label')}
      actionLabel={t('common.confirm')}
      action={() => {
        newCollaborator ? moveDaysToNewCollaborator() : moveDaysToExistingCollaborator();
      }}
      setShow={(value) => handleShowModalMoveDays(value)}
      show={showModalMoveDays}
      size="lg"
    >
      <p>
        {`${currentCollaboratorName ?? ''} - ${currentRole}`}:{' '}
        <b className="ms-2">{`${currentGu === 0 ? '' : currentGu} ${t('project-detail.move-days.md-remaining')}`}</b>
      </p>
      {showModalMoveDays && (
        <MoveDays
          fullAllocationsShown={fullAllocationsShown}
          roleList={roleList}
          filterOption={filterOption}
          getOptions={getOptions}
          collaborators={collaborators}
          currentCollaborator={currentCollaborator}
          {...moveDaysProps}
        />
      )}
    </ModalAction>
  );
};

export default MoveDaysModal;

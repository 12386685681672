import { useEffect } from 'react';
import { trackUserIdGa4 } from 'shared/utils/gtag';
import { TrackPagesGA4Enum } from 'enums/global.enum';
import { Tabs } from 'antd';
import { PassiveCycleTabs } from 'enums/project.enum';
import usePassiveCycleTabs from './_hooks/usePassiveCycleTabs';
import OrderList from './order-list';
import SuppliersCrud from './suppliers-crud';
import PassiveCycleBills from './passive-cycle-bills';

const PassiveCycle: React.FC = () => {
  const { selectedTab, passiveCycleTabs, onChangeTab } = usePassiveCycleTabs();

  useEffect(() => {
    trackUserIdGa4(TrackPagesGA4Enum.PASSIVE_CYCLE);
  }, []);

  return (
    <>
      <Tabs defaultActiveKey={PassiveCycleTabs.ORDERS} items={passiveCycleTabs} onChange={onChangeTab} />
      {selectedTab === PassiveCycleTabs.ORDERS && <OrderList />}
      {selectedTab === PassiveCycleTabs.BILLS && <PassiveCycleBills />}
      {selectedTab === PassiveCycleTabs.SUPPLIERS_CRUD && <SuppliersCrud />}
    </>
  );
};

export default PassiveCycle;

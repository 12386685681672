import { FilterDtoStatoEnum } from '@api/client';
import i18n from '../i18n/i18n';

export const StatusBadgeData = [
  {
    id: '2',
    name: FilterDtoStatoEnum.Pending,
    label: i18n.t('status.pending'),
    filterLabel: i18n.t('status.pending'),
    icon: 'open-pending',
    color: '#545663',
    backgroundColor: '#d9d9d9',
  },
  {
    id: '3',
    name: FilterDtoStatoEnum.Planning,
    label: i18n.t('status.planning'),
    filterLabel: i18n.t('status.planning'),
    icon: 'open-planning',
    color: '#545663',
    backgroundColor: '#fffbe6',
  },
  {
    id: '4',
    name: FilterDtoStatoEnum.ClosedWonPlanning,
    label: i18n.t('status.closed_won_planning'),
    filterLabel: i18n.t('status.closed_won_planning'),
    icon: 'closed-won-planning',
    color: '#545663',
    backgroundColor: '#fffbe6',
  },
  {
    id: '5',
    name: FilterDtoStatoEnum.ClosedLost,
    label: i18n.t('status.closed_lost'),
    filterLabel: i18n.t('status.closed_lost'),
    icon: 'time-expire-out-of-time',
    color: '#ffffff',
    backgroundColor: '#ec0606',
  },
  {
    id: '6',
    name: FilterDtoStatoEnum.IntranetPending,
    label: i18n.t('status.intranet_pending'),
    filterLabel: i18n.t('status.intranet_pending'),
    icon: 'open-pending',
    color: '#545663',
    backgroundColor: '#d9d9d9',
  },
  {
    id: '7',
    name: FilterDtoStatoEnum.WorkInProgress,
    label: i18n.t('status.work_in_progress'),
    filterLabel: i18n.t('status.work_in_progress'),
    icon: 'work-in-progress',
    color: '#ffffff',
    backgroundColor: '#22519e',
  },
  {
    id: '8',
    name: FilterDtoStatoEnum.Completed,
    label: i18n.t('status.completed'),
    filterLabel: i18n.t('status.completed'),
    icon: 'closed-won-complete',
    color: '#545663',
    backgroundColor: '#b7eb8f',
  },
  {
    id: '9',
    name: FilterDtoStatoEnum.ClosedWonPlanning,
    label: i18n.t('status.closed_won_planning'),
    filterLabel: i18n.t('status.closed_won_planning'),
    icon: 'closed-won-planning',
    color: '#594b24',
    backgroundColor: '#ffd666',
  },
  {
    id: '10',
    name: FilterDtoStatoEnum.PlanningOk,
    label: i18n.t('status.planning_ok'),
    filterLabel: i18n.t('status.planning_ok'),
    icon: 'edit',
    color: '#594b24',
    backgroundColor: '#fffbe6',
  },
  {
    id: '12',
    name: FilterDtoStatoEnum.ControlloCdg,
    label: i18n.t('status.work_in_progress'),
    filterLabel: i18n.t('status.controllo_cdg'),
    icon: 'work-in-progress',
    color: '#ffffff',
    backgroundColor: '#22519e',
  },
  {
    id: '14',
    name: FilterDtoStatoEnum.ControlloCl,
    label: i18n.t('status.work_in_progress'),
    filterLabel: i18n.t('status.controllo_cl'),
    icon: 'work-in-progress',
    color: '#ffffff',
    backgroundColor: '#22519e',
  },
  {
    id: '15',
    name: FilterDtoStatoEnum.ReportingClosed,
    label: i18n.t('status.reporting_closed'),
    filterLabel: i18n.t('status.reporting_closed'),
    icon: 'closed-won-complete',
    color: '#594b24',
    backgroundColor: '#ffd666',
  },
  {
    id: '16',
    name: FilterDtoStatoEnum.EconomicsClosed,
    label: i18n.t('status.economics_closed'),
    filterLabel: i18n.t('status.economics_closed'),
    icon: 'closed-won-complete',
    color: '#594b24',
    backgroundColor: '#ffd666',
  },
];

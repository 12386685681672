import { ListProjectCLDto } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import { getClProjectsWithoutOrders } from './actions';

interface InitialState {
  clProjectsWithoutOrders: ListProjectCLDto | undefined;
}

const initialState: InitialState = {
  clProjectsWithoutOrders: undefined,
};

export const clProjectsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getClProjectsWithoutOrders.fulfilled, (state, action) => {
    state.clProjectsWithoutOrders = action.payload;
  });
});

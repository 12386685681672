/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ElencoAssetDtoFromJSON, ElencoAssetDtoToJSON, } from './ElencoAssetDto';
/**
 * Check if a given object implements the ElencoAssetListDto interface.
 */
export function instanceOfElencoAssetListDto(value) {
    return true;
}
export function ElencoAssetListDtoFromJSON(json) {
    return ElencoAssetListDtoFromJSONTyped(json, false);
}
export function ElencoAssetListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'elencoAssetDtoList': json['elencoAssetDtoList'] == null ? undefined : (json['elencoAssetDtoList'].map(ElencoAssetDtoFromJSON)),
    };
}
export function ElencoAssetListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'elencoAssetDtoList': value['elencoAssetDtoList'] == null ? undefined : (value['elencoAssetDtoList'].map(ElencoAssetDtoToJSON)),
    };
}

import { DatePickerProps, Select } from 'antd';
import AddMonthComponent from '../add-month-component';
import DeleteMonthComponent from '../delete-month-component';
import PeriodDateHeader from '../period-date-header';
import DropdownConfig from '../dropdown-config';
import { useTranslation } from 'react-i18next';
import { MonthOption } from '../../_models/monthOption';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FilterDtoTipologiaEnum, RevenuePlanDto, StaffExtendedDto } from '@api/client';
import './styles.scss';
import LinksButton from 'shared/design-system/components/links-button';
import FeeRateSwitch from '../fee-rate-switch';
import { useAppSelector } from 'core/store/hooks';
import { currentProjectTypeSelector } from 'pages/private/project-list/_redux/selectors';

interface Props {
  readOnly: boolean | undefined;
  monthOptionsObj: MonthOption[];
  checkedList: CheckboxValueType[];
  years: (number | undefined)[];
  fullAllocationsShown: Partial<StaffExtendedDto>[];
  visibleRows: { [key: string]: boolean };
  isWorkInProgress: boolean;
  visibleColumns: { [key: string]: boolean };
  isTotalsTableCollapsed: boolean;
  setIsTotalsTableCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleRows: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setVisibleColumns: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  addCollaborator: () => void;
  addMonths: () => void;
  setDateRange: React.Dispatch<React.SetStateAction<number[]>>;
  disableAllocatedMonths: (current: { year: () => number; month: () => number }) => boolean;
  removeMonths: () => void;
  onChangeCheckedList: (list: any[]) => void;
  onCheckAllChange: (e: CheckboxChangeEvent) => void;
  filterAllocationsByYear: (
    year: number | 'all',
    allocations?: Partial<StaffExtendedDto>[] | null | undefined,
    revenuePlan?: RevenuePlanDto[] | null | undefined
  ) => void;
}

const RevenuePlanActionsHeader: React.FC<Props> = ({
  readOnly,
  monthOptionsObj,
  checkedList,
  years,
  fullAllocationsShown,
  visibleRows,
  isWorkInProgress,
  visibleColumns,
  isTotalsTableCollapsed,
  setIsTotalsTableCollapsed,
  setVisibleRows,
  setVisibleColumns,
  addCollaborator,
  addMonths,
  setDateRange,
  disableAllocatedMonths,
  removeMonths,
  onChangeCheckedList,
  onCheckAllChange,
  filterAllocationsByYear,
}) => {
  const { t } = useTranslation();
  const projectType = useAppSelector(currentProjectTypeSelector);

  const onDateChange: DatePickerProps['onChange'] = (dates, dateStr) => {
    if (!dates) setDateRange([]);
    const [meseInizio, annoInizio] = dateStr[0].split('/').map((str) => parseInt(str, 10));
    const [meseFine, annoFine] = dateStr[1].split('/').map((str) => parseInt(str, 10));
    setDateRange([meseInizio, annoInizio, meseFine, annoFine]);
  };

  return (
    <div className="d-flex justify-content-between mb-3" id="header-buttons">
      <div className="col-7 d-flex align-items-start">
        {/* ADD COLLABORATOR */}
        <LinksButton
          className="btn-sm btn btn-links-outline action-button px-3"
          isDisabled={readOnly}
          onClick={addCollaborator}
        >
          <div className="d-flex align-items-center">
            <i className="icon-aggiungi-collaboratore" />
            {t('project-detail.add-collaborator')}
          </div>
        </LinksButton>
        {/* ADD MONTHS */}
        <AddMonthComponent
          readOnly={readOnly}
          addMonths={addMonths}
          onDateChange={onDateChange}
          disableAllocatedMonths={disableAllocatedMonths}
        />
        {/* DELETE MONTHS */}
        <DeleteMonthComponent
          monthOptionsObj={monthOptionsObj}
          readOnly={readOnly}
          checkedList={checkedList}
          years={years}
          removeMonths={removeMonths}
          onChangeCheckedList={onChangeCheckedList}
          onCheckAllChange={onCheckAllChange}
        />
        {/* FEE RATE SWITCH */}
        {projectType === FilterDtoTipologiaEnum.Consumo && <FeeRateSwitch setVisibleColumns={setVisibleColumns} />}
      </div>
      <div className="col-5 d-flex align-items-start">
        {/* PERIOD */}
        <PeriodDateHeader fullAllocationsShown={fullAllocationsShown} />
        <Select defaultValue={'all'} onChange={(e) => filterAllocationsByYear(e as number | 'all')}>
          <Select.Option value="all">{t('project-detail.all')}</Select.Option>
          {years.map((year, index) => (
            <Select.Option key={'year-' + index} value={year}>
              {year}
            </Select.Option>
          ))}
        </Select>
        {/* COLUMNS VISIBILITY */}
        <DropdownConfig
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          setIsCollapsed={setIsTotalsTableCollapsed}
          isWorkInProgress={isWorkInProgress}
          isCollapsed={isTotalsTableCollapsed}
          visibleRows={visibleRows}
          setVisibleRows={setVisibleRows}
        />
      </div>
    </div>
  );
};

export default RevenuePlanActionsHeader;

import './App.scss';
import { Provider } from 'react-redux';
import { store } from './core/store';
import AppRouter from './AppRouter';
import AppToast from './shared/design-system/components/app-toast';
import { ConfigProvider } from 'antd';

function App() {
  return (
    <Provider store={store}>
      <div id="page-top" className="app-body">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#4e73df',
            },
            components: {
              Radio: {
                colorPrimary: '#22519e',
                borderRadius: 2,
              },
            },
          }}
        >
          <AppRouter />
        </ConfigProvider>
        <AppToast />
        {/* <AppScrollToTop /> */}
      </div>
    </Provider>
  );
}

export default App;

export enum DashboardUserTables {
  RESOURCE_PLANNING = 'Resource Planning',
  UNDERBOOKING = 'Underbooking',
  OVERBOOKING = 'Overbooking',
}

export enum DashboardFieldsColor {
  REVENUES = '#228086',
  COSTS = '#d81212',
  MARGINS = '#1b7112',
  RATE = '#a600b0',
  FTE = '#ce9e32',
}

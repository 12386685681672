import { ContractOrderOutputDto } from '@api/client';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import CommonAccordion from 'shared/design-system/components/common-accordion';
import { formatCurrency } from 'shared/utils/common.utils';

interface Props {
  order: ContractOrderOutputDto;
}

const ActiveCycleOrderData: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();

  return (
    <CommonAccordion header={t('Order data')} isDefaultOpened={true}>
      <Row>
        <Col>
          <div className="span-values">
            <div className="head">{t('Order')}</div>
            <div className="value">{order.idExternalContract || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Account')}</div>
            <div className="value">{order.denominazioneCliente || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Legal entity')}</div>
            <div className="value">{order.legalEntity?.nome || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Start date')}</div>
            <div className="value">{order.startDate?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('End date')}</div>
            <div className="value">{order.endDate?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Status')}</div>
            <div className="value">{order.ordStato || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Business Structure')}</div>
            <div className="value">{order.strutturaFunzione?.nome || '-'}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="span-values">
            <div className="head">{t('Amount RTI')}</div>
            <div className="value">{order.ordImportoRti ? `${formatCurrency(order.ordImportoRti)}€` : '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Links quote')}</div>
            <div className="value">{order.ordQuotaLinks || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Object')}</div>
            <div className="value">{order.ordOggetto || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('IBAN')}</div>
            <div className="value">{order.ordIban || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('IVA')}</div>
            <div className="value">{order.ordIva || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Notes RTI')}</div>
            <div className="value">{order.ordNoteRTI || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Notes')}</div>
            <div className="value">{order.ordNote || '-'}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="span-values">
            <div className="head">{t('Order name')}</div>
            <div className="value">{order.object || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('PO number')}</div>
            <div className="value">{order.ordPoNumber || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('PO date')}</div>
            <div className="value">{order.dataPo?.toLocaleDateString() || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('CIG')}</div>
            <div className="value">{order.ordCig || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('CUP')}</div>
            <div className="value">{order.ordCup || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('SDI')}</div>
            <div className="value">{order.ordSdi || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('GG term')}</div>
            <div className="value">{order.ordTermGG || '-'}</div>
          </div>
        </Col>
      </Row>
    </CommonAccordion>
  );
};

export default ActiveCycleOrderData;

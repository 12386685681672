import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { createAsset, getAssets, updateProjectAssets } from '../_redux/actions';
import { ProjectElencoAssetDto, ProjectElencoAssetInput } from '@api/client';
import { currentProjectRevenueSelector } from 'pages/private/project-list/_redux/selectors';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';

const useSaveProjectAsset = (
  projectAssets: ProjectElencoAssetDto[],
  projectId: number | undefined,
  getProjectAssets: () => void
) => {
  const dispatch = useAppDispatch();
  const totalRevenue = useAppSelector(currentProjectRevenueSelector);

  const handleSaveProjectAssets = () => {
    if (!projectId) return;
    if (projectAssets.reduce((acc, item) => acc + (item.importo || 0), 0) > (totalRevenue || 0)) {
      AppToastService.error('The total of the amounts cannot exceed the total of the revenue');
      return;
    }
    const newAssets = projectAssets.filter((item) => item.idElencoAsset && item.idElencoAsset < 0);
    if (newAssets.length) {
      addNewAssetsAndSaveProjectAssets(newAssets);
    } else {
      saveProjectAssets();
    }
  };

  const addNewAssetsAndSaveProjectAssets = (newAssets: ProjectElencoAssetDto[]) => {
    const assetPromises = newAssets.map((item) =>
      dispatch(createAsset({ elencoAssetInputDto: { title: item.titoloAsset } }))
    );

    Promise.all(assetPromises).then(() => {
      dispatch(getAssets())
        .unwrap()
        .then((res) => {
          if (!res) return;
          const projectAssetsWithNewId = projectAssets.map((item) => {
            if (item.idElencoAsset && item.idElencoAsset < 0) {
              const newAsset = res.elencoAssetDtoList?.find((asset) => asset.title === item.titoloAsset);
              return {
                ...item,
                idElencoAsset: newAsset?.id,
              };
            }
            return item;
          });
          const payload: ProjectElencoAssetInput[] = projectAssets.map((item) => ({
            id: item.id,
            idElencoAsset:
              projectAssetsWithNewId.find((projectAsset) => projectAsset.titoloAsset === item.titoloAsset)
                ?.idElencoAsset || item.idElencoAsset,
            idProject: projectId,
            importo: item.importo,
          }));
          dispatch(updateProjectAssets(payload)).then(() => getProjectAssets());
        });
    });
  };

  const saveProjectAssets = () => {
    if (!projectId) return;
    const payload: ProjectElencoAssetInput[] = projectAssets.map((item) => ({
      id: item.id,
      idElencoAsset: item.idElencoAsset,
      idProject: projectId,
      importo: item.importo,
    }));
    dispatch(updateProjectAssets(payload)).then(() => getProjectAssets());
  };

  return { handleSaveProjectAssets };
};

export default useSaveProjectAsset;

import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Input, Select } from 'antd';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { filtersActiveAssociableInvoicesUsedSelector } from '../../../_redux/selectors';
import { saveFiltersActiveAssociableInvoicesUsed } from '../../../_redux/actions';
import LinksButton from 'shared/design-system/components/links-button';
import useGetPassiveCycleOptions from 'pages/private/passive-cycle/_hooks/useGetPassiveCycleOptions';
import { filterOption } from 'shared/utils/common.utils';

export const associableActiveInvoicesFormData: any = {
  idFattura: undefined,
  importoMin: undefined,
  tipoFattura: undefined,
  descrizione: undefined,
  idAzienda: undefined,
  idAccount: undefined,
};

interface Props {
  onSubmitInvoices: any;
  handleResetInvoices: () => void;
}
const AssociableActiveInvoicesFilterForm = ({ onSubmitInvoices, handleResetInvoices }: Props) => {
  const dispatch = useAppDispatch();
  const filterUsed = useAppSelector(filtersActiveAssociableInvoicesUsedSelector);
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions('active');
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: associableActiveInvoicesFormData,
    values: filterUsed,
  });

  const Reset = () => {
    reset();
    handleResetInvoices();
  };

  const handleFormSubmit = async (formData: any) => {
    dispatch(saveFiltersActiveAssociableInvoicesUsed(formData));
    try {
      await onSubmitInvoices(formData);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Col sm="3">
          <Form.Label>{t('ID Invoice')}</Form.Label>
          <Controller
            name="idFattura"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('Search ID Invoice')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('Amount')}</Form.Label>
          <Controller
            name="importoMin"
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                placeholder={t('Search amount')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('Typology')}</Form.Label>
          <Controller
            name="tipoFattura"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('Search typology')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('Description')}</Form.Label>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('Search description')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="3">
          <Form.Label>{t('Account')}</Form.Label>
          <Controller
            name="idAccount"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('Search account')}
              >
                {mappedPassiveCycleOptions?.clienteList?.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.denominazione}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('Legal entity')}</Form.Label>
          <Controller
            name="idAzienda"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('Search legal entity')}
              >
                {mappedPassiveCycleOptions?.aziendaList?.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.nome}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
      </Row>
      <div className="d-flex mt-3">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};
export default AssociableActiveInvoicesFilterForm;

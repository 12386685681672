import { createReducer } from '@reduxjs/toolkit';
import AuthService from 'core/auth/auth.service';
import { authLogin, authLogout, authRenew } from './actions';

interface InitialValues {}

const initialValues: InitialValues = {};

export const authReducer = createReducer(initialValues, (builder) => {
  builder
    .addCase(authLogin.fulfilled, (state, action) => {
      AuthService.setAuthentication(action?.payload?.token);
      AuthService.setUser();
    })
    .addCase(authRenew.fulfilled, (state, action) => {
      AuthService.setAuthentication(action?.payload?.token);
    })
    .addCase(authLogout, (state, action) => {
      AuthService.logout(action.payload);
    });
});

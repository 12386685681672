import { FilterProjectMonthDto, FilterProjectMonthValuesDto } from '@api/client';
import { chipsData } from 'enums/chipsData';
import { LocationFilterEnum } from 'enums/global.enum';
import { saveDashboardPeopleFilters, saveDashboardProjectFilters } from 'pages/private/dashboard/_redux/actions';
import { saveFiltersOrdersUsed } from 'pages/private/passive-cycle/_redux/actions';
import { saveFiltersUsed } from 'pages/private/project-list/_redux/actions';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

interface Props {
  setChipsFormData: React.Dispatch<React.SetStateAction<any>>;
  formData: any;
  filtriMT: FilterProjectMonthValuesDto | undefined | null;
  reset: any;
  location: string;
  dispatch: ThunkDispatch<RootState, any, AnyAction>;
  saveFiltersUsedAction: (filters: FilterProjectMonthDto) => {
    payload: FilterProjectMonthDto;
    type: string;
  };
}

const useRemoveChipAndSave = (props: Props) => {
  const { setChipsFormData, formData, filtriMT, reset, location, dispatch, saveFiltersUsedAction } = props;
  const handleRemoveChip = (indexToRemove: number, e: any) => {
    setChipsFormData((prevState: any) => {
      const newState = [...prevState];
      const removedChip = newState.splice(indexToRemove, 1)[0];
      const [removedKey] = removedChip;
      console.log('removedKey', removedKey);
      const updatedFormData = { ...formData };
      newState.forEach(([key, value]) => {
        if (key === chipsData.businessUnit.key || key === chipsData.deliveryUnit.key) {
          updatedFormData[key] =
            filtriMT?.[key as keyof Pick<FilterProjectMonthValuesDto, 'businessUnit' | 'deliveryUnit'>]?.find(
              (unit) => unit.name === value
            )?.id || null;
        } else if (key) {
          updatedFormData[key] = value;
        }
      });
      e.stopPropagation();
      reset(updatedFormData);
      saveFiltersOnStore(updatedFormData, location);
      return newState;
    });
  };

  const saveFiltersOnStore = (updatedFormData: any, location: string) => {
    switch (location) {
      case LocationFilterEnum.PROJECT_LIST:
        dispatch(saveFiltersUsed(updatedFormData));
        break;
      case LocationFilterEnum.MT_PL:
      case LocationFilterEnum.MT_CL:
      case LocationFilterEnum.MT_CDG:
        dispatch(saveFiltersUsedAction(updatedFormData));
        break;
      case LocationFilterEnum.DASHBOARD_PROJECTS:
        dispatch(saveDashboardProjectFilters(updatedFormData));
        break;
      case LocationFilterEnum.DASHBOARD_RESOURCES:
        dispatch(saveDashboardPeopleFilters(updatedFormData));
        break;
      case LocationFilterEnum.PASSIVE_CYCLE:
        dispatch(saveFiltersOrdersUsed(updatedFormData));
        break;
      default:
        return;
    }
  };

  return { handleRemoveChip };
};

export default useRemoveChipAndSave;

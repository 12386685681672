/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalCostDtoFromJSON, ExternalCostDtoToJSON, } from './ExternalCostDto';
import { RevenuePlanDtoFromJSON, RevenuePlanDtoToJSON, } from './RevenuePlanDto';
/**
 * Check if a given object implements the ProjectPastDataDatail interface.
 */
export function instanceOfProjectPastDataDatail(value) {
    return true;
}
export function ProjectPastDataDatailFromJSON(json) {
    return ProjectPastDataDatailFromJSONTyped(json, false);
}
export function ProjectPastDataDatailFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'prjName': json['prjName'] == null ? undefined : json['prjName'],
        'prjId': json['prjId'] == null ? undefined : json['prjId'],
        'prjIntranetId': json['prjIntranetId'] == null ? undefined : json['prjIntranetId'],
        'bidAmount': json['bidAmount'] == null ? undefined : json['bidAmount'],
        'totalRevenue': json['totalRevenue'] == null ? undefined : json['totalRevenue'],
        'otherCosts': json['otherCosts'] == null ? undefined : (json['otherCosts'].map(ExternalCostDtoFromJSON)),
        'revenuePlan': json['revenuePlan'] == null ? undefined : (json['revenuePlan'].map(RevenuePlanDtoFromJSON)),
    };
}
export function ProjectPastDataDatailToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'prjName': value['prjName'],
        'prjId': value['prjId'],
        'prjIntranetId': value['prjIntranetId'],
        'bidAmount': value['bidAmount'],
        'totalRevenue': value['totalRevenue'],
        'otherCosts': value['otherCosts'] == null ? undefined : (value['otherCosts'].map(ExternalCostDtoToJSON)),
        'revenuePlan': value['revenuePlan'] == null ? undefined : (value['revenuePlan'].map(RevenuePlanDtoToJSON)),
    };
}

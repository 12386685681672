import { useEffect, useState } from 'react';
import { ProfiloProfessionaleDto } from '@api/client';
import { useAppDispatch } from 'core/store/hooks';
import { getRoles } from '../../_redux/actions';

const useGetRoles = () => {
  const dispatch = useAppDispatch();
  const [roleList, setRoleList] = useState<ProfiloProfessionaleDto[]>([]);

  useEffect(() => {
    dispatch(getRoles())
      .unwrap()
      .then((res) => {
        if (res && res.listaRuoli) {
          setRoleList(res.listaRuoli);
        }
      });
  }, []);

  return roleList;
};

export default useGetRoles;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'shared/utils/common.utils';
import { ValueType } from 'enums/global.enum';
import { useAppSelector } from 'core/store/hooks';
import { DashboardProjectsPeriod } from 'enums/project.enum';
import { dashboardProjectTotalsCurrentYearSelector, dashboardProjectTotalsSelector } from '../../_redux/selectors';
import { DashboardFieldsColor } from 'enums/dashboard.enum';

export interface Totals {
  label: string;
  value: string;
  span: ValueType;
  col?: number;
  color?: string;
  border?: string;
  tooltip?: string;
}

export interface TotalsGroup {
  group: string;
  color: string;
  col: number;
  data: Totals[];
}

function useGetProjectTotals(period: DashboardProjectsPeriod) {
  const { t } = useTranslation();
  const [totals, setTotals] = useState<TotalsGroup[]>([]);
  const totalsSelector = useAppSelector(
    period === DashboardProjectsPeriod.ALL ? dashboardProjectTotalsSelector : dashboardProjectTotalsCurrentYearSelector
  );

  useEffect(() => {
    if (!totalsSelector) return;

    const tot = getTotals();

    setTotals(tot);
  }, [totalsSelector]);

  const getTotals = () => {
    const newFields: TotalsGroup[] = [];

    newFields.push(
      {
        group: 'Revenues',
        color: DashboardFieldsColor.REVENUES,
        col: 3,
        data: [
          {
            label: `${t('dashboard.projects.table-titles.actual-revenues-title')}`,
            value: `${formatCurrency(totalsSelector?.actualRevenuesTotal, 0) ?? '-'}`,
            span: ValueType.EURO,
          },
          {
            label: `${t('dashboard.projects.table-titles.af-revenues-title')}`,
            value: `${formatCurrency(totalsSelector?.afRevenuesTotal, 0) ?? '-'}`,
            span: ValueType.EURO,
          },
        ],
      },
      {
        group: 'Costs',
        color: DashboardFieldsColor.COSTS,
        col: 3,
        data: [
          {
            label: `${t('dashboard.projects.table-titles.actual-costs-title')}`,
            value: `${formatCurrency(totalsSelector?.actualCostsTotal, 0) ?? '-'}`,
            span: ValueType.EURO,
            tooltip: `${t('dashboard.projects.table-titles.actual-hr-costs-title')} : ${
              formatCurrency(totalsSelector?.actualHrCostsTotal, 0) ?? '-'
            } ${ValueType.EURO}\n${t('dashboard.projects.table-titles.actual-other-costs-title')} : ${
              formatCurrency(totalsSelector?.actualOtherCostsTotal, 0) ?? '-'
            } ${ValueType.EURO}`,
          },
          {
            label: `${t('dashboard.projects.table-titles.af-costs-title')}`,
            value: `${formatCurrency(totalsSelector?.prjAFCostiTotal, 0) ?? '-'}`,
            span: ValueType.EURO,
          },
        ],
      },
      {
        group: 'Margins',
        color: DashboardFieldsColor.MARGINS,
        col: 7,
        data: [
          {
            label: `${t('dashboard.projects.table-titles.actual-margin-title')}`,
            value: `${formatCurrency(totalsSelector?.actualMarginTotal, 0) ?? '-'}`,
            span: ValueType.EURO,
          },
          {
            label: `${t('dashboard.projects.table-titles.actual-margin-perc-title')}`,
            value: `${formatCurrency((totalsSelector?.actualMarginRateTotal || 0) * 100) ?? '-'}`,
            span: ValueType.PERCENTUALE,
          },
          {
            label: `${t('dashboard.projects.table-titles.af-margin-title')}`,
            value: `${formatCurrency(totalsSelector?.prjAfMargin, 0) ?? '-'}`,
            span: ValueType.EURO,
          },

          {
            label: `${t('dashboard.projects.table-titles.af-margin-perc-title')}`,
            value: `${formatCurrency((totalsSelector?.prjAfMarginPerc || 0) * 100) ?? '-'}`,
            span: ValueType.PERCENTUALE,
          },
        ],
      },
      {
        group: 'Rate',
        color: DashboardFieldsColor.RATE,
        col: 3,
        data: [
          {
            label: `${t('dashboard.projects.table-titles.actual-rate-title')}`,
            value: `${formatCurrency(totalsSelector?.actualRateTotal, 0) ?? '-'}`,
            span: ValueType.EURO,
          },
          {
            label: `${t('dashboard.projects.table-titles.af-rate-title')}`,
            value: `${formatCurrency(totalsSelector?.prjAFRateTotal, 0) ?? '-'}`,
            span: ValueType.EURO,
          },
        ],
      },
      {
        group: 'FTE',
        color: DashboardFieldsColor.FTE,
        col: 6,
        data: [
          {
            label: `${t('dashboard.projects.table-titles.actual-fte-title')}`,
            value: `${formatCurrency(totalsSelector?.prjAFteAnnoTotal, 0) ?? '-'}`,
            span: ValueType.NULL,
          },
          {
            label: `${t('dashboard.projects.table-titles.actual-gu-title')}`,
            value: `${formatCurrency(totalsSelector?.prjAGUTotal, 0) ?? '-'}`,
            span: ValueType.NULL,
          },
          {
            label: `${t('dashboard.projects.table-titles.af-fte-title')}`,
            value: `${formatCurrency(totalsSelector?.prjAFFteAnnoTotal, 0) ?? '-'}`,
            span: ValueType.NULL,
          },

          {
            label: `${t('dashboard.projects.table-titles.af-gu-title')}`,
            value: `${formatCurrency(totalsSelector?.prjAFGUTotal, 0) ?? '-'}`,
            span: ValueType.NULL,
          },
        ],
      }
    );

    return newFields;
  };

  return { totals };
}

export default useGetProjectTotals;

import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Indicatori } from '../../_models/indicatori';
import { DatePicker, InputNumber, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import { RuoloTeam } from '../../_models/ruoloTeam';
import { ProfiloProfessionaleDto } from '@api/client';
import dayjs from 'dayjs';
import { filterOption, formatCurrency } from 'shared/utils/common.utils';
import LinksButton from 'shared/design-system/components/links-button';

interface Props {
  showModal: boolean;
  indicatori: Indicatori;
  ruoliTeam: RuoloTeam[];
  roleList: ProfiloProfessionaleDto[];
  handleCloseModal: () => void;
  onDateChange: (date: dayjs.Dayjs, dateString: string | string[]) => void;
  getDefaultDateRange: () => [dayjs.Dayjs | null | undefined, dayjs.Dayjs | null | undefined];
  addRole: () => void;
  handleRoleChange: (id: any, index: number) => void;
  handleChangeAmounts: (event: number | null, index: number, name: string) => void;
  handleSeniorityChange: (id: any, index: number) => void;
  deleteRole: (index: number) => void;
  isConfermaEnabled: () => boolean;
  createTeam: () => void;
}

const PlanByTeamModal: React.FC<Props> = ({
  showModal,
  indicatori,
  ruoliTeam,
  roleList,
  handleCloseModal,
  onDateChange,
  getDefaultDateRange,
  addRole,
  handleRoleChange,
  handleChangeAmounts,
  handleSeniorityChange,
  deleteRole,
  isConfermaEnabled,
  createTeam,
}) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  const calculateCost = (index: number) => {
    const ruolo = ruoliTeam[index];
    if (!ruolo.seniority?.costo || !ruolo.giorniUomo || !ruolo.numPersone) return '-';
    return ruolo.seniority.costo * ruolo.giorniUomo + ' €/gg';
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} size="xl" centered scrollable>
      <Modal.Header className="card-header card-header-border" closeButton>
        <Modal.Title>
          <b className="text-blue-links">{t('project-detail.compile-before-start')}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pe-4 modal-520h">
        <Row
          className="d-flex align-items-center pt-3 sticky-top"
          style={{ backgroundColor: '#f3f4f7', boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)' }}
        >
          <Col xs={2} className="mb-3">
            <b className="text-gray-links text-sm">{t('project-detail.quadrature-indicators')}</b>
          </Col>
          <Col xs={10}>
            <Row>
              <Col xs={2}>
                <div className="span-values">
                  <div className="value text-teal-links">{formatCurrency(indicatori.importoOfferta)}</div>
                  <div className="head">{t('project-detail.offer-amount-€')}</div>
                </div>
              </Col>
              <Col xs={2}>
                <div className="span-values">
                  <div className="value">{formatCurrency(indicatori.costoTotaleStimato)}</div>
                  <div className="head">{t('project-detail.extimated-costs-€')}</div>
                </div>
              </Col>
              <Col xs={2}>
                <div className="span-values">
                  <div className="value">{formatCurrency(indicatori.scostamento)}</div>
                  <div className="head">{t('project-detail.deviation')}</div>
                </div>
              </Col>
              <Col xs={2}>
                <div className="span-values">
                  <div className="d-flex">
                    <div className="value text-teal-links">{formatCurrency(indicatori.totaleGiorniUomo, 0)}</div>
                    <div className="value">{`/${formatCurrency(indicatori.nRisorse, 0)}`}</div>
                  </div>
                  <div className="head">{t('project-detail.gu-number-persons')}</div>
                </div>
              </Col>
              <Col xs={2}>
                <div className="span-values">
                  <div className="value">{formatCurrency(indicatori.ricavi, 2)}</div>
                  <div className="head">{t('project-detail.revenues-€')}</div>
                </div>
              </Col>
              <Col xs={2}>
                <div className="span-values">
                  <div className="value">{formatCurrency(indicatori.margine, 2)}</div>
                  <div className="head">{t('project-detail.margin-%')}</div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="mt-3">
          <div className="d-flex flex-column">
            <h6 className="text-blue-links mb-3">{t('project-detail.define-project-duration')}</h6>
            <Form>
              <Col sm="5">
                <Form.Label className="text-gray-links text-sm me-3">{t('project-detail.select-period')}</Form.Label>
                <RangePicker
                  picker="month"
                  format="MM/YYYY"
                  locale={locale}
                  getPopupContainer={(triggerMode) => {
                    return triggerMode.parentNode as HTMLElement;
                  }}
                  onChange={(dates: any, dateStrings: [string, string]) => onDateChange(dates, dateStrings)}
                  defaultValue={getDefaultDateRange()}
                />
              </Col>
            </Form>
          </div>
        </div>
        <div className="mt-3">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="text-blue-links mb-3">{t('project-detail.team-members')}</h6>
              <LinksButton className="btn-sm btn-links-outline px-4" onClick={() => addRole()}>
                {t('project-detail.add-role')}
              </LinksButton>
            </div>

            {ruoliTeam.map((ruolo, index) => (
              <Row
                className="d-flex align-items-center py-3"
                style={{ borderBottom: '1px solid #dee2e6' }}
                key={ruolo.id}
              >
                <Col xs={3}>
                  <Form.Label className="text-gray-links text-sm">
                    {t('project-detail.professional-profile')}
                  </Form.Label>
                  <Select
                    className="w-100"
                    placeholder={t('project-detail.select-profile')}
                    getPopupContainer={(triggerMode) => {
                      return triggerMode.parentNode as HTMLElement;
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    value={
                      ruoliTeam[index].idPps
                        ? {
                            value: ruoliTeam[index].idPps,
                            label: roleList.find((el) => el.idPps == ruoliTeam[index].idPps)?.descrizionePps,
                          }
                        : undefined
                    }
                    onChange={(e) => handleRoleChange(e, index)}
                  >
                    {roleList.map((el) => (
                      <Select.Option key={`${el.idPps}-${index}`} value={el.idPps}>
                        {el.descrizionePps}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={3}>
                  <Form.Label className="text-gray-links text-sm">{t('project-detail.seniority')}</Form.Label>
                  <Select
                    className="w-100"
                    placeholder={t('project-detail.select-seniority')}
                    getPopupContainer={(triggerMode) => {
                      return triggerMode.parentNode as HTMLElement;
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    value={
                      ruoliTeam[index].seniority?.ppt
                        ? {
                            value: ruoliTeam[index].seniority?.ppt,
                            label: roleList
                              .find((el) => el.idPps == ruoliTeam[index].seniority?.ppt)
                              ?.pptList?.find((el) => el.idCostoProfilo == ruoliTeam[index].seniority?.ppt)
                              ?.descrizioneCostoProfilo,
                          }
                        : undefined
                    }
                    onChange={(e) => handleSeniorityChange(e, index)}
                  >
                    {roleList
                      .find((role) => role.descrizionePps === ruolo.descrizionePps)
                      ?.pptList?.map((ppt, index) => (
                        <Select.Option key={`${ppt.idCostoProfilo}-${index}`} value={ppt.idCostoProfilo}>
                          {ppt.descrizioneCostoProfilo}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>
                <Col xs={2}>
                  <Form.Label className="text-gray-links text-sm">{t('project-detail.gu-days')}</Form.Label>
                  <InputNumber
                    className="w-100"
                    key={'GU-' + index}
                    value={ruoliTeam[index].giorniUomo}
                    min={1}
                    name="giorniUomo"
                    onChange={(e) => handleChangeAmounts(e, index, 'giorniUomo')}
                  />
                </Col>
                <Col xs={1}>
                  <Form.Label className="text-gray-links text-sm">{t('project-detail.number-persons')}</Form.Label>
                  <InputNumber
                    className="w-100"
                    key={'nPersone-' + index}
                    value={ruoliTeam[index].numPersone}
                    min={1}
                    name="numPersone"
                    onChange={(e) => handleChangeAmounts(e, index, 'numPersone')}
                  />
                </Col>
                <Col xs={2} className="d-flex flex-column">
                  <Form.Label className="text-gray-links text-sm">{t('project-detail.standard-cost')}</Form.Label>
                  <span className="fw-bold text-gray-links py-1">{calculateCost(index)}</span>
                </Col>
                <Col xs={1}>
                  <LinksButton className="btn-icon" onClick={() => deleteRole(index)}>
                    <i className="icon-delete" style={{ color: 'red' }}></i>
                  </LinksButton>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <LinksButton className="btn-sm btn-links-outline px-4" onClick={handleCloseModal}>
          {t('common.cancel')}
        </LinksButton>
        <LinksButton
          className="btn-sm btn-primary btn-links px-4"
          isDisabled={!isConfermaEnabled()}
          onClick={() => createTeam()}
        >
          {t('common.confirm')}
        </LinksButton>
      </Modal.Footer>
    </Modal>
  );
};

export default PlanByTeamModal;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

export const selectNotesState = (state: RootState) => state.private.projectMessages;

export const selectProjectMessages = createSelector(
  selectNotesState,
  (projectMessagesState) => projectMessagesState.messages
);

export const selectProjectMessageCategories = createSelector(
  selectNotesState,
  (projectMessagesState) => projectMessagesState.categories
);

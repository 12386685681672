import { useEffect, useState } from 'react';
import { useAppDispatch } from 'core/store/hooks';
import {
  CreateUpdateProjectDto,
  CreateUpdateProjectQuoteDto,
  CreateUpdateQuoteDto,
  SearchFilterDto,
  SearchFilterDtoStatiEnum,
  SearchFilterDtoTipologieEnum,
  SearchUsersRequest,
  UserDto,
} from '@api/client';
import { FormikState } from 'formik';
import { crudCreateProject, crudGetCategories, crudReset, crudUpdateProject, getCrudFilters } from '../_redux/actions';
import dayjs from 'dayjs';
import { typologiesData } from 'enums/typologies';
import { getUsers } from '../../project-list/project-detail/_redux/actions';

const useFormCrud = (
  allUsers: UserDto[] | null,
  crudFilters: SearchFilterDto | null | undefined,
  projectWrapper: CreateUpdateProjectQuoteDto | null | undefined
) => {
  const { createUpdateProjectDto, createUpdateQuoteDto } = projectWrapper || {};
  const [states, setStates] = useState<{ id: number; value: SearchFilterDtoStatiEnum }[] | undefined>([]);
  const [types, setTypes] = useState<{ id: number; value: SearchFilterDtoTipologieEnum; label: string }[] | undefined>(
    []
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const request: SearchUsersRequest = {
      userSearchRequest: {},
    };
    if (!allUsers) dispatch(getUsers(request));
    dispatch(getCrudFilters());
    dispatch(crudGetCategories());

    return () => {
      dispatch(crudReset());
    };
  }, []);

  const initialValues: Partial<FormikState<unknown>> = {
    values: {
      idStato: createUpdateProjectDto?.idStato ?? undefined,
      idType: createUpdateProjectDto?.idType ?? undefined,
      name: createUpdateProjectDto?.name ?? '',
      description: createUpdateProjectDto?.description ?? '',
      startDate: createUpdateProjectDto?.startDate ?? undefined,
      endDate: createUpdateProjectDto?.endDate ?? undefined,
      idBusinessManager: createUpdateProjectDto?.idBusinessManager ?? undefined,
      idProjectManager: createUpdateProjectDto?.idProjectManager ?? undefined,
      clientId: createUpdateProjectDto?.clientId ?? undefined,
      idOpportunity: createUpdateProjectDto?.idOpportunity ?? '',
      idOpportunityPadre: createUpdateProjectDto?.idOpportunityPadre ?? '',
      intranetId: createUpdateProjectDto?.intranetId ?? '',
      idStrutturaBusiness: createUpdateProjectDto?.idStrutturaBusiness ?? undefined,
      idStrutturaDelivery: createUpdateProjectDto?.idStrutturaDelivery ?? undefined,
      idAzienda: createUpdateProjectDto?.idAzienda ?? undefined,
      // tipoCostoRicavo: createUpdateProjectDto?.tipoCostoRicavo ?? undefined,
      bidAmount: createUpdateQuoteDto?.bidAmount ?? '',
      externalQuoteId: createUpdateQuoteDto?.externalQuoteId ?? '',
      idContratto: createUpdateProjectDto?.idContract ?? '',
      bidCosts: createUpdateQuoteDto?.bidCosts ?? '',
      bidDays: createUpdateQuoteDto?.bidDays ?? '',
      bidHrcosts: createUpdateQuoteDto?.bidHrcosts ?? '',
      bidOthercosts: createUpdateQuoteDto?.bidOthercosts ?? '',
      nonWorkableAmount: createUpdateProjectDto?.nonWorkableAmount ?? '',
      codiceRicavo: createUpdateProjectDto?.codiceRicavo?.id ?? undefined,
      codiceCosto: createUpdateProjectDto?.codiceCosto?.id ?? undefined,
      codiceCostoLv2: createUpdateProjectDto?.codiceCostoLv2?.id ?? undefined,
      codiceCostoLv3: createUpdateProjectDto?.codiceCostoLv3?.id ?? undefined,
      categories: createUpdateProjectDto?.categories?.[0]?.id ?? undefined,
      isPrjCoordinamentoFrom: createUpdateProjectDto?.isPrjCoordinamentoFrom ?? false,
      isPrjCoordinamentoTo: createUpdateProjectDto?.isPrjCoordinamentoTo ?? false,
      isDeleted: createUpdateProjectDto?.isDeleted ?? false,
    },
  };
  const handleSubmit = (values: Partial<CreateUpdateProjectDto & CreateUpdateQuoteDto>) => {
    const payload: CreateUpdateProjectQuoteDto = {
      createUpdateProjectDto: {
        id: createUpdateProjectDto?.id ?? 0,
        idStato: values.idStato,
        idType: Number(values.idType),
        name: values.name,
        description: values.description,
        startDate: dayjs(values.startDate).add(12, 'hour').toDate(),
        endDate: dayjs(values.endDate).add(12, 'hour').toDate(),
        projectManager: allUsers?.find((el) => el.id === values.idProjectManager)?.nominativo,
        idProjectManager: values.idProjectManager,
        businessUnit: createUpdateProjectDto?.businessUnit ?? undefined,
        client: crudFilters?.clienti?.find((el) => el.id === values.clientId)?.denominazione,
        creationDate: createUpdateProjectDto?.creationDate ?? undefined,
        lastmodifiedDate: createUpdateProjectDto?.lastmodifiedDate ?? undefined,
        idOpportunity: values.idOpportunity,
        compenteceCenters: createUpdateProjectDto?.compenteceCenters ?? undefined,
        clientId: values.clientId,
        intranetId: values.intranetId,
        idCompetenceCenter: createUpdateProjectDto?.idCompetenceCenter ?? undefined,
        idStrutturaBusiness: values.idStrutturaBusiness,
        idStrutturaDelivery: values.idStrutturaDelivery,
        idAzienda: values.idAzienda,
        idContract: values.idContratto,
        nonWorkableAmount: values.nonWorkableAmount,
        codiceRicavo: crudFilters?.codiciRicavo?.find((el) => el.id === values.codiceRicavo),
        codiceCosto: crudFilters?.codiciCosto?.find((el) => el.id === values.codiceCosto),
        codiceCostoLv2: crudFilters?.codiciCostolv2?.find((el) => el.id === values.codiceCostoLv2),
        codiceCostoLv3: crudFilters?.codiciCostolv3?.find((el) => el.id === values.codiceCostoLv3),
        categories: values.categories ? [{ id: Number(values.categories) }] : undefined,
        isPrjCoordinamentoFrom: values?.isPrjCoordinamentoFrom ?? false,
        isPrjCoordinamentoTo: values?.isPrjCoordinamentoTo ?? false,
        isDeleted: values?.isDeleted ?? false,
      },
      createUpdateQuoteDto: {
        id: createUpdateQuoteDto?.id ?? 0,
        projectId: createUpdateProjectDto?.id ?? 0,
        bidAmount: values.bidAmount,
        bidCosts: values.bidCosts,
        bidDays: values.bidDays,
        bidHrcosts: values.bidHrcosts,
        bidOthercosts: values.bidOthercosts,
        rejected: createUpdateQuoteDto?.rejected ?? false,
        creationDate: createUpdateQuoteDto?.creationDate ?? undefined,
        lastmodifiedDate: createUpdateQuoteDto?.lastmodifiedDate ?? undefined,
        externalQuoteId: values.externalQuoteId,
        // idContratto: values.idContratto,
      },
    };
    // console.log(payload);
    if (projectWrapper) {
      dispatch(crudUpdateProject(payload));
      // console.log(payload, 'project updated');
    } else {
      dispatch(crudCreateProject(payload));
      // console.log(payload, 'project created');
    }
  };

  useEffect(() => {
    const idMapping = {
      // OFFERING: 1,
      PENDING: 2,
      PLANNING: 3,
      CLOSED_WON_PENDING: 4,
      CLOSED_LOST: 5,
      INTRANET_PENDING: 6,
      WORK_IN_PROGRESS: 7,
      COMPLETED: 8,
      CLOSED_WON_PLANNING: 9,
      PLANNING_OK: 10,
      // WORK_IN_PROGRESS_DRAFT: 11,
      CONTROLLO_CDG: 12,
      // CONTROLLO_RESPONSABILE: 13,
      CONTROLLO_CL: 14,
      REPORTING_CLOSED: 15,
      ECONOMICS_CLOSED: 16,
    };

    const statesMapped = crudFilters?.stati?.map((value) => {
      return { id: idMapping[value], value };
    });

    const typesMapped: any = crudFilters?.tipologie
      ?.map((value) => {
        const typology = typologiesData.find((t) => t.name === value);
        return typology ? { id: typology.id, value: typology.name, label: typology.label } : null;
      })
      .filter((item) => item !== null || undefined);

    setStates(statesMapped);
    setTypes(typesMapped);
  }, [crudFilters?.stati, crudFilters?.tipologie]);
  return { initialValues, handleSubmit, states, types };
};

export default useFormCrud;

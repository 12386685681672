import { Tooltip } from 'antd';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ModalAction from '../modal-action';
import MonthCheckList from '../months-check-list';
import { useState } from 'react';
import { RevenuePlanDto, StaffExtendedDto } from '@api/client';
import useSpreadOutDays from '../../_hooks/useSpreadOutDays';
import { useAppSelector } from 'core/store/hooks';
import { feeRateTableSelector } from '../../_redux/selectors';

interface Props {
  index: number;
  readOnly: boolean | undefined;
  years: (number | undefined)[];
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined;
  fullAllocationsShown?: Partial<StaffExtendedDto>[];
  currentYear: number | 'all';
  totalGUPlanned: number[];
  averagePricePlanned: number[];
  idProject: number | undefined;
  currentCollaboratorName: string;
  currentGu: number;
  setFullAllocations: React.Dispatch<React.SetStateAction<Partial<StaffExtendedDto>[] | null | undefined>>;
  setCurrentCollaboratorName: React.Dispatch<React.SetStateAction<string>>;
  setCurrentGu: React.Dispatch<React.SetStateAction<number>>;
  filterAllocationsByYear: (
    year: number | 'all',
    allocations?: Partial<StaffExtendedDto>[] | null | undefined,
    revenuePlan?: RevenuePlanDto[] | null | undefined
  ) => void;
}

const SpreadOutComponent: React.FC<Props> = ({
  index,
  readOnly,
  years,
  fullAllocations,
  fullAllocationsShown,
  currentYear,
  totalGUPlanned,
  averagePricePlanned,
  idProject,
  currentCollaboratorName,
  currentGu,
  setFullAllocations,
  setCurrentCollaboratorName,
  setCurrentGu,
  filterAllocationsByYear,
}) => {
  const { t } = useTranslation();
  const feeRateTable = useAppSelector(feeRateTableSelector);
  const [showModalSpalma, setShowModalSpalma] = useState(Array(fullAllocationsShown?.length).fill(false));

  const {
    monthOptionsObj,
    checkedList,
    onChangeCheckedList,
    onCheckAllChange,
    spalmaGiorni,
    pasteAveragePriceInSelectedMonths,
  } = useSpreadOutDays(
    fullAllocations,
    currentYear,
    totalGUPlanned,
    averagePricePlanned,
    idProject,
    setFullAllocations,
    filterAllocationsByYear
  );

  const handleShowModalSpalma = (index: number, value: boolean) => {
    const newShowModal = [...showModalSpalma];
    newShowModal[index] = value;
    setShowModalSpalma(newShowModal);

    const collaboratorName = fullAllocationsShown![index].name;
    setCurrentCollaboratorName(collaboratorName!);
    const totalGu = totalGUPlanned?.[index]?.toFixed(2);
    setCurrentGu(+totalGu);
  };

  return (
    <>
      <Tooltip
        title={
          feeRateTable === 'allocations' ? t('project-detail.spread-out-days') : t('project-detail.spread-out-price')
        }
        color="white"
        key={`tooltip-spalma-${index}`}
      >
        <Button
          className="btn-sm btn-icon border-none me-0"
          disabled={readOnly}
          onClick={() => handleShowModalSpalma(index, true)}
        >
          <i className="icon-calendar-spalma icon-primary align-middle" />
        </Button>
      </Tooltip>
      <ModalAction
        title={
          feeRateTable === 'allocations' ? t('project-detail.spread-out-days') : t('project-detail.spread-out-price')
        }
        actionLabel={feeRateTable === 'allocations' ? t('project-detail.divide') : t('project-detail.paste')}
        action={feeRateTable === 'allocations' ? spalmaGiorni : pasteAveragePriceInSelectedMonths}
        index={index}
        setShow={(value) => handleShowModalSpalma(index, value)}
        show={showModalSpalma[index]}
        size="lg"
      >
        <p>
          {`${currentCollaboratorName ?? ''}`} : <b>{`${currentGu === 0 ? 'Inserire Gu' : currentGu + ' Gu'}`}</b>
        </p>
        <MonthCheckList
          monthOptionsObj={monthOptionsObj}
          checkedList={checkedList}
          onChange={onChangeCheckedList}
          onCheckAllChange={onCheckAllChange}
          years={years}
        />
      </ModalAction>
    </>
  );
};

export default SpreadOutComponent;

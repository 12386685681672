import { useTranslation } from 'react-i18next';
import ModalAction from '../modal-action';
import { DatePicker } from 'antd';
import AuthService from 'core/auth/auth.service';
import locale from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import { useState } from 'react';
import LinksButton from 'shared/design-system/components/links-button';

interface Props {
  readOnly: boolean | undefined;
  addMonths: () => void;
  onDateChange: (date: dayjs.Dayjs, dateString: string | string[]) => void;
  disableAllocatedMonths: (current: { year: () => number; month: () => number }) => boolean;
}

const AddMonthComponent: React.FC<Props> = ({ readOnly, addMonths, onDateChange, disableAllocatedMonths }) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [showModalAddMonths, setShowModalAddMonths] = useState(false);

  return (
    <>
      <LinksButton
        className="btn-sm btn btn-links-outline action-button px-1"
        isDisabled={readOnly}
        onClick={() => setShowModalAddMonths(true)}
      >
        <div className="d-flex align-items-center">
          <i className="icon-calendar-add" />
        </div>
      </LinksButton>
      <ModalAction
        title={t('project-detail.add-months')}
        actionLabel={t('common.add')}
        action={addMonths}
        setShow={(value) => setShowModalAddMonths(value)}
        show={showModalAddMonths}
      >
        <p>
          <b>{t('project-detail.add-new-months')}</b>
        </p>
        <div className="p-1">
          <RangePicker
            picker="month"
            format="MM/YYYY"
            minDate={dayjs(new Date())}
            locale={locale}
            dropdownClassName="createDateRangePicker"
            getPopupContainer={(triggerMode) => {
              return triggerMode.parentNode as HTMLElement;
            }}
            disabledDate={!AuthService.isSuperAdmin() ? disableAllocatedMonths : undefined}
            onChange={(dates: any, dateStrings: [string, string]) => onDateChange(dates, dateStrings)}
          />
        </div>
      </ModalAction>
    </>
  );
};

export default AddMonthComponent;

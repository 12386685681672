import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ContractOrderOutputDto } from '@api/client';
import React from 'react';
import {
  activeCycleFattureEmesseSelector,
  activeCycleOrdersAttiviSelector,
  activeCycleProjectsAssociatedSelector,
  activeOrdersSfSelector,
  associableInvoicesActiveCycleSelector,
  associableProjectsActiveCycleSelector,
} from '../_redux/selectors';
import { getActiveCycleFattureEmesse, searchActiveOrders, searchActiveOrdersSf } from '../_redux/actions';
import ActiveCycleOrderData from './_components/active-order-data';
import ActiveOrderDataTable from './_components/active-order-data-table';
import ActiveOrderAssociatedProjectsTable from './_components/active-order-associated-projects-table';
import ActiveOrderAssociatedInvoicesTable from './_components/active-order-associated-invoices-table';
import AssociableProjectsTable from './_components/associable-projects-table';
import { Button } from 'react-bootstrap';
import useFormAssociableActiveInvoices from 'shared/design-system/components/app-accordion/_hooks/useFormAssociableActiveInvoices';
import AccordionForm, {
  projectListFormData,
} from 'shared/design-system/components/app-accordion/_components/accordion-form';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import useAccordionSubmit, {
  FilterProjectsLocation,
} from 'shared/design-system/components/app-accordion/_hooks/useFormSubmit';
import AppCard from 'shared/design-system/components/app-card';
import NoData from 'shared/design-system/components/no-data-box';
import LinksButton from 'shared/design-system/components/links-button';
import AppAccordion from 'shared/design-system/components/app-accordion';
import { LocationFilterEnum } from 'enums/global.enum';
import AssociableActiveInvoicesFilterForm, {
  associableActiveInvoicesFormData,
} from './_components/associable-invoices-filter-form';

const ActiveCycleOrderDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: any }>();
  const [currentOrder, setCurrentOrder] = useState<ContractOrderOutputDto | undefined>(undefined);
  const filteredOrders = useAppSelector(activeOrdersSfSelector);
  const projectsAssociated = useAppSelector(activeCycleProjectsAssociatedSelector);
  const orderAttivo = useAppSelector(activeCycleOrdersAttiviSelector);
  const fattureEmesse = useAppSelector(activeCycleFattureEmesseSelector);
  const associableProjects = useAppSelector(associableProjectsActiveCycleSelector);
  const associableInvoices = useAppSelector(associableInvoicesActiveCycleSelector);

  useEffect(() => {
    if (filteredOrders) {
      const order = filteredOrders.contractOrderOutputDtoList?.find((order) => order.pkOrderSF === id);
      setCurrentOrder(order);
      dispatch(searchActiveOrders({ contractOrderSearchDto: { cicloAttivoOrderNumber: id } }));
    } else {
      dispatch(searchActiveOrdersSf({ contractOrderSFSearchInputDto: { orderId: id, paging: { page: 0, size: 10 } } }));
    }
  }, [filteredOrders]);

  useEffect(() => {
    const orderId = orderAttivo?.[0]?.id;
    if (orderId) {
      dispatch(
        getActiveCycleFattureEmesse({
          request: { fatturaEmessaSearchDto: { numeroOrdine: String(orderId), paging: { size: 9999, page: 0 } } },
          type: 'associated',
        })
      );
    }
  }, [orderAttivo]);

  const [isAssociateNewProjectVisible, setIsAssociateNewProjectVisible] = useState(false);
  const [isAssociateNewInvoiceVisible, setIsAssociateNewInvoiceVisible] = useState(false);
  const [isProjectSectionVisible, setIsProjectSectionVisible] = useState(true);
  const [isInvoiceSectionVisible, setIsInvoiceSectionVisible] = useState(true);
  const [isEconomicsSectionVisible, setIsEconomicsSectionVisible] = useState(true);

  // INIZO - Form filter projects
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset } = useAccordionSubmit(
    projectListFormData,
    setIsOpen,
    FilterProjectsLocation.ACTIVE_CYCLE
  );
  const [controlledDate, setControlledDate] = useState<any>(null);
  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };
  // FINE - Form filter projects

  // INIZIO - Form filter invoices
  const [isOpenInvoice, setIsOpenInvoice] = useState<boolean>(true);
  const { onSubmitInvoices, onResetInvoices, chipsFormInvoicesData, setChipsFormInvoicesData, resetInvoice } =
    useFormAssociableActiveInvoices(
      associableActiveInvoicesFormData,
      setIsOpenInvoice,
      currentOrder?.startDate || new Date(),
      orderAttivo?.[0]?.fkCliente
    );
  const handleResetInvoices = () => {
    onResetInvoices();
    chipsFormInvoicesData && setChipsFormInvoicesData([]);
  };
  // FINE - Form filter invoices

  useEffect(() => {
    if (isAssociateNewProjectVisible) {
      window.scrollBy({ top: 600, behavior: 'smooth' });
    }
  }, [isAssociateNewProjectVisible]);

  useEffect(() => {
    if (isAssociateNewInvoiceVisible) {
      window.scrollBy({ top: 250, behavior: 'smooth' });
    }
  }, [isAssociateNewInvoiceVisible]);

  return (
    <>
      <h4 className="text-gray-800 my-0">{t('Order')}</h4>

      {!currentOrder && (
        <AppCard title={t('Order data')} className="border-left-primary mt-3">
          <div className="mt-3">
            <NoData msg={t('No order found')} />
          </div>
        </AppCard>
      )}

      {currentOrder && (
        <>
          <ActiveCycleOrderData order={currentOrder} />
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <b className="text-blue-links">{t('Economics data')}</b>
            <Button
              className="btn text-blue-links border-none p-0"
              type="button"
              variant="icon"
              onClick={() => {
                setIsEconomicsSectionVisible(!isEconomicsSectionVisible);
              }}
            >
              {isEconomicsSectionVisible ? (
                <i className="fas fa-chevron-up text-blue-links" />
              ) : (
                <i className="fas fa-chevron-down text-blue-links" />
              )}
            </Button>
          </div>

          <hr className="solid mt-2" />
          {isEconomicsSectionVisible && <ActiveOrderDataTable order={currentOrder} />}

          {projectsAssociated.length && (
            <>
              {/* ASSOCIATE INVOICE BUTTON */}
              <div className="d-flex justify-content-between mt-3 mb-1">
                <b className="text-blue-links">{t('Invoices issued')}</b>
                <div>
                  {isInvoiceSectionVisible && (
                    <LinksButton
                      className="btn-sm btn-primary btn-teal px-4 ms-auto"
                      disabled={!projectsAssociated.length}
                      onClick={() => {
                        setIsAssociateNewInvoiceVisible(!isAssociateNewInvoiceVisible);
                      }}
                    >
                      {t('+ Associate invoice')}
                    </LinksButton>
                  )}
                  <Button
                    className="btn text-blue-links border-none p-0"
                    type="button"
                    variant="icon"
                    onClick={() => {
                      setIsInvoiceSectionVisible(!isInvoiceSectionVisible);
                      setIsAssociateNewInvoiceVisible(false);
                    }}
                  >
                    {isInvoiceSectionVisible ? (
                      <i className="fas fa-chevron-up text-blue-links" />
                    ) : (
                      <i className="fas fa-chevron-down text-blue-links" />
                    )}
                  </Button>
                </div>
              </div>
              <hr className="solid mt-2" />

              {/* ASSOCIATED INVOICES */}
              {isInvoiceSectionVisible && (
                <ActiveOrderAssociatedInvoicesTable invoices={fattureEmesse} showAddInvoice={false} />
              )}

              {/* ASSOCIATE NEW INVOICE CARD */}
              {isAssociateNewInvoiceVisible && (
                <AppCard
                  title="Associate new invoice"
                  className="border-left-primary mt-3"
                  headerClassname="d-flex justify-content-between"
                  headerElement={
                    <Button
                      className="btn text-blue-links p-0"
                      type="button"
                      variant="icon"
                      onClick={() => setIsAssociateNewInvoiceVisible(false)}
                    >
                      <i className="fas fa-x text-blue-links" />
                    </Button>
                  }
                >
                  <AppAccordion
                    formData={associableActiveInvoicesFormData}
                    location={LocationFilterEnum.ACTIVE_CYCLE_INVOICES}
                    setIsOpen={setIsOpenInvoice}
                    isOpen={isOpenInvoice}
                    chipsFormData={chipsFormInvoicesData}
                    setChipsFormData={setChipsFormInvoicesData}
                    reset={resetInvoice}
                  >
                    <AssociableActiveInvoicesFilterForm
                      onSubmitInvoices={onSubmitInvoices}
                      handleResetInvoices={handleResetInvoices}
                    />
                  </AppAccordion>
                  <ActiveOrderAssociatedInvoicesTable
                    invoices={associableInvoices}
                    idOrdine={orderAttivo?.[0]?.id}
                    idOrdineSf={currentOrder.pkOrderSF}
                    associatedProjects={projectsAssociated}
                    showAddInvoice={true}
                  />
                </AppCard>
              )}
            </>
          )}
          {/* ASSOCIATE PROJECT BUTTON */}
          <div className="d-flex align-items-center justify-content-between mt-3 mb-1">
            <b className="text-blue-links">{t('Projects')}</b>
            <div>
              {isProjectSectionVisible && (
                <LinksButton
                  className="btn-sm btn-primary btn-teal px-4 ms-auto"
                  onClick={() => {
                    setIsAssociateNewProjectVisible(true);
                    window.scrollBy({ top: 400, behavior: 'smooth' });
                  }}
                >
                  {t('+ Associate project')}
                </LinksButton>
              )}
              <Button
                className="btn text-blue-links border-none p-0"
                type="button"
                variant="icon"
                onClick={() => {
                  setIsProjectSectionVisible(!isProjectSectionVisible);
                  setIsAssociateNewProjectVisible(false);
                }}
              >
                {isProjectSectionVisible ? (
                  <i className="fas fa-chevron-up text-blue-links" />
                ) : (
                  <i className="fas fa-chevron-down text-blue-links" />
                )}
              </Button>
            </div>
          </div>
          <hr className="solid mt-2" />

          {/* ASSOCIATED PROJECTS */}
          {isProjectSectionVisible && (
            <ActiveOrderAssociatedProjectsTable
              projects={projectsAssociated}
              orderId={currentOrder.pkOrderSF}
              numericOrderId={orderAttivo?.[0]?.id}
            />
          )}

          {/* ASSOCIATE NEW PROJECT CARD */}
          {isAssociateNewProjectVisible && (
            <AppCard
              title="Associate new project"
              className="border-left-primary mt-3"
              headerClassname="d-flex justify-content-between"
              headerElement={
                <Button
                  className="btn text-blue-links p-0"
                  type="button"
                  variant="icon"
                  onClick={() => setIsAssociateNewProjectVisible(false)}
                >
                  <i className="fas fa-x text-blue-links" />
                </Button>
              }
            >
              <AppAccordion
                formData={projectListFormData}
                location={LocationFilterEnum.PROJECT_LIST}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                chipsFormData={chipsFormData}
                setChipsFormData={setChipsFormData}
                reset={reset}
              >
                <AccordionForm
                  onSubmit={onSubmit}
                  handleReset={handleReset}
                  setControlledDate={setControlledDate}
                  // onReset={onReset} control={control} register={register}
                />
              </AppAccordion>
              <AssociableProjectsTable projects={associableProjects} orderId={currentOrder.pkOrderSF} />
            </AppCard>
          )}
        </>
      )}
    </>
  );
};

export default ActiveCycleOrderDetail;

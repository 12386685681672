import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AuthService from 'core/auth/auth.service';
import { MOCK_TOKEN } from 'env';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = MOCK_TOKEN;
    AuthService.setAuthentication(token);
    AuthService.setUser();
    navigate('/');
  }, []);

  return <></>;
};

export default LoginPage;

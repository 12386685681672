import { ExternalCostDto, StaffExtendedDto, UpdateCostsRequest } from '@api/client';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { saveTeamData, updateStaff } from '../_redux/actions';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { useTranslation } from 'react-i18next';
import { getProjectDetails } from '../../_redux/actions';
import { revenueProgressSelector } from '../_redux/selectors';

const useSaveDraft = (
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined,
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const revenueProgress = useAppSelector(revenueProgressSelector);

  const saveBozza = (idProject: number | undefined, costsData: ExternalCostDto[] | null | undefined) => {
    if (!idProject) return;
    if (
      fullAllocations?.some(
        (el) => !el.idPps && !el.idPpt && !el.userId && el.allocazioni?.some((el) => el.actualAllocation !== 0)
      )
    ) {
      AppToastService.error(t('project-detail.toast-select-profile-before-save'));
      return;
    }

    let request: UpdateCostsRequest = {
      idProject: idProject,
      updateCostsInputDto: {
        fullAllocations: fullAllocations
          ?.map((el) => ({
            idPps: Number(el.idPps),
            idPpt: Number(el.idPpt),
            userId: el.userId,
            allocazioni: el.allocazioni?.map((el) => ({
              id: el.id,
              month: el.month,
              year: el.year,
              actualAllocation: el.actualAllocation,
              sellingPrice: el.sellingPrice,
            })),
          }))
          .filter((el) => el.idPps || el.idPpt || el.userId || el.allocazioni?.some((el) => el.actualAllocation !== 0)),
        otherCosts: costsData || undefined,
        revenueDaConsolidareMonthList: revenueProgress || undefined,
      },
    };
    // console.log(request);
    dispatch(updateStaff(request))
      .unwrap()
      .then((res) => {
        if (res) {
          setDraftValidation(true);
          dispatch(getProjectDetails(idProject))
            .unwrap()
            .then((res) => {
              if (res) {
                setRefresh(true);
                if (res.staffList?.length === 0) {
                  dispatch(saveTeamData(null));
                }
              }
            });
        }
      });
  };

  return { saveBozza };
};

export default useSaveDraft;

import { Checkbox, Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getOptionsByYear } from '../../_utils/revenue-plan.utils';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';
import { getFormattedDateString } from 'shared/utils/common.utils';

interface MonthCheckListProps {
  monthOptionsObj: {
    label: number | undefined;
    value: string;
  }[];
  checkedList: CheckboxValueType[];
  onChange: (list: any[]) => void;
  onCheckAllChange: (e: CheckboxChangeEvent) => void;
  years: (number | undefined)[];
}

const MonthCheckList: React.FC<MonthCheckListProps> = ({
  monthOptionsObj,
  checkedList,
  onChange,
  onCheckAllChange,
  years,
}) => {
  const { t } = useTranslation();
  const checkAll = monthOptionsObj.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < monthOptionsObj.length;
  return (
    <div className="p-1">
      <Checkbox className="py-1" indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        {t('project-detail.select-all')}
      </Checkbox>
      <Checkbox.Group value={checkedList} style={{ width: '100%' }} onChange={onChange} className="d-flex flex-column">
        {years.map((year, index) => {
          const optionsByYear = getOptionsByYear(monthOptionsObj, year!);
          if (optionsByYear.length > 0) {
            return (
              <React.Fragment key={'cb-yearr' + index}>
                <Row className="mb-1">
                  <Col span={2}>
                    <Checkbox value={year} style={{ flexDirection: 'row-reverse' }}>
                      <h6 className="text-left my-2 me-1">{year}</h6>
                    </Checkbox>
                  </Col>
                  <Col span={22}>
                    <Divider className="my-3 mx-3" />
                  </Col>
                </Row>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    gridTemplateRows: 'repeat(2, 1fr)',
                  }}
                >
                  {optionsByYear.map((el) => {
                    return (
                      <Row key={`year-${el.value}`} className="d-flex" style={{ minWidth: '120px' }}>
                        <Col span={8}>
                          <Checkbox value={el.value} className="mb-1">
                            {getFormattedDateString(Number(el.label))}
                          </Checkbox>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          }
          return null;
        })}
      </Checkbox.Group>
    </div>
  );
};

export default MonthCheckList;

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { GetUserListRequest, SearchUsersRequest, UserListFilterDto } from '@api/client';
import { StorageKeysEnum } from 'enums/storageKeys.enum';
import { filtersDashboardPeopleUsedSelector } from 'pages/private/dashboard/_redux/selectors';
import {
  getDashboardUsers,
  getOverbookingUsers,
  getUnderbookingUsers,
  saveDashboardPeopleFilters,
} from 'pages/private/dashboard/_redux/actions';
import { allUsersSelector } from 'pages/private/project-list/project-detail/_redux/selectors';
import { getUsers } from 'pages/private/project-list/project-detail/_redux/actions';
import { DashboardUserTables } from 'enums/dashboard.enum';

const useFormDashboardPeople = (initialData: any, table: DashboardUserTables) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const allUsers = useAppSelector(allUsersSelector);
  const filtersUsed = useAppSelector(filtersDashboardPeopleUsedSelector);
  const [chipsFormData, setChipsFormData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);

  useEffect(() => {
    const request: SearchUsersRequest = {
      userSearchRequest: {},
    };
    if (!allUsers) dispatch(getUsers(request));
  }, []);

  const { reset, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  const onSubmit = (data: any) => {
    const currentPageKey = getCurrentPageKey();
    sessionStorage.setItem(currentPageKey, String(0));
    isSubmitting.current = true;
    const formDataArray = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormData([...formDataArray]);

    const filtersDashboard: UserListFilterDto = {};

    if (data.idProject) {
      filtersDashboard.idProject = data.idProject;
    }
    if (data.projectName) {
      filtersDashboard.projectName = data.projectName;
    }
    if (data.plUserId) {
      filtersDashboard.plUserId = data.plUserId;
    }
    if (data.personaIdList) {
      filtersDashboard.personaIdList = data.personaIdList;
    }
    if (data.mesiAnni) {
      filtersDashboard.mesiAnni = data.mesiAnni;
    }
    if (data.allProject) {
      filtersDashboard.allProject = data.allProject;
    }
    if (data.isUserActive) {
      filtersDashboard.isUserActive = data.isUserActive;
    }

    const payload: GetUserListRequest = {
      userListFilterDto: { ...filtersDashboard },
    };

    dispatch(saveDashboardPeopleFilters(filtersDashboard));
    switch (table) {
      case DashboardUserTables.RESOURCE_PLANNING:
        dispatch(getDashboardUsers(payload))
          .unwrap()
          .then((res: any) => {});
        break;
      case DashboardUserTables.UNDERBOOKING:
        dispatch(getUnderbookingUsers(payload))
          .unwrap()
          .then((res: any) => {});
        break;
      case DashboardUserTables.OVERBOOKING:
        dispatch(getOverbookingUsers(payload))
          .unwrap()
          .then((res: any) => {});
        break;
      default:
        break;
    }

    handleSubmit(data);
  };

  const onReset = () => {
    reset(initialData);
    setChipsFormData([]);
    dispatch(saveDashboardPeopleFilters(initialData));
  };

  const getCurrentPageKey = () => {
    switch (table) {
      case DashboardUserTables.RESOURCE_PLANNING:
        return StorageKeysEnum.CURRENT_PAGE_DASHBOARD_USERS;
      case DashboardUserTables.UNDERBOOKING:
        return StorageKeysEnum.CURRENT_PAGE_DASHBOARD_UNDERBOOKING;
      case DashboardUserTables.OVERBOOKING:
        return StorageKeysEnum.CURRENT_PAGE_DASHBOARD_OVERBOOKING;
      default:
        return '';
    }
  };

  return { onSubmit, onReset, filterParams, chipsFormData, setChipsFormData, reset };
};

export default useFormDashboardPeople;

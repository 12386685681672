import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'shared/utils/common.utils';
import { ValueType } from 'enums/global.enum';
import { Totals } from '../projects-hooks/useGetProjectTotals';

function useGetBookingTotals(personCount: number, totalNFTE: number) {
  const { t } = useTranslation();
  const [totals, setTotals] = useState<Totals[]>([]);

  useEffect(() => {
    if (!personCount && !totalNFTE) return;
    const tot = getTotals();
    setTotals(tot);
  }, [personCount, totalNFTE]);

  const getTotals = () => {
    const newFields: Totals[] = [];

    newFields.push(
      {
        label: `${t('Resources Count')}`,
        value: `${formatCurrency(personCount, 0) ?? '-'}`,
        span: ValueType.NULL,
        col: 2,
        color: '#22519e',
        border: 'border-left-primary',
      },
      {
        label: `${t('Total FTE')}`,
        value: `${formatCurrency(totalNFTE, 0) ?? '-'}`,
        span: ValueType.NULL,
        col: 2,
        color: '#22519e',
        border: 'border-left-primary',
      }
    );

    return newFields;
  };

  return { totals };
}

export default useGetBookingTotals;

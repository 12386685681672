import { ContractOrderOutputDto } from '@api/client';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'shared/utils/common.utils';

interface Props {
  order: ContractOrderOutputDto;
}

const PassiveOrderEconomicsData: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();

  if (order) {
    return (
      <div className="table-responsive table-bordered mt-3">
        <Table className={'mt-0 mb-0'}>
          <thead className="bg-red">
            <tr>
              <th scope="col">
                <span>{'Supply amount'}</span>
              </th>
              <th scope="col">
                <span>{'A. Costs'}</span>
              </th>
              <th scope="col">
                <span>{'Invoices received'}</span>
              </th>
              <th scope="col">
                <span>{'FdR'}</span>
              </th>
              <th scope="col">
                <span>{'Remaining balance'}</span>
              </th>
              <th scope="col">
                <span>{'Link Doc'}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-end">
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">{formatCurrency(order.contractValue)}€</p>
                </span>
              </td>
              <td className="text-end">
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">
                    {order.ordCPACostiEsterni !== undefined ? `${formatCurrency(order.ordCPACostiEsterni)}€` : '-'}
                  </p>
                </span>
              </td>
              <td className="text-end">
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">
                    {order.ordCPTotFattureRicevute !== undefined
                      ? `${formatCurrency(order.ordCPTotFattureRicevute)}€`
                      : '-'}
                  </p>
                </span>
              </td>
              <td className="text-end">
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">{order.ordCPFdR !== undefined ? `${formatCurrency(order.ordCPFdR)}€` : '-'}</p>
                </span>
              </td>
              <td className="text-end">
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">
                    {order.ordCPResiduo !== undefined ? `${formatCurrency(order.ordCPACostiEsterni)}€` : '-'}
                  </p>
                </span>
              </td>
              <td>
                {order.linkDocumentation ? (
                  <a href={order.linkDocumentation} target="_blank" rel="noreferrer">
                    <i className="icon-noun-link text-blue-links fs-6" />
                  </a>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '4rem' }}>
        {t('No order details')}
      </div>
    );
  }
};

export default PassiveOrderEconomicsData;

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractOrderExternalCostDtoFromJSON, ContractOrderExternalCostDtoToJSON, } from './ContractOrderExternalCostDto';
/**
 * Check if a given object implements the CreateContractOrderDto interface.
 */
export function instanceOfCreateContractOrderDto(value) {
    return true;
}
export function CreateContractOrderDtoFromJSON(json) {
    return CreateContractOrderDtoFromJSONTyped(json, false);
}
export function CreateContractOrderDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'contractOrderId': json['contractOrderId'] == null ? undefined : json['contractOrderId'],
        'contractStatus': json['contractStatus'] == null ? undefined : json['contractStatus'],
        'processingStatus': json['processingStatus'] == null ? undefined : json['processingStatus'],
        'expenseItemList': json['expenseItemList'] == null ? undefined : json['expenseItemList'],
        'contractValue': json['contractValue'] == null ? undefined : json['contractValue'],
        'resaleProjectCode': json['resaleProjectCode'] == null ? undefined : json['resaleProjectCode'],
        'resaleDetachmentRate': json['resaleDetachmentRate'] == null ? undefined : json['resaleDetachmentRate'],
        'automaticRenewal': json['automaticRenewal'] == null ? undefined : json['automaticRenewal'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'signatureDate': json['signatureDate'] == null ? undefined : (new Date(json['signatureDate'])),
        'note': json['note'] == null ? undefined : json['note'],
        'contractOrderExternalCostDtoList': json['contractOrderExternalCostDtoList'] == null ? undefined : (json['contractOrderExternalCostDtoList'].map(ContractOrderExternalCostDtoFromJSON)),
        'fornitore': json['fornitore'] == null ? undefined : json['fornitore'],
        'linkDocumentation': json['linkDocumentation'] == null ? undefined : json['linkDocumentation'],
        'category': json['category'] == null ? undefined : json['category'],
        'legalEntity': json['legalEntity'] == null ? undefined : json['legalEntity'],
        'cancellationWithin': json['cancellationWithin'] == null ? undefined : (new Date(json['cancellationWithin'])),
        'cancelAtAnyTime': json['cancelAtAnyTime'] == null ? undefined : json['cancelAtAnyTime'],
        'withdrawalWithin': json['withdrawalWithin'] == null ? undefined : (new Date(json['withdrawalWithin'])),
        'cancellationProcedure': json['cancellationProcedure'] == null ? undefined : json['cancellationProcedure'],
        'object': json['object'] == null ? undefined : json['object'],
        'withdrawalWithinLiteral': json['withdrawalWithinLiteral'] == null ? undefined : json['withdrawalWithinLiteral'],
        'idExternalContract': json['idExternalContract'] == null ? undefined : json['idExternalContract'],
        'fkUtente': json['fkUtente'] == null ? undefined : json['fkUtente'],
        'idSede': json['idSede'] == null ? undefined : json['idSede'],
        'linkTicket': json['linkTicket'] == null ? undefined : json['linkTicket'],
        'fkUtenteEsterno': json['fkUtenteEsterno'] == null ? undefined : json['fkUtenteEsterno'],
        'tariffa': json['tariffa'] == null ? undefined : json['tariffa'],
        'quantita': json['quantita'] == null ? undefined : json['quantita'],
    };
}
export function CreateContractOrderDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'contractOrderId': value['contractOrderId'],
        'contractStatus': value['contractStatus'],
        'processingStatus': value['processingStatus'],
        'expenseItemList': value['expenseItemList'],
        'contractValue': value['contractValue'],
        'resaleProjectCode': value['resaleProjectCode'],
        'resaleDetachmentRate': value['resaleDetachmentRate'],
        'automaticRenewal': value['automaticRenewal'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'signatureDate': value['signatureDate'] == null ? undefined : ((value['signatureDate']).toISOString()),
        'note': value['note'],
        'contractOrderExternalCostDtoList': value['contractOrderExternalCostDtoList'] == null ? undefined : (value['contractOrderExternalCostDtoList'].map(ContractOrderExternalCostDtoToJSON)),
        'fornitore': value['fornitore'],
        'linkDocumentation': value['linkDocumentation'],
        'category': value['category'],
        'legalEntity': value['legalEntity'],
        'cancellationWithin': value['cancellationWithin'] == null ? undefined : ((value['cancellationWithin']).toISOString()),
        'cancelAtAnyTime': value['cancelAtAnyTime'],
        'withdrawalWithin': value['withdrawalWithin'] == null ? undefined : ((value['withdrawalWithin']).toISOString()),
        'cancellationProcedure': value['cancellationProcedure'],
        'object': value['object'],
        'withdrawalWithinLiteral': value['withdrawalWithinLiteral'],
        'idExternalContract': value['idExternalContract'],
        'fkUtente': value['fkUtente'],
        'idSede': value['idSede'],
        'linkTicket': value['linkTicket'],
        'fkUtenteEsterno': value['fkUtenteEsterno'],
        'tariffa': value['tariffa'],
        'quantita': value['quantita'],
    };
}

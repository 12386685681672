import { CostTypeDto, ExternalCostDto } from '@api/client';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { saveCostsData } from '../../_redux/actions';
import { costsDataSelector } from '../../_redux/selectors';

const useGetOtherCostsTableData = (
  otherCosts: ExternalCostDto[] | undefined,
  costList: CostTypeDto[] | null,
  refresh?: boolean
) => {
  const dispatch = useAppDispatch();
  const costsDataFromStore = useAppSelector(costsDataSelector);
  const [otherCostsTableData, setOtherCostsTableData] = useState<ExternalCostDto[] | null | undefined>(
    costsDataFromStore
  );
  const [otherCostsTableDataShown, setOtherCostsTableDataShown] = useState<ExternalCostDto[] | null | undefined>(
    costsDataFromStore
  );
  const [totalOtherCosts, setTotalOtherCosts] = useState<number[]>([]);
  const [years, setYears] = useState<Array<number | undefined>>([]);
  const [currentYear, setCurrentYear] = useState<number | 'all'>('all');
  // inizializza la tabella
  useEffect(() => {
    if (!otherCostsTableData || refresh) {
      const mappedOtherCosts = otherCosts?.map((el) => ({
        costType: el.costType,
        description: el.description,
        groupId: el.groupId,
        totalBillsRicevute: el.totalBillsRicevute,
        costAllocation: el.costAllocation
          ?.map((el) => ({
            id: el.id || -Math.floor(Math.random() * 100000),
            mese: el.mese,
            anno: el.anno,
            actualCost: el.actualCost,
            editEnabled: el.editEnabled,
          }))
          .sort((a, b) => {
            // Ordina per anno
            if (a.anno && b.anno && a.anno !== b.anno) {
              return a.anno - b.anno;
            }
            // Se gli anni sono uguali, ordina per mese
            return (a.mese ?? 0) - (b.mese ?? 0);
          }),
      }));
      setOtherCostsTableData(mappedOtherCosts);
      setOtherCostsTableDataShown(mappedOtherCosts);

      const yearsToSet: Array<number | undefined> = [];

      mappedOtherCosts?.[0]?.costAllocation?.forEach((alloc) => {
        if (!yearsToSet.includes(alloc?.anno)) {
          yearsToSet.push(alloc?.anno);
        }
      });
      setYears(yearsToSet);
    }
  }, [otherCosts]);

  useEffect(() => {
    calculateTotalOtherCosts();
    dispatch(saveCostsData(otherCostsTableData));
  }, [otherCostsTableData]);

  const calculateTotalOtherCosts = () => {
    const totalOtherCosts: number[] = [];
    otherCostsTableData?.forEach((allocation) => {
      let totalGU = 0;
      allocation.costAllocation?.forEach((alloc) => {
        if (alloc.editEnabled) {
          totalGU += alloc.actualCost ?? 0;
        }
      });
      totalOtherCosts.push(totalGU);
    });
    setTotalOtherCosts(totalOtherCosts);
  };

  const filterAllocationsByYear = (
    year: number | 'all',
    otherCostsData: ExternalCostDto[] | null | undefined = otherCostsTableData
  ): void => {
    setCurrentYear(year);
    if (year === 'all') {
      setOtherCostsTableDataShown(otherCostsData);
    } else {
      const filteredAllocations = otherCostsData?.map((el) => ({
        ...el,
        costAllocation: el.costAllocation?.filter((alloc) => alloc.anno === year),
      }));
      setOtherCostsTableDataShown(filteredAllocations);
    }
  };

  return {
    otherCostsTableData,
    setOtherCostsTableData,
    otherCostsTableDataShown,
    years,
    totalOtherCosts,
    filterAllocationsByYear,
    currentYear,
    setTotalOtherCosts,
  };
};

export default useGetOtherCostsTableData;

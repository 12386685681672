import { FatturaRicevutaSearchDto, SearchFattureRicevuteRequest } from '@api/client';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import {
  getFattureRicevute,
  saveFiltersPassiveAssociableInvoicesUsed,
} from 'pages/private/active-cycle/active-cycle-bills/_redux/actions';
import { filtersPassiveAssociableInvoicesUsedSelector } from 'pages/private/active-cycle/active-cycle-bills/_redux/selectors';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

const useFormAssociablePassiveInvoices = (
  initialData: any,
  setIsOpenInvoice: React.Dispatch<React.SetStateAction<boolean>>,
  dataFattura: Date
) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const filterUsed = useAppSelector(filtersPassiveAssociableInvoicesUsedSelector);
  const [chipsFormInvoicesData, setChipsFormInvoicesData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);
  const { handleSubmit, control, register, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  useEffect(() => {
    const data: any = filterUsed;
    const formDataArray = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          const isRangeModified = data[key].some((value: any, index: any) => value !== initialData[key][index]);
          if (isRangeModified) {
            formDataArray.push([key, data[key]]);
          }
        } else if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }
    setChipsFormInvoicesData([...formDataArray]);
  }, [dispatch, filterUsed, initialData]);

  const onSubmitInvoices = (data: any) => {
    isSubmitting.current = true;
    const formDataArray = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          const isRangeModified = data[key].some((value: any, index: any) => value !== initialData[key][index]);
          if (isRangeModified) {
            formDataArray.push([key, data[key]]);
          }
        } else if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormInvoicesData([...formDataArray]);

    const filterDto: FatturaRicevutaSearchDto = {};
    filterDto.excludefattureAssociate = true;
    // filterDto.dataFatturaDa = dataFattura;
    filterDto.paging = { size: 50, page: 0 };

    if (data.fkFatturaRicevuta) {
      filterDto.fkFatturaRicevuta = data.fkFatturaRicevuta;
    }
    if (data.startEndRange && data.startEndRange.length > 0) {
      filterDto.dataFatturaDa = new Date(data.startEndRange[0]);
      filterDto.dataFatturaA = new Date(data.startEndRange[1]);
    }
    if (data.supplier) {
      filterDto.fkFornitore = data.supplier;
    }
    if (data.legalEntity) {
      filterDto.fkAzienda = data.legalEntity;
    }
    if (data.frNumDocumento) {
      filterDto.frNumDocumento = data.frNumDocumento;
    }
    if (data.frDescrizioneCausale) {
      filterDto.frDescrizioneCausale = data.frDescrizioneCausale;
    }
    if (
      data.priceRange &&
      data.priceRange.length >= 0 &&
      (filterDto.importoMinimo !== null || filterDto.importoMassimo !== null)
    ) {
      filterDto.importoMinimo = data.priceRange[0];
      filterDto.importoMassimo = data.priceRange[1];
    }

    const payload: SearchFattureRicevuteRequest = {
      fatturaRicevutaSearchDto: filterDto,
    };

    dispatch(getFattureRicevute({ request: payload, type: 'associable' }))
      .unwrap()
      .then((res: any) => {});

    handleSubmit(data);
    dispatch(saveFiltersPassiveAssociableInvoicesUsed(data));
    setIsOpenInvoice(false);
  };

  const onResetInvoices = () => {
    reset(initialData);
    dispatch(saveFiltersPassiveAssociableInvoicesUsed(initialData));
    setChipsFormInvoicesData([]);
  };

  return {
    onSubmitInvoices,
    onResetInvoices,
    filterParams,
    chipsFormInvoicesData,
    setChipsFormInvoicesData,
    resetInvoice: reset,
    formState,
  };
};

export default useFormAssociablePassiveInvoices;

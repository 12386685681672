import { Session } from 'enums/project.enum';
import {
  editCdgProjects,
  editClProjects,
  editPlProjects,
  getCdgFilters,
  getCdgOtherCosts,
  getCdgProjects,
  getClFilters,
  getClOtherCosts,
  getClProjects,
  getPlFilters,
  getPlOtherCosts,
  getPlProjects,
  saveCdgFiltersUsed,
  saveCdgOtherCosts,
  saveClFiltersUsed,
  saveClOtherCosts,
  savePlFiltersUsed,
} from './../_redux/actions';
import {
  cdgProjectsSelector,
  clProjectsSelector,
  filtersCdgUsedSelector,
  filtersClUsedSelector,
  filtersPlUsedSelector,
  filtriCdgSelector,
  filtriClSelector,
  filtriPlSelector,
  plProjectsSelector,
} from '../_redux/selectors';
import {
  CdgProjectOtherCostsUpdateRequest,
  ClProjectOtherCostsUpdateRequest,
  FilterProjectMonthDto,
  ProjectMonthEditRequest,
  ProjectMonthSearchRequest,
} from '@api/client';
import { TrackPagesGA4Enum } from 'enums/global.enum';

const useGetSessionActions = (session: Session) => {
  const projectsSelector = () => {
    switch (session) {
      case Session.CDG:
        return cdgProjectsSelector;
      case Session.CL:
        return clProjectsSelector;
      case Session.PL:
        return plProjectsSelector;
      default:
        return cdgProjectsSelector;
    }
  };

  const filtersSelector = () => {
    switch (session) {
      case Session.CDG:
        return filtriCdgSelector;
      case Session.CL:
        return filtriClSelector;
      case Session.PL:
        return filtriPlSelector;
      default:
        return filtriCdgSelector;
    }
  };

  const filtersUsedSelector = () => {
    switch (session) {
      case Session.CDG:
        return filtersCdgUsedSelector;
      case Session.CL:
        return filtersClUsedSelector;
      case Session.PL:
        return filtersPlUsedSelector;
      default:
        return filtersCdgUsedSelector;
    }
  };

  const getFiltersAction = () => {
    switch (session) {
      case Session.CDG:
        return getCdgFilters();
      case Session.CL:
        return getClFilters();
      case Session.PL:
        return getPlFilters();
      default:
        return getCdgFilters();
    }
  };

  const getProjectsAction = (payload: ProjectMonthSearchRequest) => {
    switch (session) {
      case Session.CDG:
        return getCdgProjects(payload);
      case Session.CL:
        return getClProjects(payload);
      case Session.PL:
        return getPlProjects(payload);
      default:
        return getCdgProjects(payload);
    }
  };

  const saveFiltersUsedAction = (filters: FilterProjectMonthDto) => {
    switch (session) {
      case Session.CDG:
        return saveCdgFiltersUsed(filters);
      case Session.CL:
        return saveClFiltersUsed(filters);
      case Session.PL:
        return savePlFiltersUsed(filters);
      default:
        return saveCdgFiltersUsed(filters);
    }
  };

  const editProjectsAction = (payload: ProjectMonthEditRequest) => {
    switch (session) {
      case Session.CDG:
        return editCdgProjects(payload);
      case Session.CL:
        return editClProjects(payload);
      case Session.PL:
        return editPlProjects(payload);
      default:
        return editCdgProjects(payload);
    }
  };

  const getOtherCostsAction = (id: number) => {
    switch (session) {
      case Session.CDG:
        return getCdgOtherCosts({ idProject: id });
      case Session.CL:
        return getClOtherCosts({ idProject: id });
      case Session.PL:
        return getPlOtherCosts({ idProject: id });
      default:
        return getCdgOtherCosts({ idProject: id });
    }
  };

  const saveOtherCostsAction = (payload: CdgProjectOtherCostsUpdateRequest | ClProjectOtherCostsUpdateRequest) => {
    switch (session) {
      case Session.CDG:
        return saveCdgOtherCosts(payload);
      case Session.CL:
        return saveClOtherCosts(payload);
      default:
        return saveCdgOtherCosts(payload);
    }
  };

  const getSessionTracker = () => {
    switch (session) {
      case Session.CDG:
        return TrackPagesGA4Enum.CDG;
      case Session.CL:
        return TrackPagesGA4Enum.CL;
      case Session.PL:
        return TrackPagesGA4Enum.PL;
      default:
        return TrackPagesGA4Enum.CDG;
    }
  };

  return {
    projectsSelector,
    filtersSelector,
    filtersUsedSelector,
    getFiltersAction,
    getProjectsAction,
    saveFiltersUsedAction,
    editProjectsAction,
    getOtherCostsAction,
    saveOtherCostsAction,
    getSessionTracker,
  };
};

export default useGetSessionActions;

import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DashboardProjectsPeriod, DashboardTabs } from 'enums/project.enum';
import ProjectTable from './project-table';
import PeopleTable from './people-table';
import BookingTable from './booking-table';
import { DashboardUserTables } from 'enums/dashboard.enum';

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();

  const dashboardTabs: TabsProps['items'] = [
    {
      key: DashboardTabs.PROJECTS_TABLE_CURRENT_YEAR,
      label: t('dashboard.tabs.current-year-projects'),
      children: (
        <ProjectTable period={DashboardProjectsPeriod.CURRENT_YEAR} key={DashboardProjectsPeriod.CURRENT_YEAR} />
      ),
    },
    {
      key: DashboardTabs.PROJECTS_TABLE,
      label: t('dashboard.tabs.projects'),
      children: <ProjectTable period={DashboardProjectsPeriod.ALL} key={DashboardProjectsPeriod.ALL} />,
    },
    {
      key: DashboardTabs.PEOPLE_TABLE,
      label: t('dashboard.tabs.resource-planning'),
      children: <PeopleTable />,
    },
    {
      key: DashboardTabs.UNDERBOOKING_TABLE,
      label: t('dashboard.tabs.underbooking'),
      children: <BookingTable table={DashboardUserTables.UNDERBOOKING} key={DashboardUserTables.UNDERBOOKING} />,
    },
    {
      key: DashboardTabs.OVERBOOKING_TABLE,
      label: t('dashboard.tabs.overbooking'),
      children: <BookingTable table={DashboardUserTables.OVERBOOKING} key={DashboardUserTables.OVERBOOKING} />,
    },
  ];

  return (
    <>
      <h4 className="text-gray-800 ">{t('common.dashboard')}</h4>
      <Tabs defaultActiveKey={DashboardTabs.PROJECTS_TABLE_CURRENT_YEAR} items={dashboardTabs} />
    </>
  );
};
export default DashboardPage;

import noData from 'shared/design-system/assets/svg/no-data.svg';

interface NoDataProps {
  svg?: string;
  msg?: string;
}

const NoData: React.FC<NoDataProps> = ({ svg = noData, msg }) => {
  return (
    <div className="mx-auto d-flex justify-content-center align-items-center" style={{ height: '8rem' }}>
      <div className="mx-auto">
        <img src={svg} alt="no data" width={'124em'} />
        <p className="text-center mt-2">{msg}</p>
      </div>
    </div>
  );
};

export default NoData;

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';
import {
  Configuration,
  DashboardBookingListDto,
  DashboardControllerApi,
  DashboardProjectFilterDto,
  DashboardProjectFilterInputDto,
  DashboardProjectListDto,
  DashboardUserListDto,
  GetProjectListRequest,
  GetUnderBookingRequest,
  GetUserListRequest,
  UserListFilterDto,
} from '@api/client';

enum DashboardActions {
  getDashboardProjects = '[Dashboard] Get Dashboard Projects',
  getDashboardProjectsCurrentYear = '[Dashboard] Get Dashboard Projects Current Year',
  getDashboardUsers = '[Dashboard] Get Dashboard Users',
  getDashboardProjectFilters = '[Dashboard] Get Dashboard Project Filters',
  getUnderbookingUsers = '[Dashboard] Get Underbooking Users',
  getOverbookingUsers = '[Dashboard] Get Overbooking Users',
  saveDashboardProjectFilters = '[Dashboard] Save Dashboard Project Filters',
  saveDashboardPeopleFilters = '[Dashboard] Save Dashboard People Filters',
}

export const getDashboardProjects = createAsyncThunk<
  DashboardProjectListDto | undefined,
  GetProjectListRequest,
  ThunkApiConfig
>(DashboardActions.getDashboardProjects, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new DashboardControllerApi(getConfiguration(Configuration, extra, getState)).getProjectList(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getDashboardProjectsCurrentYear = createAsyncThunk<
  DashboardProjectListDto | undefined,
  GetProjectListRequest,
  ThunkApiConfig
>(DashboardActions.getDashboardProjectsCurrentYear, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new DashboardControllerApi(getConfiguration(Configuration, extra, getState)).getProjectCurrentYearList(
      payload
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getDashboardUsers = createAsyncThunk<DashboardUserListDto | undefined, GetUserListRequest, ThunkApiConfig>(
  DashboardActions.getDashboardUsers,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new DashboardControllerApi(getConfiguration(Configuration, extra, getState)).getUserList(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getDashboardProjectFilters = createAsyncThunk<DashboardProjectFilterDto | undefined, void, ThunkApiConfig>(
  DashboardActions.getDashboardProjectFilters,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new DashboardControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).getDashboardProjectFilter();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getUnderbookingUsers = createAsyncThunk<
  DashboardBookingListDto | undefined,
  GetUnderBookingRequest,
  ThunkApiConfig
>(DashboardActions.getUnderbookingUsers, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new DashboardControllerApi(getConfiguration(Configuration, extra, getState)).getUnderBooking(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getOverbookingUsers = createAsyncThunk<
  DashboardBookingListDto | undefined,
  GetUnderBookingRequest,
  ThunkApiConfig
>(DashboardActions.getOverbookingUsers, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new DashboardControllerApi(getConfiguration(Configuration, extra, getState)).getOverBooking(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const saveDashboardProjectFilters = createAction<DashboardProjectFilterInputDto>(
  DashboardActions.saveDashboardProjectFilters
);

export const saveDashboardPeopleFilters = createAction<UserListFilterDto>(DashboardActions.saveDashboardPeopleFilters);

import { ExternalCostDto } from '@api/client';
import { useAppSelector } from 'core/store/hooks';
import { associatedContractsSelector } from '../../_redux/selectors';
import { useEffect } from 'react';

const useValidationAssociatedContracts = ({
  otherCostsTableData,
  setAssociatedCostsValidation,
}: {
  otherCostsTableData: ExternalCostDto[] | null | undefined;
  setAssociatedCostsValidation: (value: boolean) => void;
}) => {
  const associatedContracts = useAppSelector(associatedContractsSelector);

  useEffect(() => {
    if (otherCostsTableData && associatedContracts) {
      let validation = true;
      otherCostsTableData.forEach((cost) => {
        if (
          !!associatedContracts?.find(
            (pcCost) => pcCost.costTypeId === cost.costType?.id && pcCost.groupId === cost.groupId
          )
        ) {
          const associatedAmount =
            associatedContracts
              ?.find((pcCost) => pcCost.costTypeId === cost.costType?.id && pcCost.groupId === cost.groupId)
              ?.contracts?.reduce((acc, el) => acc + (el.amount ?? 0), 0) ?? 0;
          const totalCost =
            cost.costAllocation
              ?.filter((alloc) => alloc.editEnabled)
              ?.reduce((acc, el) => acc + (el.actualCost ?? 0), 0) ?? 0;
          if (totalCost > associatedAmount) {
            validation = false;
          }
        }
      });
      setAssociatedCostsValidation(validation);
    }
  }, [otherCostsTableData, associatedContracts]);
};

export default useValidationAssociatedContracts;

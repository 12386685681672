import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { projectAssetsSelector } from '../_redux/selectors';
import { useEffect, useState } from 'react';
import { getProjectAssetById } from '../_redux/actions';

const useGetProjectAssets = (projectId: number | undefined) => {
  const dispatch = useAppDispatch();
  const projectAssetsFromRedux = useAppSelector(projectAssetsSelector);
  const [projectAssets, setProjectAssets] = useState(projectAssetsFromRedux);

  useEffect(() => {
    if (!projectAssets.length) getProjectAssets();
  }, []);

  useEffect(() => {
    setProjectAssets(projectAssetsFromRedux);
  }, [projectAssetsFromRedux]);

  const getProjectAssets = () => {
    if (!projectId) return;
    dispatch(getProjectAssetById(projectId));
  };

  return { projectAssets, setProjectAssets, getProjectAssets };
};

export default useGetProjectAssets;

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the NoteDto interface.
 */
export function instanceOfNoteDto(value) {
    return true;
}
export function NoteDtoFromJSON(json) {
    return NoteDtoFromJSONTyped(json, false);
}
export function NoteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idProject': json['idProject'] == null ? undefined : json['idProject'],
        'categoriaNoteId': json['categoriaNoteId'] == null ? undefined : json['categoriaNoteId'],
        'idNote': json['idNote'] == null ? undefined : json['idNote'],
        'text': json['text'] == null ? undefined : json['text'],
        'dataUltimaModifica': json['dataUltimaModifica'] == null ? undefined : (new Date(json['dataUltimaModifica'])),
        'author': json['author'] == null ? undefined : json['author'],
        'edited': json['edited'] == null ? undefined : json['edited'],
    };
}
export function NoteDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idProject': value['idProject'],
        'categoriaNoteId': value['categoriaNoteId'],
        'idNote': value['idNote'],
        'text': value['text'],
        'dataUltimaModifica': value['dataUltimaModifica'] == null ? undefined : ((value['dataUltimaModifica']).toISOString()),
        'author': value['author'],
        'edited': value['edited'],
    };
}

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

const managementToolsReducer = (state: RootState) => state.private.managementTools;

// CDG
export const filtriCdgSelector = createSelector(
  managementToolsReducer,
  (managementTools) => managementTools.filtersCdg
);
export const cdgProjectsSelector = createSelector(
  managementToolsReducer,
  (managementTools) => managementTools.cdgProjects
);
export const filtersCdgUsedSelector = createSelector(
  managementToolsReducer,
  (managementTools) => managementTools.filtersCdgUsed
);

// CL
export const filtriClSelector = createSelector(managementToolsReducer, (managementTools) => managementTools.filtersCl);
export const clProjectsSelector = createSelector(
  managementToolsReducer,
  (managementTools) => managementTools.clProjects
);
export const filtersClUsedSelector = createSelector(
  managementToolsReducer,
  (managementTools) => managementTools.filtersClUsed
);

// PL
export const filtriPlSelector = createSelector(managementToolsReducer, (managementTools) => managementTools.filtersPl);
export const plProjectsSelector = createSelector(
  managementToolsReducer,
  (managementTools) => managementTools.plProjects
);
export const filtersPlUsedSelector = createSelector(
  managementToolsReducer,
  (managementTools) => managementTools.filtersPlUsed
);

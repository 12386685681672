import dayjs from 'dayjs';
import { chipsData } from 'enums/chipsData';
import { t } from 'i18next';
import { DashboardProjectFilterDto, FilterProjectMonthValuesDto, UserDto } from '@api/client';

interface Props {
  filtriMT: FilterProjectMonthValuesDto | undefined | null;
  allUsers: UserDto[] | null;
  filtriProjectDashboard: DashboardProjectFilterDto | null | undefined;
}

const useRenderChipContent = ({ filtriMT, allUsers, filtriProjectDashboard }: Props) => {
  const renderMultiSelectContent = (options: string[], label: string) => {
    if (options.length > 0) {
      return `${options.length} ${t(label)}`;
    }
    return '';
  };

  const renderMesiAnniContent = (monthYearArray: { mese: number; anno: number }[], fullDescription?: boolean) => {
    if (monthYearArray.length === 0) {
      return '';
    }

    const first = monthYearArray[0];
    const last = monthYearArray[monthYearArray.length - 1];

    const formatMonth = (month: number) => month.toString().padStart(2, '0');

    const start = `${formatMonth(first.mese)}/${first.anno}`;
    const end = `${formatMonth(last.mese)}/${last.anno}`;

    return `${fullDescription ? 'Period - ' : ''}${start} -> ${end}`;
  };

  const renderUnitContent = (unitName: string, value: number) => {
    let unit;
    switch (unitName) {
      case chipsData.businessUnit.key:
        unit = filtriMT?.businessUnit;
        break;
      case chipsData.deliveryUnit.key:
        unit = filtriMT?.deliveryUnit;
        break;
      default:
        break;
    }
    return (
      unit
        ?.filter((el: { id?: number }) => value === el.id)
        ?.map((el: { name?: string }) => el.name)
        .join(', ') || ''
    );
  };

  const renderPersonContent = (personField: string, personId: number) => {
    return `${personField} - ${allUsers?.find((el) => personId === el.id)?.nominativo || personId}
    `;
  };

  const renderPriceRangeContent = (priceRange: number[]) => {
    const minPrice = priceRange[0];
    const maxPrice = priceRange[1];
    let description = 'Amount: ';
    if (minPrice && maxPrice) {
      description += `from ${minPrice}€ to ${maxPrice}€`;
    } else if (minPrice) {
      description += `from ${minPrice}€ to >`;
    } else if (maxPrice) {
      description += `< to ${maxPrice}€`;
    } else {
      return '';
    }
    return description;
  };

  const renderDateRangeContent = (dateRange: Date[], label: string) => {
    if (dateRange.length === 0) return '';
    const startDate = dayjs(dateRange[0]).format('DD/MM/YYYY');
    const endDate = dayjs(dateRange[1]).format('DD/MM/YYYY');
    return `${t(label)}: ${startDate} - ${endDate}`;
  };

  const renderDateContent = (date: Date, label: string) => {
    if (!date) return '';
    return `${t(label)}: ${dayjs(date).format('DD/MM/YYYY')}`;
  };

  const renderCustomerContent = (customerId: string) => {
    return `${chipsData.idCliente.name} - ${
      filtriProjectDashboard?.clientIds?.find((el) => el.id === customerId)?.denominazione
    }`;
  };

  return {
    renderMultiSelectContent,
    renderMesiAnniContent,
    renderUnitContent,
    renderPersonContent,
    renderPriceRangeContent,
    renderDateRangeContent,
    renderDateContent,
    renderCustomerContent,
  };
};

export default useRenderChipContent;

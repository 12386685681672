import { Modal } from 'react-bootstrap';
import { useAppDispatch } from 'core/store/hooks';
import { authLogout } from '../auth/_redux/actions';
import { useTranslation } from 'react-i18next';

interface ModalSessionExpiredProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const ModalSessionExpired: React.FC<ModalSessionExpiredProps> = ({ showModal, setShowModal }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    setShowModal(false);
    dispatch(authLogout(true));
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{t('expiration-modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('expiration-modal.message')}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-danger" onClick={handleClose}>
          {t('expiration-modal.confirm')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSessionExpired;

import { useTranslation } from 'react-i18next';
import LinksLogo from 'shared/design-system/assets/svg/links-blue.png';
import LinksLogoPittogramma from 'shared/design-system/assets/svg/links-log.png';
interface SidebarHeaderProps {
  sidebarToggled: boolean;
  toggleSidebar: () => void;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ sidebarToggled, toggleSidebar }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="cursor-pointer link d-flex">
        <div className="sidebar-brand">
          <img src={sidebarToggled ? LinksLogoPittogramma : LinksLogo} className=" logo-pittogramma " alt="Logo" />
        </div>
        {!sidebarToggled && (
          <button className="btn-icon ms-auto mt-3 me-2" onClick={toggleSidebar}>
            <i className="fa-solid fa-outdent text-blue-links"></i>
          </button>
        )}
      </div>
      {sidebarToggled && (
        <button className="btn-icon" onClick={toggleSidebar}>
          <i className="fa-solid fa-indent text-blue-links"></i>
        </button>
      )}
      {sidebarToggled && <hr className="sidebar-hr" />}
      {!sidebarToggled && (
        <span className="notification-header ms-4" style={{ color: 'gray' }}>
          {t('common.management-tools')}
        </span>
      )}
    </>
  );
};

export default SidebarHeader;

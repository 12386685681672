import {
  BillingDataResult,
  Configuration,
  ControllerProjectApi,
  ModificaStatoAvanzamentoRequest,
  StatoAvanzamentoFieldsDto,
  StatoAvanzamentoLavoriControllerApi,
} from '@api/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';

enum BillingPlanAction {
  getBillingPlan = '[BillingPlan] Get Billing Plan',
  getBillingFields = '[BillingPlan] Get Billing Fields',
  updateStatoAvanzamentoBilling = '[BillingPlan] Update Stato Avanzamento Billing',
  resetBillingPlan = '[BillingPlan] Reset Billing Plan',
}

export const getBillingPlan = createAsyncThunk<BillingDataResult | undefined, number, ThunkApiConfig>(
  BillingPlanAction.getBillingPlan,
  async (id, { dispatch, extra, getState }) => {
    try {
      return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).projectBilling({
        projectId: id,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getBillingFields = createAsyncThunk<StatoAvanzamentoFieldsDto | undefined, void, ThunkApiConfig>(
  BillingPlanAction.getBillingFields,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new StatoAvanzamentoLavoriControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).getStatoAvanzamentoFields();
    } catch (e) {
      errorHandler(e, dispatch, getState);
      return undefined;
    }
  }
);

export const updateStatoAvanzamentoBilling = createAsyncThunk<
  string | undefined,
  ModificaStatoAvanzamentoRequest,
  ThunkApiConfig
>(BillingPlanAction.updateStatoAvanzamentoBilling, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new StatoAvanzamentoLavoriControllerApi(
      getConfiguration(Configuration, extra, getState)
    ).modificaStatoAvanzamento(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
    return undefined;
  }
});

export const resetBillingPlan = createAction(BillingPlanAction.resetBillingPlan);

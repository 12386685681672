import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Input, Select } from 'antd';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { StatusBadgeData } from 'enums/status';
import LinksButton from 'shared/design-system/components/links-button';
import { filtersDashboardProjectSelector, filtersDashboardProjectUsedSelector } from '../_redux/selectors';
import { saveDashboardProjectFilters } from '../_redux/actions';
import { filterOption } from 'shared/utils/common.utils';
import TagMultipleSelect from 'shared/design-system/components/app-tag-multiple-select';

export const filtersDashboardProjectsData: any = {
  id: '',
  nome: '',
  projectStatus: [],
  businessUnit: null,
  idCliente: null,
  plUserId: null,
};

interface Props {
  onSubmit: any;
  handleReset: () => void;
}
const FiltersDashboardProjects = ({ onSubmit, handleReset }: Props) => {
  const filterUsed = useAppSelector(filtersDashboardProjectUsedSelector);
  const dispatch = useAppDispatch();
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: filtersDashboardProjectsData,
    values: filterUsed,
  });
  const filtri = useAppSelector(filtersDashboardProjectSelector);

  const Reset = () => {
    reset();
    handleReset();
  };

  const handleFormSubmit = async (formData: any) => {
    dispatch(saveDashboardProjectFilters(formData));
    try {
      await onSubmit(formData);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Col sm="3">
          <Form.Label>{t('dashboard.projects.filters.id-project')}</Form.Label>
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                placeholder={t('project-list.id-project-placeholder')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('dashboard.projects.filters.project-name')}</Form.Label>
          <Controller
            name="nome"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('project-list.search-name-project')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          {' '}
          <Form.Label>{t('dashboard.projects.filters.states')}</Form.Label>
          <Controller
            name="projectStatus"
            control={control}
            render={({ field }) => (
              <Select
                placeholder={t('project-list.select-status-placeholder')}
                mode="multiple"
                size="large"
                allowClear
                tagRender={TagMultipleSelect}
                defaultValue={[]}
                style={{ width: '100%' }}
                options={filtri?.statoEnum?.map((el: any, index: any) => ({
                  value: el,
                  label: StatusBadgeData?.find((state) => state.name === el)?.filterLabel,
                }))}
                {...field}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('dashboard.projects.filters.business-unit')}</Form.Label>
          <Controller
            name="businessUnit"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                allowClear
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('cdg.business-unit-placeholder')}
              >
                {filtri?.businessUnit?.map((el, index) => (
                  <Select.Option key={index} value={el.name}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="3">
          <Form.Label>{t('dashboard.projects.filters.account')}</Form.Label>
          <Controller
            name="idCliente"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('dashboard.projects.filters.search-account-placeholder')}
              >
                {filtri?.clientIds?.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.denominazione}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('dashboard.projects.filters.project-lead')}</Form.Label>
          <Controller
            name="plUserId"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('dashboard.projects.filters.search-pl-placeholder')}
              >
                {filtri?.projectLeads?.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.nominativo}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
      </Row>

      <div className="d-flex mt-3">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};
export default FiltersDashboardProjects;

import { combineReducers } from '@reduxjs/toolkit';
import { projectListReducer } from '../project-list/_redux/reducers';
import { projectDetailReducer } from '../project-list/project-detail/_redux/reducers';
import { crudReducer } from '../crud/_redux/reducers';
import { projectMessagesReducer } from 'shared/design-system/components/notes/_redux/reducers';
import { managementToolsReducer } from '../management-tools/_redux/reducers';
import { dashboardReducer } from '../dashboard/_redux/reducers';
import { passiveCycleReducer } from '../passive-cycle/_redux/reducers';
import { billingPlanReducer } from '../project-list/project-detail/_components/billing-plan/_redux/reducers';
import { fattureReducer } from '../active-cycle/active-cycle-bills/_redux/reducers';
import { activeCycleReducer } from '../active-cycle/active-cycle-order-list/_redux/reducers';
import { productsAndSubscriptionsReducer } from '../project-list/project-detail/_components/products-and-subscriptions/_redux/reducers';
import { clProjectsReducer } from '../cl-projects/_redux/reducers';

export const privateReducer = combineReducers({
  dashboard: dashboardReducer,
  progetti: projectListReducer,
  dettaglioProgetto: projectDetailReducer,
  billingPlan: billingPlanReducer,
  productsAndSubscriptions: productsAndSubscriptionsReducer,
  crud: crudReducer,
  projectMessages: projectMessagesReducer,
  managementTools: managementToolsReducer,
  passiveCycle: passiveCycleReducer,
  fatture: fattureReducer,
  activeCycle: activeCycleReducer,
  clProjects: clProjectsReducer,
});

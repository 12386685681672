import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { DashboardProjectsPeriod } from 'enums/project.enum';
import { dashboardProjectsCurrentYearSelector, dashboardProjectsSelector } from '../../_redux/selectors';
import { StatusBadgeData } from 'enums/status';
import { DashboardProjectDto, FilterDtoStatoEnum } from '@api/client';
import { trackUserIdGa4 } from 'shared/utils/gtag';
import { TrackPagesGA4Enum } from 'enums/global.enum';
import { getDashboardProjects, getDashboardProjectsCurrentYear } from '../../_redux/actions';
import { useTranslation } from 'react-i18next';
import { DashboardFieldsColor } from 'enums/dashboard.enum';

const useGetDashboardProjects = (period: DashboardProjectsPeriod) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [projects, setProjects] = useState<DashboardProjectDto[] | any[]>([]);

  const projectsSelector = useAppSelector(
    period === DashboardProjectsPeriod.ALL ? dashboardProjectsSelector : dashboardProjectsCurrentYearSelector
  );

  useEffect(() => {
    trackUserIdGa4(TrackPagesGA4Enum.DASHBOARD_PROJECTS);
    if (projectsSelector?.length === 0) {
      const projectStatus = StatusBadgeData.map((el) => el.name).filter((el) => el !== FilterDtoStatoEnum.Completed);
      const getProjectAction =
        period === DashboardProjectsPeriod.ALL ? getDashboardProjects : getDashboardProjectsCurrentYear;
      dispatch(getProjectAction({ dashboardProjectFilterInputDto: { size: 99999, projectStatus } }))
        .unwrap()
        .then((res) => {
          setProjects(
            res?.projects?.map((project) => ({
              ...project,
              intranetId: Number(project.intranetId),
              stato: StatusBadgeData.find((x) => x.name === project.stato)?.label,
            })) || []
          );
        });
    }
  }, []);

  useEffect(() => {
    if (projectsSelector) {
      const projectsMapped = projectsSelector.map((project) => ({
        ...project,
        intranetId: Number(project.intranetId),
        stato: StatusBadgeData.find((x) => x.name === project.stato)?.label,
      }));
      setProjects(projectsMapped);
    }
  }, [projectsSelector]);

  const tableProps: any = {
    columns: [
      {
        title: t('dashboard.projects.table-titles.id-title'),
        key: 'intranetId',
        headerColor: '#F5F5F5',
        headerFontWeight: 'bold',
      },
      {
        title: t('dashboard.projects.table-titles.project-name-title'),
        key: 'name',
        headerColor: '#F5F5F5',
        headerFontWeight: 'bold',
      },
      {
        title: t('dashboard.projects.table-titles.status-title'),
        key: 'stato',
        headerColor: '#F5F5F5',
        headerFontWeight: 'bold',
      },
      {
        title: t('dashboard.projects.table-titles.no-plan-title'),
        key: 'prjNPRicavi',
        type: 'amount',
        headerColor: '#F5F5F5',
        headerFontWeight: 'bold',
      },
      {
        title: t('dashboard.projects.table-titles.actual-revenues-title'),
        key: 'actualRevenues',
        type: 'amount',
        color: '#c2d7df',
        headerColor: DashboardFieldsColor.REVENUES,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },

      {
        title: t('dashboard.projects.table-titles.actual-costs-title'),
        key: 'actualCosts',
        type: 'amount',
        color: '#dfb2b5',
        headerColor: DashboardFieldsColor.COSTS,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.actual-hr-costs-title'),
        key: 'actualHrCosts',
        type: 'amount',
        color: '#dfb2b5',
        headerColor: DashboardFieldsColor.COSTS,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.actual-other-costs-title'),
        key: 'actualOtherCosts',
        type: 'amount',
        color: '#dfb2b5',
        headerColor: DashboardFieldsColor.COSTS,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.actual-margin-title'),
        key: 'actualMargin',
        type: 'amount',
        color: '#bad7b7',
        headerColor: DashboardFieldsColor.MARGINS,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.actual-margin-perc-title'),
        key: 'actualMarginRate',
        type: 'perc',
        color: '#bad7b7',
        headerColor: DashboardFieldsColor.MARGINS,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.actual-rate-title'),
        key: 'actualRate',
        type: 'amount',
        color: '#e990ec',
        headerColor: DashboardFieldsColor.RATE,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.af-revenues-title'),
        key: 'afRevenues',
        type: 'amount',
        color: '#c2d7df',
        headerColor: DashboardFieldsColor.REVENUES,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.af-costs-title'),
        key: 'prjAFCosti',
        type: 'amount',
        color: '#dfb2b5',
        headerColor: DashboardFieldsColor.COSTS,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.af-margin-title'),
        key: 'prjAFMargin',
        type: 'amount',
        color: '#bad7b7',
        headerColor: DashboardFieldsColor.MARGINS,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
      {
        title: t('dashboard.projects.table-titles.af-margin-perc-title'),
        key: 'prjAFMarginPerc',
        type: 'perc',
        color: '#bad7b7',
        headerColor: DashboardFieldsColor.MARGINS,
        textHeaderColor: '#ffffff',
        headerFontWeight: '600',
      },
    ],
  };

  return { projects, setProjects, tableProps };
};

export default useGetDashboardProjects;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { DettagliProgettoDtoStatoEnum, DettaglioProjectDto } from '@api/client';
import { saveRevenueProgress } from '../../_redux/actions';
import { currentProjectSelector } from 'pages/private/project-list/_redux/selectors';
import { getProjectDetails } from 'pages/private/project-list/_redux/actions';

const useProjectDetails = () => {
  const dispatch = useAppDispatch();
  const projectDetailsSelector = useAppSelector(currentProjectSelector);
  const [projectDetails, setProjectDetails] = useState<DettaglioProjectDto | null>(projectDetailsSelector);
  const { id } = useParams<{ id: any }>();
  const isWorkInProgress =
    projectDetails?.dettaglioOfferta?.stato === DettagliProgettoDtoStatoEnum.WorkInProgress ||
    projectDetails?.dettaglioOfferta?.stato === DettagliProgettoDtoStatoEnum.ControlloCdg ||
    projectDetails?.dettaglioOfferta?.stato === DettagliProgettoDtoStatoEnum.ControlloCl ||
    projectDetails?.dettaglioOfferta?.stato === DettagliProgettoDtoStatoEnum.Completed ||
    projectDetails?.dettaglioOfferta?.stato === DettagliProgettoDtoStatoEnum.ReportingClosed ||
    projectDetails?.dettaglioOfferta?.stato === DettagliProgettoDtoStatoEnum.EconomicsClosed;

  const isPlanning =
    projectDetails?.dettaglioOfferta?.stato === DettagliProgettoDtoStatoEnum.Planning ||
    projectDetails?.dettaglioOfferta?.stato === DettagliProgettoDtoStatoEnum.ClosedWonPlanning;

  const getProjectDetail = () => {
    const projectId = parseInt(id, 10);
    dispatch(getProjectDetails(projectId));
  };

  useEffect(() => {
    if (!projectDetails && id) {
      getProjectDetail();
    }
  }, [dispatch, id, projectDetails]);

  useEffect(() => {
    if (projectDetailsSelector) {
      setProjectDetails(projectDetailsSelector);
      dispatch(saveRevenueProgress(projectDetailsSelector?.revenueList));
    }
  }, [projectDetailsSelector]);

  return { projectDetails, setProjectDetails, isPlanning, isWorkInProgress, getProjectDetail };
};

export default useProjectDetails;

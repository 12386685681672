import {
  ClProjectSearchRequest,
  Configuration,
  ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi,
  ListProjectCLDto,
} from '@api/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';

enum CLProjectsActions {
  getClProjectsWithoutOrders = '[CLProjects] Get CL Projects Without Orders',
}

export const getClProjectsWithoutOrders = createAsyncThunk<
  ListProjectCLDto | undefined,
  ClProjectSearchRequest,
  ThunkApiConfig
>(CLProjectsActions.getClProjectsWithoutOrders, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi(
      getConfiguration(Configuration, extra, getState)
    ).clProjectSearch(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

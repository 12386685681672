/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CostTypeDtoFromJSON, CostTypeDtoToJSON, } from './CostTypeDto';
import { ExternalCostAllocationDtoFromJSON, ExternalCostAllocationDtoToJSON, } from './ExternalCostAllocationDto';
/**
 * Check if a given object implements the ExternalCostDto interface.
 */
export function instanceOfExternalCostDto(value) {
    return true;
}
export function ExternalCostDtoFromJSON(json) {
    return ExternalCostDtoFromJSONTyped(json, false);
}
export function ExternalCostDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'description': json['description'] == null ? undefined : json['description'],
        'costType': json['costType'] == null ? undefined : CostTypeDtoFromJSON(json['costType']),
        'groupId': json['groupId'] == null ? undefined : json['groupId'],
        'costAllocation': json['costAllocation'] == null ? undefined : (json['costAllocation'].map(ExternalCostAllocationDtoFromJSON)),
        'isAssociated': json['isAssociated'] == null ? undefined : json['isAssociated'],
        'contractAmount': json['contractAmount'] == null ? undefined : json['contractAmount'],
        'totalBillsRicevute': json['totalBillsRicevute'] == null ? undefined : json['totalBillsRicevute'],
    };
}
export function ExternalCostDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'description': value['description'],
        'costType': CostTypeDtoToJSON(value['costType']),
        'groupId': value['groupId'],
        'costAllocation': value['costAllocation'] == null ? undefined : (value['costAllocation'].map(ExternalCostAllocationDtoToJSON)),
        'isAssociated': value['isAssociated'],
        'contractAmount': value['contractAmount'],
        'totalBillsRicevute': value['totalBillsRicevute'],
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PagingDtoFromJSON, PagingDtoToJSON, } from './PagingDto';
/**
 * Check if a given object implements the ContractOrderSFSearchInputDto interface.
 */
export function instanceOfContractOrderSFSearchInputDto(value) {
    return true;
}
export function ContractOrderSFSearchInputDtoFromJSON(json) {
    return ContractOrderSFSearchInputDtoFromJSONTyped(json, false);
}
export function ContractOrderSFSearchInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'object': json['object'] == null ? undefined : json['object'],
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'ordNumero': json['ordNumero'] == null ? undefined : json['ordNumero'],
        'ordOggetto': json['ordOggetto'] == null ? undefined : json['ordOggetto'],
        'ordCig': json['ordCig'] == null ? undefined : json['ordCig'],
        'ordCup': json['ordCup'] == null ? undefined : json['ordCup'],
        'ordSdi': json['ordSdi'] == null ? undefined : json['ordSdi'],
        'ordTermGG': json['ordTermGG'] == null ? undefined : json['ordTermGG'],
        'ordPoNumber': json['ordPoNumber'] == null ? undefined : json['ordPoNumber'],
        'dataPo': json['dataPo'] == null ? undefined : (new Date(json['dataPo'])),
        'denominazioneCliente': json['denominazioneCliente'] == null ? undefined : json['denominazioneCliente'],
        'businessManagerId': json['businessManagerId'] == null ? undefined : json['businessManagerId'],
        'aziendaId': json['aziendaId'] == null ? undefined : json['aziendaId'],
        'clienteId': json['clienteId'] == null ? undefined : json['clienteId'],
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'paging': json['paging'] == null ? undefined : PagingDtoFromJSON(json['paging']),
        'stato': json['stato'] == null ? undefined : json['stato'],
        'sortedDate': json['sortedDate'] == null ? undefined : json['sortedDate'],
    };
}
export function ContractOrderSFSearchInputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'object': value['object'],
        'orderId': value['orderId'],
        'ordNumero': value['ordNumero'],
        'ordOggetto': value['ordOggetto'],
        'ordCig': value['ordCig'],
        'ordCup': value['ordCup'],
        'ordSdi': value['ordSdi'],
        'ordTermGG': value['ordTermGG'],
        'ordPoNumber': value['ordPoNumber'],
        'dataPo': value['dataPo'] == null ? undefined : ((value['dataPo']).toISOString()),
        'denominazioneCliente': value['denominazioneCliente'],
        'businessManagerId': value['businessManagerId'],
        'aziendaId': value['aziendaId'],
        'clienteId': value['clienteId'],
        'projectId': value['projectId'],
        'paging': PagingDtoToJSON(value['paging']),
        'stato': value['stato'],
        'sortedDate': value['sortedDate'],
    };
}

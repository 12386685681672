import { BillingDto } from '@api/client';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { getBillingPlan } from '../_redux/actions';
import { billingDataSelector, isBillEditEnabledSelector } from '../_redux/selectors';

const useGetBillingData = (projectId: number) => {
  const dispatch = useAppDispatch();
  const billingSelector = useAppSelector(billingDataSelector);
  const isEditEnabled = useAppSelector(isBillEditEnabledSelector);
  const [billingPlan, setBillingPlan] = useState<BillingDto[] | undefined>(undefined);

  useEffect(() => {
    if (!billingSelector) {
      dispatch(getBillingPlan(projectId));
    }
  }, []);

  useEffect(() => {
    if (billingSelector) {
      setBillingPlan(
        billingSelector.map((item) => ({
          ...item,
          statoAvanzamentoLavori: {
            ...item.statoAvanzamentoLavori,
            idTipologiaBlocco: {
              ...item.statoAvanzamentoLavori?.idTipologiaBlocco,
              value: item.statoAvanzamentoLavori?.idTipologiaBlocco?.value || 'LAVORAZIONI_IN_CORSO',
            },
          },
        }))
      );
    }
  }, [billingSelector]);

  return { billingPlan, setBillingPlan, isEditEnabled };
};

export default useGetBillingData;

import {
  ActiveOrderProject,
  AssociateOrderWithProjectRequest,
  CicloAttivoControllerApi,
  CicloPassivoFieldsDto,
  Configuration,
  ContractOrderOutputListDto,
  ContractOrderSFSearchInputDto,
  FatturaEmessaPagedDto,
  FatturaEmessaSearchDto,
  FattureProgettoControllerApi,
  GetProjects1Request,
  ProjectCicloPassivoOutputListDto,
  ProjectLightInfoDto,
  SearchContractOrders2Request,
  SearchContractOrdersSFRequest,
  SearchFattureEmesse1Request,
} from '@api/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';

enum ActiveCycleActions {
  getActiveCycleOptions = '[ActiveCycle] Get Active Cycle Options',
  searchActiveOrdersSf = '[ActiveCycle] Search Active Orders SF',
  searchActiveOrders = '[ActiveCycle] Search Active Orders',
  getActiveCycleFattureEmesse = '[ActiveCycle] Get Active Cycle Fatture Emissione',
  associateOrderProject = '[ActiveCycle] Associate Order Project',
  getProjectsForActiveCycle = '[ActiveCycle] Get Projects For Active Cycle',
  getAssociableProjects = '[ActiveCycle] Get Associable Projects',
  deleteOrderProjectAssociation = '[ActiveCycle] Delete Order Project Association',
  disassociateInvoice = '[ActiveCycle] Disassociate Invoice',
  saveFiltersOrdersSfUsed = '[ActiveCycle] Save Filters Orders SF Used',
  saveFiltersAssociableInvoicesUsed = '[ActiveCycle] Save Filters Associable Invoices Used',
}

export const getActiveCycleOptions = createAsyncThunk<CicloPassivoFieldsDto | undefined, void, ThunkApiConfig>(
  ActiveCycleActions.getActiveCycleOptions,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new CicloAttivoControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).getCicloAttivoFields();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const searchActiveOrdersSf = createAsyncThunk<
  ContractOrderOutputListDto | undefined,
  SearchContractOrdersSFRequest,
  ThunkApiConfig
>(ActiveCycleActions.searchActiveOrdersSf, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new CicloAttivoControllerApi(getConfiguration(Configuration, extra, getState)).searchContractOrdersSF(
      payload
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const searchActiveOrders = createAsyncThunk<
  ContractOrderOutputListDto | undefined,
  SearchContractOrders2Request,
  ThunkApiConfig
>(ActiveCycleActions.searchActiveOrders, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new CicloAttivoControllerApi(getConfiguration(Configuration, extra, getState)).searchContractOrders2(
      payload
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getActiveCycleFattureEmesse = createAsyncThunk<
  FatturaEmessaPagedDto | undefined,
  { request: SearchFattureEmesse1Request; type: 'associated' | 'associable' },
  ThunkApiConfig
>(ActiveCycleActions.getActiveCycleFattureEmesse, async ({ request }, { dispatch, extra, getState }) => {
  try {
    return await new CicloAttivoControllerApi(getConfiguration(Configuration, extra, getState)).searchFattureEmesse1(
      request
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const associateOrderProject = createAsyncThunk<
  ActiveOrderProject | undefined,
  AssociateOrderWithProjectRequest,
  ThunkApiConfig
>(ActiveCycleActions.associateOrderProject, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new CicloAttivoControllerApi(
      getConfiguration(Configuration, extra, getState)
    ).associateOrderWithProject(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getProjectsForActiveCycle = createAsyncThunk<ProjectLightInfoDto[] | undefined, void, ThunkApiConfig>(
  ActiveCycleActions.getProjectsForActiveCycle,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new CicloAttivoControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).getProjectLightInfoFattureAttive1();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getAssociableProjects = createAsyncThunk<
  ProjectCicloPassivoOutputListDto | undefined,
  GetProjects1Request,
  ThunkApiConfig
>(ActiveCycleActions.getAssociableProjects, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new CicloAttivoControllerApi(getConfiguration(Configuration, extra, getState)).getProjects1(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const deleteOrderProjectAssociation = createAsyncThunk<
  void,
  { orderId: number; projectId: number },
  ThunkApiConfig
>(ActiveCycleActions.deleteOrderProjectAssociation, async (payload, { dispatch, extra, getState }) => {
  try {
    await new CicloAttivoControllerApi(
      getConfiguration(Configuration, extra, getState)
    ).deleteOrderProjectAssociationRaw(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const disassociateInvoice = createAsyncThunk<void, number, ThunkApiConfig>(
  ActiveCycleActions.disassociateInvoice,
  async (id, { dispatch, extra, getState }) => {
    try {
      await new FattureProgettoControllerApi(getConfiguration(Configuration, extra, getState)).deleteFatturaProgetto({
        id,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const saveFiltersOrdersSfUsed = createAction<ContractOrderSFSearchInputDto>(
  ActiveCycleActions.saveFiltersOrdersSfUsed
);

export const saveFiltersActiveAssociableInvoicesUsed = createAction<FatturaEmessaSearchDto>(
  ActiveCycleActions.saveFiltersAssociableInvoicesUsed
);

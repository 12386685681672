import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import getConfiguration from 'core/store/config';
import { ThunkApiConfig } from 'core/store';
import {
  AuthenticateRequestRequest,
  AuthenticationControllerApi,
  AuthenticationResponseDto,
  Configuration,
  SignInRequestDto,
} from '@api/client';

export enum AuthReducer {
  login = '[AUTH] Login',
  renew = '[AUTH] Renew',
  logout = '[AUTH] Logout',
}

export const authLogin = createAsyncThunk<AuthenticationResponseDto | undefined, SignInRequestDto, ThunkApiConfig>(
  AuthReducer.login,
  async (values, { dispatch, extra, getState }) => {
    try {
      let request: AuthenticateRequestRequest = {
        signInRequestDto: values,
      };

      return await new AuthenticationControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).authenticateRequest(request);
    } catch (e) {
      console.error('authLogin error', e);
    }
  }
);

export const authRenew = createAsyncThunk<AuthenticationResponseDto | undefined, void, ThunkApiConfig>(
  AuthReducer.renew,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new AuthenticationControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).renewAuthentication();
    } catch (e) {
      console.error('authRenew error', e);
    }
  }
);

export const authLogout = createAction<boolean | undefined>(AuthReducer.logout);

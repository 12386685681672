import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useGetAndMapOrdersSf from './_hooks/useGetAndMapOrdersSf';
import ServerSidePaginator from 'shared/design-system/components/app-server-side-paginator';
import ActiveCycleOrderListTable from './_components/active-cycle-order-list-table';
import useGetPassiveCycleOptions from 'pages/private/passive-cycle/_hooks/useGetPassiveCycleOptions';
import ActiveCycleOrderListFilterForm, { orderSfListFormData } from './_components/active-cycle-order-list-filter-form';
import { LocationFilterEnum } from 'enums/global.enum';
import AppAccordion from 'shared/design-system/components/app-accordion';
import AppCard from 'shared/design-system/components/app-card';
import NoData from 'shared/design-system/components/no-data-box';
import useFormActiveCycle from 'shared/design-system/components/app-accordion/_hooks/useFormActiveCycle';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { filtersOrdersSfUsedSelector } from './_redux/selectors';
import { searchActiveOrdersSf } from './_redux/actions';
import { SearchContractOrdersSFRequest } from '@api/client';

const ActiveCycleOrderList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const filtriUsed = useAppSelector(filtersOrdersSfUsedSelector);
  const { orders, paginatorData } = useGetAndMapOrdersSf();
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions('active');

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useFormActiveCycle(
    orderSfListFormData,
    setIsOpen
  );

  const [controlledDate, setControlledDate] = useState<any>(null);

  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };

  const changePage = (page: number) => {
    let filters = { ...filtriUsed };
    if (filters.paging) {
      filters.paging = { ...filters.paging, page: page };
    }
    const payload: SearchContractOrdersSFRequest = {
      contractOrderSFSearchInputDto: filters!,
    };
    dispatch(searchActiveOrdersSf(payload));
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-3">
        <h4 className="text-gray-800 my-0">{t('Active cycle orders')}</h4>
      </div>

      <AppAccordion
        formData={orderSfListFormData}
        location={LocationFilterEnum.ACTIVE_CYCLE}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        chipsFormData={chipsFormData}
        setChipsFormData={setChipsFormData}
        reset={reset}
      >
        <ActiveCycleOrderListFilterForm
          onSubmit={onSubmit}
          handleReset={handleReset}
          setControlledDate={setControlledDate}
          mappedPassiveCycleOptions={mappedPassiveCycleOptions}
          // onReset={onReset} control={control} register={register}
        />
      </AppAccordion>

      <AppCard title={t('All orders')} className="mt-3">
        {orders.length === 0 ? (
          <NoData msg={t('No orders found')} />
        ) : (
          <>
            <ActiveCycleOrderListTable data={orders} />
          </>
        )}
        {orders?.length > 0 ? (
          <ServerSidePaginator
            currentPage={paginatorData?.page || 0}
            totalPages={paginatorData?.totalPages || 0}
            elementsToShow={orders.length}
            totalElements={paginatorData?.totalElements}
            onPageChange={(e) => changePage(e)}
          />
        ) : (
          ''
        )}
      </AppCard>
    </>
  );
};

export default ActiveCycleOrderList;

import { FatturaEmessaSearchDto, SearchFattureEmesse1Request } from '@api/client';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import {
  getActiveCycleFattureEmesse,
  saveFiltersActiveAssociableInvoicesUsed,
} from 'pages/private/active-cycle/active-cycle-order-list/_redux/actions';
import { filtersActiveAssociableInvoicesUsedSelector } from 'pages/private/active-cycle/active-cycle-order-list/_redux/selectors';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

const useFormAssociableActiveInvoices = (
  initialData: any,
  setIsOpenInvoice: React.Dispatch<React.SetStateAction<boolean>>,
  dataFattura: Date,
  fkCliente: string | undefined
) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const filterUsed = useAppSelector(filtersActiveAssociableInvoicesUsedSelector);
  const [chipsFormInvoicesData, setChipsFormInvoicesData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);
  const { handleSubmit, control, register, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  useEffect(() => {
    const data: any = filterUsed;
    const formDataArray = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          const isRangeModified = data[key].some((value: any, index: any) => value !== initialData[key][index]);
          if (isRangeModified) {
            formDataArray.push([key, data[key]]);
          }
        } else if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }
    setChipsFormInvoicesData([...formDataArray]);
  }, [dispatch, filterUsed, initialData]);

  const onSubmitInvoices = (data: any) => {
    isSubmitting.current = true;
    const formDataArray = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          const isRangeModified = data[key].some((value: any, index: any) => value !== initialData[key][index]);
          if (isRangeModified) {
            formDataArray.push([key, data[key]]);
          }
        } else if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormInvoicesData([...formDataArray]);

    const filterDto: FatturaEmessaSearchDto = {};
    filterDto.excludefattureAssociate = true;
    // filterDto.dataFatturaDa = dataFattura;
    filterDto.fkCliente = fkCliente;
    filterDto.paging = { size: 9999, page: 0 };

    if (data.idFattura) {
      filterDto.idFattura = data.idFattura;
    }
    if (data.importoMin) {
      debugger;
      filterDto.importoMin = Number(data.importoMin);
      filterDto.importoMax = Number(data.importoMin);
    }
    if (data.tipoFattura) {
      filterDto.tipoFattura = data.tipoFattura;
    }
    if (data.descrizione) {
      filterDto.descrizione = data.descrizione;
    }
    if (data.idAzienda) {
      filterDto.idAzienda = data.idAzienda;
    }
    if (data.idAccount) {
      filterDto.idAccount = data.idAccount;
    }

    const payload: SearchFattureEmesse1Request = {
      fatturaEmessaSearchDto: filterDto,
    };

    dispatch(getActiveCycleFattureEmesse({ request: payload, type: 'associable' }))
      .unwrap()
      .then((res: any) => {});

    handleSubmit(data);
    dispatch(saveFiltersActiveAssociableInvoicesUsed(data));
    setIsOpenInvoice(false);
  };

  const onResetInvoices = () => {
    reset(initialData);
    dispatch(saveFiltersActiveAssociableInvoicesUsed(initialData));
    setChipsFormInvoicesData([]);
  };

  return {
    onSubmitInvoices,
    onResetInvoices,
    filterParams,
    chipsFormInvoicesData,
    setChipsFormInvoicesData,
    resetInvoice: reset,
    formState,
  };
};

export default useFormAssociableActiveInvoices;

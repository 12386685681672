import {
  ContractOrderExternalCostDto,
  ProjectExternalCost,
  RemoveExternalCosts1Request,
  UpdateContractAndAssociateExternalCostsRequest,
} from '@api/client';
import { Input, InputNumber, Tooltip } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormBootstrap from 'react-bootstrap/Form';
import LinksButton from 'shared/design-system/components/links-button';
import { useAppDispatch } from 'core/store/hooks';
import { removeOtherCosts, searchOrders, updateOrder } from '../../../../_redux/actions';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import ModalAction from 'pages/private/project-list/project-detail/_components/modal-action';
import { useParams } from 'react-router-dom';

interface ContractOrderExternalCostWithFlag extends ContractOrderExternalCostDto {
  isAssociated: boolean;
}

interface Props {
  associatedProject: ProjectExternalCost;
  contractOrderId: number | undefined;
}

const PassiveCycleProjectAssociatedCard: React.FC<Props> = ({ associatedProject, contractOrderId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: any }>();
  const [project, setProject] = useState<ProjectExternalCost>(associatedProject);
  const [showExternalCosts, setShowExternalCosts] = useState<boolean>(false);
  const [originalProject, setOriginalProject] = useState<ProjectExternalCost>(project);
  const [contractOrderExternalCosts, setContractOrderExternalCosts] = useState<ContractOrderExternalCostWithFlag[]>([]);
  const [originalContractOrderExternalCosts, setOriginalContractOrderExternalCosts] = useState<
    ContractOrderExternalCostWithFlag[]
  >([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showModalRemoveAllCosts, setShowModalRemoveAllCosts] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (project) {
      const mappedCosts: ContractOrderExternalCostWithFlag[] | undefined = project.externalCosts?.map((cost) => {
        return {
          isAssociated: true,
          externalCostDescription: cost.description,
          groupId: cost.groupId,
          projectId: project.projectId,
          costTypeId: cost.costType?.id,
          amount:
            cost.contractAmount ||
            parseFloat(
              cost?.costAllocation
                ?.map((allocation) => allocation.actualCost)
                .reduce((a, b) => (a ?? 0) + (b ?? 0), 0)
                ?.toFixed(2) || '0'
            ),
        };
      });
      setContractOrderExternalCosts(mappedCosts || []);
      setOriginalContractOrderExternalCosts(mappedCosts || []);
    }
  }, [project]);

  const handleChangeAssociated = (e: ChangeEvent<HTMLInputElement>, cost: ContractOrderExternalCostWithFlag) => {
    const updatedCosts = contractOrderExternalCosts.map((item) => {
      if (item.costTypeId === cost.costTypeId && item.groupId === cost.groupId) {
        return {
          ...item,
          isAssociated: e.target.checked,
        };
      }
      return item;
    });
    setContractOrderExternalCosts(updatedCosts);
    setIsDirty(true);
  };

  const handleChangeAmount = (e: number | undefined, cost: ContractOrderExternalCostWithFlag) => {
    const updatedCosts = contractOrderExternalCosts.map((item) => {
      if (item.costTypeId === cost.costTypeId && item.groupId === cost.groupId) {
        return {
          ...item,
          amount: e || 0,
        };
      }
      return item;
    });
    setContractOrderExternalCosts(updatedCosts);
    setIsDirty(true);
  };

  const handleChangeDescription = (e: string, cost: ContractOrderExternalCostWithFlag) => {
    const updatedCosts = contractOrderExternalCosts.map((item) => {
      if (item.costTypeId === cost.costTypeId && item.groupId === cost.groupId) {
        if (!e) {
          setIsDirty(false);
        }
        return {
          ...item,
          externalCostDescription: e,
        };
      }
      return item;
    });
    setContractOrderExternalCosts(updatedCosts);
    setIsDirty(true);
  };

  const resetData = () => {
    setProject(originalProject);
    setIsDirty(false);
    setShowExternalCosts(false);
  };

  const removeAllCosts = () => {
    if (!contractOrderId) return;
    const payload: RemoveExternalCosts1Request = {
      contractOrderId,
      contractOrderExternalCostDto: contractOrderExternalCosts.map((cost) => {
        return {
          costTypeId: cost.costTypeId,
          projectId: cost.projectId,
          groupId: cost.groupId,
          amount: cost.amount,
        };
      }),
    };
    removeCosts(payload);
  };

  const removeCosts = (payload: RemoveExternalCosts1Request) => {
    dispatch(removeOtherCosts(payload))
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(
            searchOrders({
              request: { contractOrderSearchDto: { orderId: Number(id), paging: { page: 0, size: 10 } } },
              currentOrderId: Number(id),
            })
          );
        }
      });
  };

  const save = () => {
    if (!contractOrderId) return;
    const costsToRemove = contractOrderExternalCosts.filter((cost) => !cost.isAssociated);
    if (costsToRemove.length > 0) {
      const payload: RemoveExternalCosts1Request = {
        contractOrderId,
        contractOrderExternalCostDto: costsToRemove.map((cost) => {
          return {
            costTypeId: cost.costTypeId,
            projectId: cost.projectId,
            groupId: cost.groupId,
            amount: cost.amount,
            externalCostDescription: cost.externalCostDescription,
          };
        }),
      };
      removeCosts(payload);
    }
    // find cost to update: if the cost is associated and the amount is different from the original one
    const costsToUpdate = contractOrderExternalCosts.filter((cost) => {
      const originalCost = originalProject.externalCosts?.find(
        (originalCost) => originalCost.costType?.id === cost.costTypeId && originalCost.groupId === cost.groupId
      );
      return (
        cost.isAssociated &&
        originalCost &&
        (originalCost.costAllocation?.[0].actualCost !== cost.amount ||
          originalCost.description !== cost.externalCostDescription) &&
        cost.amount !== 0
      );
    });
    const hasEmptyDescription = costsToUpdate.some((cost) => !cost.externalCostDescription);
    if (hasEmptyDescription) {
      return AppToastService.error('All associated costs must have a description');
    }
    if (costsToUpdate.length > 0) {
      const payload: UpdateContractAndAssociateExternalCostsRequest = {
        createContractOrderDto: [
          {
            contractOrderId,
            contractOrderExternalCostDtoList: costsToUpdate,
          },
        ],
      };
      dispatch(updateOrder(payload))
        .unwrap()
        .then((res) => {
          if (res) {
            dispatch(
              searchOrders({
                request: { contractOrderSearchDto: { orderId: Number(id), paging: { page: 0, size: 10 } } },
                currentOrderId: Number(id),
              })
            );
          }
        });
    }
    setEditMode(false);
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <div className="dflex flex-column">
            <div className="d-flex justify-content-between" style={{ maxHeight: '25px' }}>
              <h6 className="fw-bold align-content-center">{`${project.projectId} (${project.intranetId}) - ${project.projectName}`}</h6>

              <div className="d-flex">
                <Tooltip placement="top" title="Delete" color="red">
                  <button
                    onClick={() => {
                      setShowModalRemoveAllCosts(true);
                    }}
                    className="btn-icon"
                    style={{ height: '10px' }}
                  >
                    <i className={'icon-delete text-red me-1'} />
                  </button>
                </Tooltip>
                <Tooltip placement="top" title="Edit" color="teal">
                  <button
                    onClick={() => {
                      setEditMode(!editMode);
                      setContractOrderExternalCosts(originalContractOrderExternalCosts);
                      setIsDirty(false);
                      setShowExternalCosts(true);
                    }}
                    className="btn-icon"
                    style={{ height: '10px' }}
                  >
                    <i className={'icon-edit text-teal-links me-1'} />
                  </button>
                </Tooltip>
                <button
                  className="download btn btn-sm btn-links-no-border d-flex align-items-top p-0"
                  onClick={() => setShowExternalCosts(!showExternalCosts)}
                >
                  <p className="text-blue-links fw-bold">
                    {showExternalCosts ? t('Hide external costs') : t('Show external costs >')}
                  </p>
                </button>
              </div>
            </div>
            {showExternalCosts && (
              <>
                <div className="mt-4">
                  <div className="row">
                    {contractOrderExternalCosts?.map((cost, index) => (
                      <div key={index} className="col-6 d-flex justify-content-between align-items-center mb-3">
                        <div className="col-9 d-flex align-items-center">
                          <FormBootstrap.Check
                            type={'checkbox'}
                            checked={cost.isAssociated}
                            onChange={(e) => handleChangeAssociated(e, cost)}
                            disabled={!editMode}
                          />
                          <label className="w-100 ms-2 me-4">
                            {t(`project-detail.cost-type-id-${cost.costTypeId}`)}
                            <br />
                            {editMode ? (
                              <Input
                                type="text"
                                className="w-100"
                                placeholder={t('Insert description')}
                                value={cost.externalCostDescription}
                                onChange={(e) => handleChangeDescription(e.target.value, cost)}
                                size="middle"
                                disabled={!editMode || !cost.isAssociated}
                              />
                            ) : (
                              <span className="text-placeholder">
                                {cost.externalCostDescription || 'No description avaiable'}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-3 input-num-wrapper d-flex flex-column">
                          {editMode && <label className="w-100">{t(`Amount`)}</label>}

                          <InputNumber
                            type="number"
                            className="w-100"
                            placeholder={t('Insert amount')}
                            min={0}
                            step="0.01"
                            value={cost.amount}
                            onChange={(e) => handleChangeAmount(e as number | undefined, cost)}
                            size="middle"
                            suffix={'€'}
                            disabled={!editMode || !cost.isAssociated}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {editMode && (
                  <div className="d-flex justify-content-end">
                    <LinksButton className="btn btn-links-outline" onClick={() => resetData()}>
                      Cancel
                    </LinksButton>
                    <LinksButton className="btn-primary btn-links" onClick={() => save()} isDisabled={!isDirty}>
                      Save
                    </LinksButton>
                  </div>
                )}
              </>
            )}
          </div>
        </Card.Body>
      </Card>
      <ModalAction
        title={t('Disassociate project and related external costs')}
        actionLabel={t('common.confirm')}
        cancelLabel={t('common.cancel')}
        action={() => {
          removeAllCosts();
        }}
        setShow={(value) => setShowModalRemoveAllCosts(value)}
        show={showModalRemoveAllCosts}
      >
        <p>{`Are you sure you want to disassociate all the external costs from the project ${project.projectName}?`}</p>
      </ModalAction>
    </>
  );
};

export default PassiveCycleProjectAssociatedCard;

import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './style.scss';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { authLogout } from 'pages/auth/_redux/actions';
import HamburgerMenu from './hamburger-menu';
import { useTranslation } from 'react-i18next';
import UserBadge from './user-badge';
import { Tooltip } from 'antd';
import { generateSidebarLinks } from 'enums/sidebarLinks';
import SidebarHeader from './sidebar-header';
import { HELP_URL } from 'env';
import { layoutSidebarToggleSelector } from 'shared/design-system/_redux/selectors';
import { layoutToggleSidebar } from 'shared/design-system/_redux/actions';

const AppSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const sidebarToggled = useAppSelector(layoutSidebarToggleSelector);

  const handleLogout = () => {
    dispatch(authLogout());
  };

  const toggleSidebar = () => {
    dispatch(layoutToggleSidebar());
  };

  const sidebarLinks = generateSidebarLinks(t);

  return (
    <>
      <HamburgerMenu />
      <Nav
        className={`fixed-sidebar navbar-nav sidebar sidebar-dark accordion app-sidebar  hideOnSm ${
          sidebarToggled ? 'toggled' : 'is-open'
        }`}
        id="mySidenav"
      >
        <SidebarHeader sidebarToggled={sidebarToggled} toggleSidebar={toggleSidebar} />
        <div className={`${!sidebarToggled ? 'justify-navbar' : 'justify-toggled'}`}>
          <div>
            {sidebarLinks.map(
              (link) =>
                link.authCheck() && (
                  <Tooltip title={link.label} color="white" placement="right" key={link.path}>
                    <Nav.Item className="link-container mx-auto py-0 my-0">
                      <Nav.Link as={NavLink} to={link.path} disabled={link.disabled} className="link-item py-2">
                        <div className="d-flex align-items-center">
                          <i className={`${link.icon} text-dark text-xl`} />
                          {!sidebarToggled && (
                            <div className="sidebar-link-label-container">
                              <span>{link.label}</span>
                            </div>
                          )}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Tooltip>
                )
            )}
          </div>
          <div className="sidebar-bottom-section">
            {sidebarToggled && <hr className="sidebar-hr" />}
            <Tooltip title={t('common.help')} color="white" placement="right">
              <Nav.Item className="ms-auto link-container mx-auto">
                <Nav.Link href={HELP_URL} target="_blank" active={false} className="link-item">
                  <i className="fas fa-question text-dark" />
                  {!sidebarToggled && <span className="ps-1">{t('common.help')}</span>}
                </Nav.Link>
              </Nav.Item>
            </Tooltip>
            <Tooltip title={t('common.logout')} color="white" placement="right">
              <Nav.Item className="ms-auto link-container mx-auto">
                <Nav.Link
                  onClick={() => {
                    handleLogout();
                  }}
                  disabled={false}
                  className="link-item"
                >
                  <i className="fas fa-sign-out-alt text-dark" />
                  {!sidebarToggled && <span className="">{t('common.logout')}</span>}
                </Nav.Link>
              </Nav.Item>
            </Tooltip>
            <UserBadge />
          </div>
        </div>
      </Nav>
    </>
  );
};
export default AppSidebar;

import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DatePicker, Input, Select, SelectProps, Switch, Tag } from 'antd';
import dayjs from 'dayjs';
import 'shared/design-system/components/app-accordion/style.scss';
import { t } from 'i18next';
import { MappedOrderOptions } from 'enums/orderOptions';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import LinksButton from 'shared/design-system/components/links-button';
import { filtersOrdersSfUsedSelector } from '../../_redux/selectors';
import { saveFiltersOrdersSfUsed } from '../../_redux/actions';
import { filterOption } from 'shared/utils/common.utils';

export const orderSfListFormData: any = {
  orderId: undefined,
  ordNumero: undefined,
  orderName: undefined,
  startEndRange: [],
  clienteId: undefined,
  aziendaId: undefined,
  businessManagerId: undefined,
  stato: undefined,
  ordCig: undefined,
  ordCup: undefined,
  ordSdi: undefined,
  dataPo: undefined,
  denominazioneCliente: undefined,
  projectId: undefined,
  sortByInsertionDate: false,
};

interface Props {
  onSubmit: any;
  handleReset: () => void;
  // setIsOpen: (isOpen: boolean) => any;
  // isOpen: any;
  setControlledDate: any;
  mappedPassiveCycleOptions: MappedOrderOptions | undefined;
}
const ActiveCycleOrderListFilterForm: React.FC<Props> = ({
  onSubmit,
  handleReset,
  setControlledDate,
  mappedPassiveCycleOptions,
}) => {
  const filterUsed = useAppSelector(filtersOrdersSfUsedSelector);
  const dispatch = useAppDispatch();
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: orderSfListFormData,
    values: filterUsed,
  });

  const Reset = () => {
    reset();
    handleReset();
    setControlledDate([]);
  };

  const { RangePicker } = DatePicker;
  const handleFormSubmit = async (formData: any) => {
    dispatch(saveFiltersOrdersSfUsed(formData));
    try {
      await onSubmit(formData);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(handleFormSubmit)}>
      <Form.Group className="grid">
        <Row>
          <Col sm="3">
            <Form.Label>{t('ID Order')}</Form.Label>
            <Controller
              name="orderId"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search order ID')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('Order number')}</Form.Label>
            <Controller
              name="ordNumero"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search order number')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('Order name')}</Form.Label>
            <Controller
              name="orderName"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search order name')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            {' '}
            <Form.Label>{t('Start/End date')}</Form.Label>
            <Controller
              name="startEndRange"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <RangePicker
                  placeholder={[
                    `${t('project-list.start-date-placeholder')}`,
                    `${t('project-list.end-date-placeholder')}`,
                  ]}
                  className="w-100 createDateRangePicker"
                  popupClassName="createDateRangePicker"
                  {...field}
                  ref={field.ref}
                  name={field.name}
                  onBlur={field.onBlur}
                  format="DD/MM/YYYY"
                  value={
                    field.value && field.value.length === 2 ? [dayjs(field.value[0]), dayjs(field.value[1])] : null
                  }
                  size="large"
                  onChange={(dates: any) => {
                    field.onChange(dates ? dates.map((date: any) => date.format('YYYY-MM-DD')) : []);
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="3">
            <Form.Label>{t('Account')}</Form.Label>
            <Controller
              name="clienteId"
              control={control}
              render={({ field }) => (
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  allowClear
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  placeholder={t('Search account')}
                >
                  {mappedPassiveCycleOptions?.clienteList?.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.denominazione}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('Legal entity')}</Form.Label>
            <Controller
              name="aziendaId"
              control={control}
              render={({ field }) => (
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  allowClear
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  placeholder={t('Search legal entity')}
                >
                  {mappedPassiveCycleOptions?.aziendaList?.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.nome}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('CL/AM')}</Form.Label>
            <Controller
              name="businessManagerId"
              control={control}
              render={({ field }) => (
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  allowClear
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  placeholder={t('Search CL/AM')}
                >
                  {mappedPassiveCycleOptions?.businessManager?.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('Status')}</Form.Label>
            <Controller
              name="stato"
              control={control}
              render={({ field }) => (
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  allowClear
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  placeholder={t('Search status')}
                >
                  {mappedPassiveCycleOptions?.orderStatusList?.map((el, index) => (
                    <Select.Option key={index} value={el}>
                      {el}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="3">
            <Form.Label>{t('CIG')}</Form.Label>
            <Controller
              name="ordCig"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search CIG')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('CUP')}</Form.Label>
            <Controller
              name="ordCup"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search CUP')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('SDI')}</Form.Label>
            <Controller
              name="ordSdi"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search SDI')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('Account name')}</Form.Label>
            <Controller
              name="denominazioneCliente"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search account name')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="3">
            <Form.Label>{t('Object')}</Form.Label>
            <Controller
              name="ordOggetto"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search object')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('PO number')}</Form.Label>
            <Controller
              name="ordPoNumber"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search PO number')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('GG term')}</Form.Label>
            <Controller
              name="ordTermGG"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search TermGG')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            {' '}
            <Form.Label>{t('PO date')}</Form.Label>
            <Controller
              name="dataPo"
              control={control}
              render={({ field }) => (
                <DatePicker
                  placeholder={`${t('Search PO date')}`}
                  className="w-100 createDateRangePicker"
                  popupClassName="createDateRangePicker"
                  {...field}
                  ref={field.ref}
                  name={field.name}
                  onBlur={field.onBlur}
                  format="DD/MM/YYYY"
                  value={field.value}
                  size="large"
                  onChange={(date: any) => {
                    field.onChange(date);
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="3">
            <Form.Label>{t('ID project')}</Form.Label>
            <Controller
              name="projectId"
              control={control}
              render={({ field }) => (
                <Input
                  type="number"
                  placeholder={t('Search ID project')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('Sort by insertion date')}</Form.Label>
            <div>
              <Controller
                name="sortByInsertionDate"
                control={control}
                render={({ field }: any) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(checked) => field.onChange(checked)}
                    className="mt-2"
                  />
                )}
              />
            </div>
          </Col>
        </Row>
      </Form.Group>

      <div className="d-flex mt-3">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};
export default ActiveCycleOrderListFilterForm;

type TagRender = SelectProps['tagRender'];

const tagRender: TagRender = (props) => {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={'blue'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};

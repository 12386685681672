import { useEffect } from 'react';
import { Trans } from 'react-i18next';

interface Props {
  keyPaginator?: string;
  currentPage: number;
  pageSize: number;
  totalPages?: number;
  totalElements?: number;
  onPageChange: (page: number) => void;
}

const AppPaginator: React.FC<Props> = (props) => {
  const { keyPaginator, currentPage, totalPages = 0, totalElements, pageSize, onPageChange } = props;

  useEffect(() => {
    if (keyPaginator) {
      const currentPageStorage = sessionStorage.getItem(keyPaginator);
      if (currentPageStorage) {
        const storedPage = parseInt(currentPageStorage, 10);
        if (!isNaN(storedPage)) {
          onPageChange(storedPage);
        }
      }
    }
  }, [keyPaginator, onPageChange]);

  const handlePageChange = (page: number) => {
    onPageChange(page);
    if (keyPaginator) {
      sessionStorage.setItem(keyPaginator, String(page));
    }
  };
  const handleFirstClick = () => {
    handlePageChange(0);
  };

  const handlePrevClick = () => {
    handlePageChange(currentPage - 1);
  };

  const handleNextClick = () => {
    handlePageChange(currentPage + 1);
  };

  const handleLastClick = () => {
    handlePageChange(totalPages - 1);
  };
  const startIndex = currentPage * pageSize + 1;
  const endIndex = Math.min(startIndex + pageSize - 1, totalElements!);
  const elementsToShow = endIndex - startIndex + 1;

  return (
    <div className="row">
      <div className="col-6 d-flex align-items-center">
        <Trans i18nKey="common.paginator.results-found" values={{ totalElements }} components={{ b: <b /> }} />
      </div>

      <div className="col-6 d-flex  justify-content-end mt-2">
        <div className="me-3 mt-2">
          <Trans
            i18nKey="common.paginator.displayed"
            values={{ elementsToShow }}
            components={{ b: <b className="text-blue-links" /> }}
          />
        </div>
        <button
          className="me-2 border-primary btn text-blue-links btn-links-pagination "
          onClick={() => handleFirstClick()}
          disabled={currentPage === 0}
        >
          <i className="fa fa-angle-double-left" aria-hidden="true"></i>
        </button>

        <button
          className="me-2 border-primary btn text-blue-links btn-links-pagination "
          onClick={() => handlePrevClick()}
          disabled={currentPage === 0}
        >
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </button>

        <button
          className="ms-2 border-primary btn text-blue-links btn-links-pagination "
          disabled={currentPage === 0}
          onClick={() => handlePrevClick()}
        >
          {currentPage + 1}
        </button>

        {currentPage + 1 < totalPages && (
          <button
            className="ms-2 border-primary btn text-blue-links btn-links-pagination "
            onClick={() => handleNextClick()}
          >
            {currentPage + 2}
          </button>
        )}

        <button
          className="ms-2 border-primary btn text-blue-links btn-links-pagination "
          onClick={() => handleNextClick()}
          disabled={currentPage === totalPages - 1 || totalPages === 0}
        >
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </button>

        <button
          className="ms-2 border-primary btn text-blue-links btn-links-pagination "
          onClick={() => handleLastClick()}
          disabled={currentPage === totalPages - 1 || totalPages === 0}
        >
          <i className="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
};

export default AppPaginator;

import {
  Configuration,
  ControllerDedicatoAlleFunzioniDellaSezioneProductsAndSubscriptionsApi,
  DeleteProgettoAssetRequest,
  ElencoAssetDto,
  ElencoAssetInsertRequest,
  ElencoAssetListDto,
  ProjectElencoAssetDtoList,
  ProjectElencoAssetInput,
} from '@api/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';

enum ProductsAndSubscriptionsActions {
  getAssets = '[ProductsAndSubscriptions] Get Assets',
  updateProjectAssets = '[ProductsAndSubscriptions] Update Project Assets',
  getProjectAssetById = '[ProductsAndSubscriptions] Get Project Asset By Id',
  deleteProjectAsset = '[ProductsAndSubscriptions] Delete Project Asset',
  createAsset = '[ProductsAndSubscriptions] Create Asset',
}

export const getAssets = createAsyncThunk<ElencoAssetListDto | undefined, void, ThunkApiConfig>(
  ProductsAndSubscriptionsActions.getAssets,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerDedicatoAlleFunzioniDellaSezioneProductsAndSubscriptionsApi(
        getConfiguration(Configuration, extra, getState)
      ).listAssetDetail();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const updateProjectAssets = createAsyncThunk<void | undefined, ProjectElencoAssetInput[], ThunkApiConfig>(
  ProductsAndSubscriptionsActions.updateProjectAssets,
  async (list, { dispatch, extra, getState }) => {
    try {
      const payload = { projectElencoAssetInput: list };
      await new ControllerDedicatoAlleFunzioniDellaSezioneProductsAndSubscriptionsApi(
        getConfiguration(Configuration, extra, getState)
      ).projectElencoAssetUpdate(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getProjectAssetById = createAsyncThunk<ProjectElencoAssetDtoList | undefined, number, ThunkApiConfig>(
  ProductsAndSubscriptionsActions.getProjectAssetById,
  async (idProject, { dispatch, extra, getState }) => {
    try {
      return await new ControllerDedicatoAlleFunzioniDellaSezioneProductsAndSubscriptionsApi(
        getConfiguration(Configuration, extra, getState)
      ).listProjectAssetDetail({
        idProject,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const deleteProjectAsset = createAsyncThunk<void | undefined, DeleteProgettoAssetRequest, ThunkApiConfig>(
  ProductsAndSubscriptionsActions.deleteProjectAsset,
  async (payload, { dispatch, extra, getState }) => {
    try {
      await new ControllerDedicatoAlleFunzioniDellaSezioneProductsAndSubscriptionsApi(
        getConfiguration(Configuration, extra, getState)
      ).deleteProgettoAsset(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const createAsset = createAsyncThunk<ElencoAssetDto | undefined, ElencoAssetInsertRequest, ThunkApiConfig>(
  ProductsAndSubscriptionsActions.createAsset,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerDedicatoAlleFunzioniDellaSezioneProductsAndSubscriptionsApi(
        getConfiguration(Configuration, extra, getState)
      ).elencoAssetInsert(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { getPassiveCycleOptions } from '../_redux/actions';
import { passiveCycleOptionsSelector } from '../_redux/selectors';
import {
  mappedExpenseItemEnumList,
  mappedOrderContractStatusEnumList,
  MappedOrderOptions,
  mappedOrderProcessingStatusEnumList,
  mappedOrderTypeEnumList,
  OrderOption,
} from 'enums/orderOptions';
import { activeCycleOptionsSelector } from 'pages/private/active-cycle/active-cycle-order-list/_redux/selectors';
import { getActiveCycleOptions } from 'pages/private/active-cycle/active-cycle-order-list/_redux/actions';

const useGetPassiveCycleOptions = (location: 'active' | 'passive') => {
  const dispatch = useAppDispatch();
  const passiveCycleOptions = useAppSelector(
    location === 'active' ? activeCycleOptionsSelector : passiveCycleOptionsSelector
  );
  const [mappedPassiveCycleOptions, setMappedPassiveCycleOptions] = useState<MappedOrderOptions>();

  useEffect(() => {
    if (!passiveCycleOptions) {
      switch (location) {
        case 'active':
          dispatch(getActiveCycleOptions());
          break;
        case 'passive':
          dispatch(getPassiveCycleOptions());
          break;
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (passiveCycleOptions) {
      const {
        expenseItemEnumList,
        orderContractStatusEnumList,
        orderProcessingStatusEnumList,
        orderTypeEnumList,
        fornitoriList,
        orderCategoryList,
        aziendaList,
        sedeList,
        clienteList,
        businessManager,
        orderStatusList,
      } = passiveCycleOptions || {};
      const mapItems = (enumList: string[] | undefined, mappedList: OrderOption[] | undefined) =>
        enumList?.map((item) => ({
          value: item,
          label: mappedList?.find((mappedItem) => mappedItem.value === item)?.label || item,
        })) || [];
      setMappedPassiveCycleOptions({
        mappedExpenseItemEnumList: mapItems(expenseItemEnumList, mappedExpenseItemEnumList),
        mappedOrderContractStatusEnumList: mapItems(orderContractStatusEnumList, mappedOrderContractStatusEnumList),
        mappedOrderProcessingStatusEnumList: mapItems(
          orderProcessingStatusEnumList,
          mappedOrderProcessingStatusEnumList
        ),
        mappedOrderTypeEnumList: mapItems(orderTypeEnumList, mappedOrderTypeEnumList),
        fornitoriList,
        orderCategoryList,
        aziendaList,
        sedeList,
        clienteList,
        businessManager,
        orderStatusList,
      });
    }
  }, [passiveCycleOptions]);

  return { mappedPassiveCycleOptions };
};

export default useGetPassiveCycleOptions;

import { useState } from 'react';
import { useAppDispatch } from 'core/store/hooks';
import { deleteOrder, searchOrders } from '../../_redux/actions';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { useTranslation } from 'react-i18next';

const useDeleteOrderById = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const deleteOrderById = () => {
    if (!idToDelete) return;
    dispatch(deleteOrder(idToDelete)).then((res) => {
      if (res) {
        dispatch(searchOrders({ request: { contractOrderSearchDto: {} } }));
        AppToastService.success(t('common.action-completed-successfully'));
      }
    });
  };

  return {
    deleteOrderById,
    setIdToDelete,
  };
};

export default useDeleteOrderById;

import { useTranslation } from 'react-i18next';
import { CrudMenuKeys } from 'enums/project.enum';
import { Tabs, TabsProps } from 'antd';
import ProjectCrud from './project-crud';
import RevenuesCostsCrud from './revenues-costs-crud';
import AuthService from 'core/auth/auth.service';
import ProjectCrudDelivery from './project-crud-delivery';
import StructureCrud from './structure-crud';
import { StructureTypes } from 'enums/global.enum';

const CrudMenu: React.FC = () => {
  const { t } = useTranslation();
  const isSuperAdmin = AuthService.isSuperAdmin();
  const currentUser = AuthService.getUser();
  const revenuesCostsCrudWitelist = [381, 777, 386, 346, 1416, 12624, 1441];

  const crudTabs: TabsProps['items'] = isSuperAdmin
    ? [
        {
          key: CrudMenuKeys.PROJECT_CRUD,
          label: t('crud.crudTabs.project-crud'),
          children: <ProjectCrud />,
        },
        ...(revenuesCostsCrudWitelist.includes(Number(currentUser?.userId))
          ? [
              {
                key: CrudMenuKeys.REVENUES_COSTS_CRUD,
                label: t('crud.crudTabs.revenues-costs-crud'),
                children: <RevenuesCostsCrud />,
              },
            ]
          : []),
        {
          key: CrudMenuKeys.STRUCTURE_CRUD_DELIVERY,
          label: t('crud.crudTabs.structure-crud-delivery'),
          children: <StructureCrud structureType={StructureTypes.DELIVERY} key={StructureTypes.DELIVERY} />,
        },
        {
          key: CrudMenuKeys.STRUCTURE_CRUD_BUSINESS,
          label: t('crud.crudTabs.structure-crud-business'),
          children: <StructureCrud structureType={StructureTypes.BUSINESS} key={StructureTypes.BUSINESS} />,
        },
      ]
    : [
        {
          key: CrudMenuKeys.PROJECT_CRUD_DELIVERY,
          label: t('crud.crudTabs.project-crud-delivery'),
          children: <ProjectCrudDelivery />,
        },
      ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <Tabs
      defaultActiveKey={isSuperAdmin ? CrudMenuKeys.PROJECT_CRUD : CrudMenuKeys.PROJECT_CRUD_DELIVERY}
      items={crudTabs}
      onChange={onChange}
    />
  );
};

export default CrudMenu;

import { useEffect, useState } from 'react';
import { DettagliProgettoDtoStatoEnum, DettaglioProjectDto } from '@api/client';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'shared/utils/common.utils';
import { ValueType } from 'enums/global.enum';

export enum DetailIcons {
  arrowUp = 'fa-solid fa-arrow-up text-success',
  arrowDown = 'fa-solid fa-arrow-down text-danger',
  danger = 'fa-solid fa-circle-exclamation text-danger',
}

export interface Field {
  label: string;
  value: string;
  span: string;
  isFirstHigher?: boolean;
  isFirstLower?: boolean;
  icon?: React.ReactNode;
}
//varie casistiche in basre al quale visualizzare i campi nell'accordion
function useProjectFields(
  projectDetails: DettaglioProjectDto | null,
  currentStatus: DettagliProgettoDtoStatoEnum | undefined
) {
  const { t } = useTranslation();
  const [fields, setFields] = useState<Field[]>([]);

  useEffect(() => {
    if (!projectDetails) return;
    const fields = calculateFields();
    setFields(fields);
  }, [projectDetails, currentStatus]);
  const revenue = projectDetails?.baselineOfferta?.ricaviConsolidati;
  const totalCost =
    projectDetails?.baselineOfferta?.costoHrConsolidato! + projectDetails?.baselineOfferta?.costoEsternoConsolidato!;
  const marginAc = revenue ? (((revenue - totalCost) / revenue) * 100).toFixed(2) : 0;
  const calculateFields = () => {
    const newFields = [];
    switch (currentStatus) {
      case DettagliProgettoDtoStatoEnum.WorkInProgress:
      case DettagliProgettoDtoStatoEnum.ControlloCdg:
      case DettagliProgettoDtoStatoEnum.ControlloCl:
      case DettagliProgettoDtoStatoEnum.Completed:
        newFields.push(
          {
            label: `${t('project-detail.revenues')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.importoOfferta, 0) ?? '-'}`,
            span: `${ValueType.EURO} (${formatCurrency(projectDetails?.baselineOfferta?.sal, 0) ?? '-'}${
              ValueType.PERCENTUALE
            } sal)`,
            col: 1,
          },
          {
            label: `${t('project-detail.consolidated-revenues-consolidated-costs')}`,
            value: `${formatCurrency(revenue, 0) ?? '-'}/${formatCurrency(totalCost, 0) ?? '-'}€ (${marginAc}%)`,
            span: ValueType.NULL,
            skipComparison: true,
            col: 1,
          },
          {
            label: `${t('project-detail.planned-cost-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.costoTotalePianificato, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.costoTotaleOfferta, 0) ?? '-'
            }`,
            span: ValueType.EURO,
            isFirstHigher: false,
            isFirstLower: false,
            icon: '',
            col: 2,
          },
          {
            label: `${t('project-detail.absolute-margin-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.margineProgettoPianificato, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.margineProgettoOfferta, 0) ?? '-'
            }`,
            span: ValueType.EURO,
            isFirstHigher: false,
            isFirstLower: false,
            icon: '',
            col: 2,
          },
          {
            label: `${t('project-detail.margin-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.margineProgettoPianificatoPerc, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.margineProgettoOffertaPerc, 0) ?? '-'
            }`,
            span: ValueType.PERCENTUALE,
            isFirstHigher: false,
            isFirstLower: false,
            icon: '',
            col: 2,
          },
          {
            label: `${t('project-detail.gu-offer')}`,
            value: `${projectDetails?.baselineOfferta?.totaleGiorniUomoPianificati?.toFixed(0) ?? '-'} / ${
              projectDetails?.baselineOfferta?.totaleGiorniUomoOfferta?.toFixed(0) ?? '-'
            }`,
            span: ValueType.GIORNI,
            isFirstHigher: false,
            isFirstLower: false,
            icon: '',
            col: 2,
          },
          {
            label: `${t('project-detail.rate-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.ratePianificato, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.rateOfferta, 0) ?? '-'
            }`,
            span: ValueType.EURO,
            isFirstHigher: false,
            isFirstLower: false,
            col: 2,
          }
        );
        break;
      case DettagliProgettoDtoStatoEnum.ClosedWonPlanning:
        newFields.push(
          {
            label: `${t('project-detail.offer-amount')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.importoOfferta, 0) ?? '-'}`,
            span: ValueType.EURO,
            col: 1,
          },
          {
            label: `${t('project-detail.planned-cost-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.costoTotalePianificato, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.costoTotaleOfferta, 0) ?? '-'
            }`,
            span: ValueType.EURO,
            isFirstHigher: false,
            isFirstLower: false,
            icon: '',
            col: 2,
          },
          {
            label: `${t('project-detail.absolute-margin-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.margineProgettoPianificato, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.margineProgettoOfferta, 0) ?? '-'
            }`,
            span: ValueType.EURO,
            isFirstHigher: false,
            isFirstLower: false,
            icon: '',
            col: 2,
          },
          {
            label: `${t('project-detail.margin-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.margineProgettoPianificatoPerc, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.margineProgettoOffertaPerc, 0) ?? '-'
            }`,
            span: ValueType.PERCENTUALE,
            isFirstHigher: false,
            isFirstLower: false,
            icon: '',
            col: 2,
          },
          {
            label: `${t('project-detail.planned-days')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.totaleGiorniUomoPianificati, 0) ?? '-'}`,
            span: ValueType.GIORNI,
            col: 1,
          },
          {
            label: `${t('project-detail.rate-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.ratePianificato, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.rateOfferta, 0) ?? '-'
            }`,
            span: ValueType.EURO,
            isFirstHigher: false,
            isFirstLower: false,
            col: 2,
          }
        );
        break;
      default:
        newFields.push(
          {
            label: `${t('project-detail.offer-amount')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.importoOfferta, 0) ?? '-'}`,
            span: ValueType.EURO,
            col: 1,
          },
          {
            label: `${t('project-detail.planned-cost-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.costoTotalePianificato, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.costoTotaleOfferta, 0) ?? '-'
            }`,
            span: ValueType.EURO,
            isFirstHigher: false,
            isFirstLower: false,
            icon: '',
            col: 2,
          },
          {
            label: `${t('project-detail.margin')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.margineProgettoPianificato, 0) ?? '-'}`,
            span: ValueType.EURO,
            col: 2,
          },
          {
            label: `${t('project-detail.planned-days')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.totaleGiorniUomoPianificati, 0) ?? '-'}`,
            span: ValueType.GIORNI,
            col: 1,
          },
          {
            label: `${t('project-detail.rate-offer')}`,
            value: `${formatCurrency(projectDetails?.baselineOfferta?.ratePianificato, 0) ?? '-'} / ${
              formatCurrency(projectDetails?.baselineOfferta?.rateOfferta, 0) ?? '-'
            }`,
            span: ValueType.EURO,
            isFirstHigher: false,
            isFirstLower: false,
            col: 1,
          }
        );
        break;
    }

    newFields.forEach((field) => {
      if (field.value.includes('/') && field.span && field.skipComparison !== true) {
        const values = field.value.split('/');
        const firstValue = parseFloat(values[0]);
        const secondValue = parseFloat(values[1]);
        field.isFirstHigher = firstValue > secondValue;
        field.isFirstLower = firstValue < secondValue;
      }
    });

    return newFields;
  };

  return fields;
}
export default useProjectFields;

export const verifycolor = (field: any) => {
  if (field.label.toLowerCase().includes('margin') || field.label.toLowerCase().includes('rate')) {
    if (field.isFirstLower) {
      field.icon = DetailIcons.arrowDown;
      return 'text-danger ';
    } else if (field.isFirstHigher) {
      field.icon = DetailIcons.arrowUp;
      return 'text-success';
    } else {
      return 'text-dark';
    }
  } else if (field.isFirstHigher) {
    field.icon = DetailIcons.danger;
    return 'text-danger';
  }
};

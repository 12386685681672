import { StaffExtendedDto } from '@api/client';
import { useEffect, useRef, useState } from 'react';
import { TableProjectKeys } from 'enums/project.enum';

const useSynchronizedScroll = (
  fullAllocationsShown: Partial<StaffExtendedDto>[] | null | undefined,
  years: (number | undefined)[],
  table: TableProjectKeys
) => {
  const teamTableRef = useRef<HTMLTableElement>(null);
  const totalsTableRef = useRef<HTMLDivElement>(null);
  const otherCostsTableRef = useRef<HTMLTableElement>(null);
  const [parentScrollLeft, setParentScrollLeft] = useState<number>(0);

  const handleParentScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (totalsTableRef.current) {
      totalsTableRef.current.scrollLeft = target.scrollLeft;
    }
  };

  const handleChildScroll = (totalsScrollLeft: number) => {
    if (teamTableRef.current) {
      teamTableRef.current.scrollLeft = totalsScrollLeft;
    }
    if (otherCostsTableRef.current) {
      otherCostsTableRef.current.scrollLeft = totalsScrollLeft;
    }
    if (totalsTableRef.current) {
      totalsTableRef.current.scrollLeft = totalsScrollLeft;
    }
  };

  useEffect(() => {
    const monthsWorked = fullAllocationsShown?.[0].allocazioni?.filter((el) => el.editEnable === false).length ?? 0;
    scrollToRight(monthsWorked * 100);
  }, [years, table]);

  const scrollToRight = (px: number) => {
    if (teamTableRef.current) {
      teamTableRef.current.scrollBy({
        top: 0,
        left: px,
        behavior: 'smooth',
      });
      setParentScrollLeft(px);
    }
  };

  return {
    teamTableRef,
    totalsTableRef,
    otherCostsTableRef,
    parentScrollLeft,
    handleParentScroll,
    handleChildScroll,
  };
};

export default useSynchronizedScroll;

import { CostTypeDto, DeleteCostsRequest, ExternalCostDto, StaffExtendedDto } from '@api/client';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'core/store/hooks';
import { deleteOtherCost } from '../../_redux/actions';

const useCostsManagement = (
  otherCostsTableData: ExternalCostDto[] | null | undefined,
  setOtherCostsTableData: React.Dispatch<React.SetStateAction<ExternalCostDto[] | null | undefined>>,
  filterAllocationsByYear: (year: number | 'all', allocations: ExternalCostDto[] | null | undefined) => void,
  currentYear: number | 'all',
  setTotalOtherCosts: React.Dispatch<React.SetStateAction<number[]>>,
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>,
  costList: CostTypeDto[] | null,
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined,
  dateRange: number[],
  setYears: React.Dispatch<React.SetStateAction<Array<number | undefined>>>
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleChangeCost = (e: any, index: number) => {
    const targetCost = costList?.find((el) => el.id === e);
    if (isPermittedChange(targetCost)) {
      const costTypeCountMap = new Map<number | undefined, number>();
      const newAllocations = otherCostsTableData?.map((el, i) => {
        let groupId;
        if (i === index) {
          groupId = (costTypeCountMap.get(targetCost?.id) || 0) + 1;
          costTypeCountMap.set(targetCost?.id, groupId);
        } else {
          const currentCostType = el.costType?.id;
          groupId = (costTypeCountMap.get(currentCostType) || 0) + 1;
          costTypeCountMap.set(currentCostType, groupId);
        }

        return {
          ...el,
          groupId: groupId,
          costType: i === index ? targetCost : el.costType,
        };
      });

      setOtherCostsTableData(newAllocations);
      filterAllocationsByYear(currentYear, newAllocations);
      setDraftValidation(false);
    } else {
      AppToastService.error(t('project-detail.non-duplicable-cost'));
    }
  };

  const handleChangeAllocation = (value: number | null, id: number | undefined) => {
    const newAllocations = otherCostsTableData?.map((el) => ({
      ...el,
      costAllocation: el.costAllocation?.map((el) => ({
        ...el,
        actualCost: el.id == id ? Number(value) : el.actualCost,
      })),
    }));
    setOtherCostsTableData(newAllocations);
    filterAllocationsByYear(currentYear, newAllocations);
    setDraftValidation(false);
  };

  const handleChangeDescription = (value: string | null, index: number) => {
    const newAllocations = otherCostsTableData?.map((el, i) => ({
      ...el,
      description: i === index ? value ?? '' : el.description,
    }));
    setOtherCostsTableData(newAllocations);
    filterAllocationsByYear(currentYear, newAllocations);
    setDraftValidation(false);
  };

  const handleChangeTotalCosts = (value: number | null, index: number) => {
    setTotalOtherCosts((prev) => {
      const newTotalCosts = [...prev];
      newTotalCosts[index] = value ?? 0;
      return newTotalCosts;
    });
  };

  const addCost = () => {
    let currentId = -Math.floor(Math.random() * 100000);
    const newAllocations = [
      ...(otherCostsTableData ?? []),
      {
        costType: {
          id: currentId,
          description: undefined,
          value: undefined,
        },
        description: undefined,
        groupId: 1,
        costAllocation: createAllocationsForNewCost(currentId),
      },
    ];
    setOtherCostsTableData(newAllocations);
    filterAllocationsByYear(currentYear, newAllocations);
    setDraftValidation(false);
  };

  const createAllocationsForNewCost = (currentId: number) => {
    if (otherCostsTableData?.length) {
      return otherCostsTableData?.[0]?.costAllocation?.map((el) => ({
        id: currentId--,
        mese: el.mese,
        anno: el.anno,
        actualCost: 0,
        editEnabled: el.editEnabled,
      }));
    }
    return fullAllocations?.[0]?.allocazioni?.map((el) => ({
      id: currentId--,
      mese: el.month,
      anno: el.year,
      actualCost: 0,
      editEnabled: el.editEnable,
    }));
  };

  const addMonth = () => {
    let currentId = -Math.floor(Math.random() * 100000);
    const lastAllocation = otherCostsTableData?.[0]?.costAllocation?.slice(-1)?.[0];
    let nextMonth = lastAllocation?.mese ? (lastAllocation.mese % 12) + 1 : 1;
    let nextYear = lastAllocation?.anno;
    if (nextMonth === 1 && lastAllocation?.mese === 12 && nextYear) {
      nextYear++;
    }
    const newAllocations = otherCostsTableData?.map((el) => ({
      ...el,
      costAllocation: [
        ...(el.costAllocation ?? []),
        {
          id: currentId--,
          mese: nextMonth,
          anno: nextYear,
          actualCost: 0,
          editEnabled: true,
        },
      ],
    }));
    setOtherCostsTableData(newAllocations);
    filterAllocationsByYear(currentYear, newAllocations);
    setDraftValidation(false);
  };
  const disableAllocatedMonths = (current: { year: () => number; month: () => number }) => {
    if (!current || !fullAllocations) {
      return false;
    }
    const year = current.year();
    const month = current.month() + 1;
    return (
      otherCostsTableData?.some((externalCost) =>
        externalCost.costAllocation?.some(
          (alloc) =>
            (alloc.anno! > year || (alloc.anno === year && alloc.mese! >= month)) && alloc.editEnabled === false
        )
      ) ?? false
    );
  };

  const addMonths = () => {
    if (dateRange.length === 0) return;
    const startMonth = dateRange[0];
    const startYear = dateRange[1];
    const endMonth = dateRange[2];
    const endYear = dateRange[3];
    const newCostAllcoations = otherCostsTableData?.map((el) => {
      const newMonths = [];
      for (let year = startYear; year <= endYear; year++) {
        for (let month = year === startYear ? startMonth : 1; month <= (year === endYear ? endMonth : 12); month++) {
          if (!el.costAllocation?.some((alloc) => alloc.anno === year && alloc.mese === month)) {
            newMonths.push({
              id: -Math.floor(Math.random() * 100000),
              mese: month,
              anno: year,
              actualCost: 0,
              editEnabled: true,
            });
          }
        }
      }
      return {
        ...el,
        costAllocation: [...(el.costAllocation ?? []), ...newMonths],
      };
    });

    newCostAllcoations?.forEach((el) => {
      el.costAllocation?.sort((a, b) => {
        if (a.anno !== b.anno) {
          return (a.anno ?? 0) - (b.anno ?? 0);
        }
        return (a.mese ?? 0) - (b.mese ?? 0);
      });
    });
    setOtherCostsTableData(newCostAllcoations);
    filterAllocationsByYear(currentYear, newCostAllcoations);
    setDraftValidation(false);
    setYears((prevYears) => {
      const updatedYears = [...prevYears];
      for (let year = startYear; year <= endYear; year++) {
        if (!updatedYears.includes(year)) {
          updatedYears.push(year);
        }
      }
      return updatedYears;
    });
  };

  const deleteCost = (index: number, projectId: number | undefined) => {
    const row = otherCostsTableData?.[index];
    if (row && row.costAllocation?.filter((cost) => !cost.editEnabled).every((el) => !el.actualCost)) {
      if (row.costAllocation?.every((el) => el.id && el.id < 0)) {
        const newCostData = otherCostsTableData?.filter((_, i) => i !== index) || [];
        setOtherCostsTableData(newCostData);
        filterAllocationsByYear(currentYear, newCostData);
        setDraftValidation(false);
      } else {
        if (!projectId) return;
        const request: DeleteCostsRequest = {
          externalCostRowDto: [
            {
              groupId: row.groupId,
              costTypeId: row.costType?.id,
              projectId,
            },
          ],
        };
        dispatch(deleteOtherCost(request)).then((response) => {
          if (response) {
            const newCostData = otherCostsTableData?.filter((_, i) => i !== index) || [];
            setOtherCostsTableData(newCostData);
            filterAllocationsByYear(currentYear, newCostData);
          }
        });
      }
    }
  };

  const deleteMonth = () => {
    const newCostData = otherCostsTableData?.map((el) => ({
      ...el,
      costAllocation: el.costAllocation?.slice(0, -1),
    }));
    setOtherCostsTableData(newCostData);
    filterAllocationsByYear(currentYear, newCostData);
    setDraftValidation(false);
  };

  const isPermittedChange = (targetCost: CostTypeDto | undefined) => {
    if (targetCost?.duplicable) {
      return true;
    }
    return !otherCostsTableData?.some((el) => el.costType?.id === targetCost?.id);
  };

  const isNonDuplicableCostTypePresent = (costId: number | undefined, duplicable: boolean | undefined) => {
    return !duplicable && otherCostsTableData?.some((el) => el.costType?.id === costId);
  };

  return {
    handleChangeCost,
    handleChangeDescription,
    handleChangeAllocation,
    handleChangeTotalCosts,
    addCost,
    deleteMonth,
    addMonth,
    addMonths,
    deleteCost,
    disableAllocatedMonths,
    isNonDuplicableCostTypePresent,
  };
};

export default useCostsManagement;

// Services
export const BASE_PATH_PROD = `/planning-tool`;
export const BASE_URL_SVIL = `https://topstar-api-npe.azurewebsites.net/planning-tool`;
export const MOCK_TOKEN =
  'eyJhbGciOiJIUzI1NiJ9.eyJOQU1FIjoiQm9uYXRlc3RhIEdpb2VsZSIsIlJPTEUiOiJTVVBFUl9BRE1JTiIsIkVNQUlMIjoiZ2lvZWxlLmJvbmF0ZXN0YUBsaW5rc210Lml0IiwiTEFOR1VBR0UiOiJJVCIsIlVTRVJJRCI6MTQxNiwic3ViIjoiZ2lvZWxlLmJvbmF0ZXN0YUBsaW5rc210Lml0IiwianRpIjoiZTk3YzdmYTItNDEyNC00ZGEzLWI5NzctNDViZDg5ZDlhOGNhIiwiaWF0IjoxNzMxMDYzOTMzLCJleHAiOjQxMDI0NDExOTl9.vTcDqnHSCuClTDsVKzHaPai5nOa2bUV6yK0KvLOvNMc';
// URLS
export const HOSTNAME_PRODUCTION = 'tops.linksmt.it';
export const HOSTNAME_WEB_SVIL = 'topstar-web-npe.azurewebsites.net';
export const URL_MICROSOFT_LOGIN = 'https://tops.linksmt.it/auth';
export const URL_MICROSOFT_LOGIN_SVIL = 'https://tops.linksmt.it/auth/npe';
// EXTERNAL_URLS
export const HELP_URL =
  'https://linksmtit.sharepoint.com/sites/PeopleOperations/SitePages/Processo-Pianificazione-e-monitoraggio-dei-progetti.aspx?promotedState=0&Mode=Edit';

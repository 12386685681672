import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ContractOrderSFSearchInputDto } from '@api/client';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { filtersOrdersSfUsedSelector } from 'pages/private/active-cycle/active-cycle-order-list/_redux/selectors';
import {
  saveFiltersOrdersSfUsed,
  searchActiveOrdersSf,
} from 'pages/private/active-cycle/active-cycle-order-list/_redux/actions';

const useFormActiveCycle = (initialData: any, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const filterUsed = useAppSelector(filtersOrdersSfUsedSelector);
  // const filters = useAppSelector(filtriProgettiSelector);
  const [chipsFormData, setChipsFormData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);
  const { handleSubmit, control, register, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  const onSubmit = (data: any) => {
    isSubmitting.current = true;
    const formDataArray = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormData([...formDataArray]);

    const filterDto: ContractOrderSFSearchInputDto = {};

    if (data.ordNumero) {
      filterDto.ordNumero = data.ordNumero;
    }
    if (data.orderId) {
      filterDto.orderId = data.orderId;
    }
    if (data.startEndRange && data.startEndRange.length > 0) {
      filterDto.startDate = new Date(data.startEndRange[0]);
      filterDto.endDate = new Date(data.startEndRange[1]);
    }
    if (data.orderName) {
      filterDto.object = data.orderName;
    }
    if (data.clienteId) {
      filterDto.clienteId = data.clienteId;
    }
    if (data.aziendaId) {
      filterDto.aziendaId = data.aziendaId;
    }
    if (data.businessManagerId) {
      filterDto.businessManagerId = data.businessManagerId;
    }
    if (data.stato) {
      filterDto.stato = data.stato;
    }
    if (data.ordCig) {
      filterDto.ordCig = data.ordCig;
    }
    if (data.ordCup) {
      filterDto.ordCup = data.ordCup;
    }
    if (data.ordSdi) {
      filterDto.ordSdi = data.ordSdi;
    }
    if (data.ordOggetto) {
      filterDto.ordOggetto = data.ordOggetto;
    }
    if (data.ordPoNumber) {
      filterDto.ordPoNumber = data.ordPoNumber;
    }
    if (data.dataPo) {
      filterDto.dataPo = data.dataPo;
    }
    if (data.ordTermGG) {
      filterDto.ordTermGG = data.ordTermGG;
    }
    if (data.denominazioneCliente) {
      filterDto.denominazioneCliente = data.denominazioneCliente;
    }
    if (data.projectId) {
      filterDto.projectId = Number(data.projectId);
    }
    if (data.sortByInsertionDate) {
      filterDto.sortedDate = data.sortByInsertionDate;
    }
    filterDto.paging = { page: 0, size: 20 };
    dispatch(searchActiveOrdersSf({ contractOrderSFSearchInputDto: filterDto }))
      .unwrap()
      .then((res: any) => {});
    handleSubmit(data);
    dispatch(saveFiltersOrdersSfUsed(filterDto));
    setIsOpen(false);
  };

  const onReset = () => {
    reset(initialData);
    dispatch(saveFiltersOrdersSfUsed(initialData));
    setChipsFormData([]);
  };

  return { onSubmit, onReset, filterParams, chipsFormData, setChipsFormData, reset, formState };
};

export default useFormActiveCycle;

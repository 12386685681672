import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DatePicker, Input, Select, SelectProps, Switch, Tag } from 'antd';
import dayjs from 'dayjs';
import 'shared/design-system/components/app-accordion/style.scss';
import { t } from 'i18next';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { filtersOrdersUsedSelector } from '../_redux/selectors';
import { saveFiltersOrdersUsed } from '../_redux/actions';
import { MappedOrderOptions } from 'enums/orderOptions';
import LinksButton from 'shared/design-system/components/links-button';
import { filterOption } from 'shared/utils/common.utils';

export const orderListFormData: any = {
  contractStatus: [],
  orderProcessingStatus: [],
  startEndRange: [],
  signatureRange: [],
  suppliers: [],
  orderId: undefined,
  automaticRenewal: undefined,
  expenseItems: [],
  projectId: undefined,
  withoutExternalCosts: undefined,
  object: undefined,
  categories: [],
  legalEntities: [],
};

interface Props {
  onSubmit: any;
  handleReset: () => void;
  // setIsOpen: (isOpen: boolean) => any;
  // isOpen: any;
  setControlledDate: any;
  mappedPassiveCycleOptions: MappedOrderOptions | undefined;
}
const FiltersOrders: React.FC<Props> = ({ onSubmit, handleReset, setControlledDate, mappedPassiveCycleOptions }) => {
  const filterUsed = useAppSelector(filtersOrdersUsedSelector);
  const dispatch = useAppDispatch();
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: orderListFormData,
    values: filterUsed,
  });

  const Reset = () => {
    reset();
    handleReset();
    setControlledDate([]);
    clearPriceRange();
  };

  const [clearPR, setClearPR] = useState(false);
  const clearPriceRange = () => {
    setClearPR(!clearPR);
  };
  const { RangePicker } = DatePicker;
  const handleFormSubmit = async (formData: any) => {
    dispatch(saveFiltersOrdersUsed(formData));
    try {
      await onSubmit(formData);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Col sm="3">
          {' '}
          <Form.Label>{t('Contract status')}</Form.Label>
          <Controller
            name="contractStatus"
            control={control}
            render={({ field }) => (
              <Select
                placeholder={t('Select contract status')}
                mode="multiple"
                size="large"
                allowClear
                tagRender={tagRender}
                optionFilterProp="children"
                filterOption={filterOption}
                defaultValue={[]}
                className="w-100"
                {...field}
              >
                {mappedPassiveCycleOptions?.mappedOrderContractStatusEnumList?.map((el, index) => (
                  <Select.Option key={index} value={el.value}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col sm="3">
          {' '}
          <Form.Label>{t('Order processing status')}</Form.Label>
          <Controller
            name="orderProcessingStatus"
            control={control}
            render={({ field }) => (
              <Select
                placeholder={t('Select order processing status')}
                mode="multiple"
                size="large"
                allowClear
                tagRender={tagRender}
                optionFilterProp="children"
                filterOption={filterOption}
                defaultValue={[]}
                className="w-100"
                {...field}
              >
                {mappedPassiveCycleOptions?.mappedOrderProcessingStatusEnumList?.map((el, index) => (
                  <Select.Option key={index} value={el.value}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col sm="3">
          {' '}
          <Form.Label>{t('Expenses')}</Form.Label>
          <Controller
            name="expenseItems"
            control={control}
            render={({ field }) => (
              <Select
                placeholder={t('Select expenses')}
                mode="multiple"
                size="large"
                allowClear
                tagRender={tagRender}
                defaultValue={[]}
                className="w-100"
                options={mappedPassiveCycleOptions?.mappedExpenseItemEnumList}
                {...field}
              />
            )}
          />
        </Col>
        <Col sm="3">
          {' '}
          <Form.Label>{t('Suppliers')}</Form.Label>
          <Controller
            name="suppliers"
            control={control}
            render={({ field }) => (
              <Select
                placeholder={t('Select suppliers')}
                mode="multiple"
                size="large"
                allowClear
                tagRender={tagRender}
                defaultValue={[]}
                optionFilterProp="children"
                filterOption={filterOption}
                className="w-100"
                {...field}
              >
                {mappedPassiveCycleOptions?.fornitoriList?.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.denominazione}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
      </Row>
      <Form.Group className="grid mt-3">
        <Row>
          <Col sm="3">
            <Form.Label>{t('ID Order')}</Form.Label>
            <Controller
              name="orderId"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search Order ID')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('project-list.id-project')}</Form.Label>
            <Controller
              name="projectId"
              control={control}
              render={({ field }) => (
                <Input
                  type="number"
                  placeholder={t('project-list.id-project-placeholder')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            {' '}
            <Form.Label>{t('Start/End date')}</Form.Label>
            <Controller
              name="startEndRange"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <RangePicker
                  placeholder={[
                    `${t('project-list.start-date-placeholder')}`,
                    `${t('project-list.end-date-placeholder')}`,
                  ]}
                  className="w-100 createDateRangePicker"
                  popupClassName="createDateRangePicker"
                  {...field}
                  ref={field.ref}
                  name={field.name}
                  onBlur={field.onBlur}
                  format="DD/MM/YYYY"
                  value={
                    field.value && field.value.length === 2 ? [dayjs(field.value[0]), dayjs(field.value[1])] : null
                  }
                  size="large"
                  onChange={(dates: any) => {
                    field.onChange(dates ? dates.map((date: any) => date.format('YYYY-MM-DD')) : []);
                  }}
                />
              )}
            />
          </Col>
          <Col sm="3">
            {' '}
            <Form.Label>{t('Signature date')}</Form.Label>
            <Controller
              name="signatureRange"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <RangePicker
                  placeholder={[
                    `${t('project-list.start-date-placeholder')}`,
                    `${t('project-list.end-date-placeholder')}`,
                  ]}
                  className="w-100 createDateRangePicker"
                  popupClassName="createDateRangePicker"
                  {...field}
                  ref={field.ref}
                  name={field.name}
                  onBlur={field.onBlur}
                  format="DD/MM/YYYY"
                  value={
                    field.value && field.value.length === 2 ? [dayjs(field.value[0]), dayjs(field.value[1])] : null
                  }
                  size="large"
                  onChange={(dates: any) => {
                    field.onChange(dates ? dates.map((date: any) => date.format('YYYY-MM-DD')) : []);
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="3">
            <Form.Label>{t('Object')}</Form.Label>
            <Controller
              name="object"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t('Search object')}
                  size="large"
                  allowClear
                  className="w-100"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col sm="3">
            {' '}
            <Form.Label>{t('Category')}</Form.Label>
            <Controller
              name="categories"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder={t('Select categories')}
                  mode="multiple"
                  size="large"
                  allowClear
                  tagRender={tagRender}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  defaultValue={[]}
                  className="w-100"
                  {...field}
                >
                  {mappedPassiveCycleOptions?.orderCategoryList?.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.value}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
          <Col sm="3">
            {' '}
            <Form.Label>{t('Legal entity')}</Form.Label>
            <Controller
              name="legalEntities"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder={t('Select legal entities')}
                  mode="multiple"
                  size="large"
                  allowClear
                  tagRender={tagRender}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  defaultValue={[]}
                  className="w-100"
                  {...field}
                >
                  {mappedPassiveCycleOptions?.aziendaList?.map((entity) => (
                    <Select.Option key={entity.id} value={entity.id}>
                      {entity.nome}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="3">
            <Form.Label>{t('Automatic renewal')}</Form.Label>
            <div>
              <Controller
                name="automaticRenewal"
                control={control}
                render={({ field }: any) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(checked) => field.onChange(checked)}
                    className="mt-2"
                  />
                )}
              />
            </div>
          </Col>
          <Col sm="3">
            <Form.Label>{t('Without external costs')}</Form.Label>
            <div>
              <Controller
                name="withoutExternalCosts"
                control={control}
                render={({ field }: any) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(checked) => field.onChange(checked)}
                    className="mt-2"
                  />
                )}
              />
            </div>
          </Col>
        </Row>
      </Form.Group>

      <div className="d-flex">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};
export default FiltersOrders;

type TagRender = SelectProps['tagRender'];

const tagRender: TagRender = (props) => {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={'blue'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};

import { Navigate, Outlet } from 'react-router-dom';
import AuthService from '../auth/auth.service';
import { PathEnum } from '../router/path.enum';
import { HOSTNAME_PRODUCTION, HOSTNAME_WEB_SVIL } from 'env';

const AuthGuard: React.FC = () => {
  const isAuthenticate = AuthService.isAuthenticated();

  return !!isAuthenticate ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        window.location.hostname !== HOSTNAME_PRODUCTION && window.location.hostname !== HOSTNAME_WEB_SVIL
          ? `/${PathEnum.AUTH}`
          : `/${PathEnum.AUTHSSO}`
      }
      replace
    />
  );
};
export default AuthGuard;

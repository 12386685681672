import { ProjectCicloPassivoOutputDto } from '@api/client';
import { useAppDispatch } from 'core/store/hooks';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { associateOrderProject, searchActiveOrders } from '../../../_redux/actions';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { formatCurrency } from 'shared/utils/common.utils';
import { StatusBadgeData } from 'enums/status';

interface Props {
  projects: ProjectCicloPassivoOutputDto[];
  orderId: string | undefined;
}

const AssociableProjectsTable: React.FC<Props> = ({ projects, orderId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleAssociateProject = (project: ProjectCicloPassivoOutputDto) => {
    const { projectId, intranetId, projectName } = project;
    if (!projectId || !orderId) return;
    dispatch(associateOrderProject({ associateActiveOrderProjectDto: { orderId, projectId } }))
      .unwrap()
      .then((res) => {
        if (res?.projectId) {
          AppToastService.success(t(`Project (${intranetId}) ${projectName} associated successfully`));
          dispatch(searchActiveOrders({ contractOrderSearchDto: { cicloAttivoOrderNumber: orderId } }));
        }
      });
  };

  if (projects.length) {
    return (
      <div className="table-responsive table-bordered mt-3">
        <Table className={'mt-0 mb-0'}>
          <thead className="bg-red">
            <tr>
              <th scope="col">
                <span>{'Project'}</span>
              </th>
              <th scope="col">
                <span>{'Tot. Amount'}</span>
              </th>
              <th scope="col">
                <span>{'Status'}</span>
              </th>
              <th scope="col">
                <span>{'CL/AM'}</span>
              </th>
              <th scope="col">
                <span>{'PL'}</span>
              </th>
              <th scope="col">
                <span>{'Account'}</span>
              </th>
              <th scope="col" style={{ maxWidth: '50px' }}>
                <span>{'Action'}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.projectId}>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{`(${project.intranetId}) ${project.projectName}`}</p>
                  </span>
                </td>
                <td className="text-end">
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">
                      {project.importo !== undefined ? `${formatCurrency(project.importo)}€` : '-'}
                    </p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">
                      {StatusBadgeData.find((x) => x.name === project.stato)?.label || project.stato}
                    </p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{project.businessManager}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{project.projectManager}</p>
                  </span>
                </td>
                <td>{project.nomeCliente}</td>
                <td className="text-center">
                  <Button
                    className="btn text-blue-links p-0"
                    type="button"
                    variant="icon"
                    onClick={() => handleAssociateProject(project)}
                  >
                    <i className="fas fa-plus-circle text-blue-links" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '4rem' }}>
        {t('No projects found')}
      </div>
    );
  }
};

export default AssociableProjectsTable;

import { useTranslation } from 'react-i18next';
import useGetAssets from './_hooks/useGetAssets';
import { Table } from 'react-bootstrap';
import { Divider, Input, InputNumber, InputRef, Select, Space, Tooltip } from 'antd';
import NoData from 'shared/design-system/components/no-data-box';
import useGetProjectAssets from './_hooks/useGetProjectAssets';
import useDeleteProjectAsset from './_hooks/useDeleteProjectAsset';
import LinksButton from 'shared/design-system/components/links-button';
import useAddProjectAsset from './_hooks/useAddProjectAsset';
import { formatCurrency } from 'shared/utils/common.utils';
import useHandleAssetEdit from './_hooks/useHandleAssetEdit';
import { useRef, useState } from 'react';
import useSaveProjectAsset from './_hooks/useSaveProjectAsset';
import ModalAction from '../modal-action';

interface Props {
  projectId: number | undefined;
}

const ProductsAndSubscriptions: React.FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const [showConfirmDeleteProjectAssetModal, setShowConfirmDeleteProjectAssetModal] = useState<boolean>(false);
  const { projectAssets, setProjectAssets, getProjectAssets } = useGetProjectAssets(projectId);
  const { assets, setAssets, getAllAssets } = useGetAssets();
  const { addProjectAsset } = useAddProjectAsset(projectId!, setProjectAssets);
  const { handleSelectChange, handleChangeImporto } = useHandleAssetEdit(assets, setProjectAssets);
  const { deleteAssetFromProject, setIdToDelete } = useDeleteProjectAsset(getProjectAssets);
  const { handleSaveProjectAssets } = useSaveProjectAsset(projectAssets, projectId, getProjectAssets);

  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setAssets([...assets, { id: -Math.floor(Math.random() * 100000), title: name }]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const showModalDeleteOrder = (id: number) => {
    setIdToDelete(id);
    setShowConfirmDeleteProjectAssetModal(true);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <LinksButton className='btn-sm btn btn-links-outline px-3"' onClick={addProjectAsset}>
          <div className="d-flex align-items-center">
            <i className="fas fa-plus me-2" />
            {t('Add asset')}
          </div>
        </LinksButton>
        <LinksButton className='btn-sm btn btn-links px-3"' onClick={handleSaveProjectAssets}>
          <div className="d-flex align-items-center">
            <i className="icon-save me-2" />
            {t('Save')}
          </div>
        </LinksButton>
      </div>
      <div>
        {projectAssets?.length ? (
          <div style={{ overflowY: 'auto', height: 'calc(100vh - 315px)' }}>
            <Table bordered>
              <thead>
                <tr>
                  <th>{t('Asset')}</th>
                  <th>{t('Amount')}</th>
                  <th className="col-2">{t('Cost')}</th>
                  <th className="col-1 text-center">{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {projectAssets?.map((projectAsset, index) => (
                  <tr key={`project_asset_${projectAsset.idElencoAsset}_${index}`}>
                    <td style={{ width: '200px' }}>
                      <Select
                        className="w-100"
                        allowClear
                        placeholder={t('project-detail.billing-plan.placeholders.select-block')}
                        value={projectAsset.idElencoAsset}
                        placement="bottomLeft"
                        options={assets.map((item) => ({ label: item.title, value: item.id }))}
                        onChange={(e) => handleSelectChange(e, index)}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                              <Input
                                placeholder="Enter new asset"
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                                onKeyDown={(e) => e.stopPropagation()}
                              />
                              <LinksButton className='btn-sm btn btn-links px-3"' onClick={addItem}>
                                Add asset
                              </LinksButton>
                            </Space>
                          </>
                        )}
                      />
                    </td>
                    <td style={{ width: '150px' }}>
                      <InputNumber
                        type="number"
                        className="w-100"
                        placeholder={t('project-detail.billing-plan.placeholders.insert-amount')}
                        value={projectAsset.importo}
                        disabled={!projectAsset.idElencoAsset}
                        onChange={(e) => handleChangeImporto(e, projectAsset.idElencoAsset)}
                        precision={2}
                      />
                    </td>
                    <td className="text-end">{formatCurrency(projectAsset.costo)}</td>
                    <td className="text-center">
                      <Tooltip placement="top" color="white" title={t('Delete')}>
                        <button
                          onClick={() => {
                            showModalDeleteOrder(projectAsset.id!);
                          }}
                          className="btn-icon"
                        >
                          <i className={`icon-delete text-danger me-1`} />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoData msg={t('No asset found')} />
          </div>
        )}
      </div>
      <ModalAction
        title={t('Delete asset')}
        actionLabel={t('common.confirm')}
        cancelLabel={t('common.cancel')}
        action={() => {
          deleteAssetFromProject();
        }}
        setShow={(value) => setShowConfirmDeleteProjectAssetModal(value)}
        show={showConfirmDeleteProjectAssetModal}
      >
        <span className="text-danger">
          <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
        </span>
        <div className="p-1">
          <p>{t('Are you sure you want to detele the asset?')}</p>
        </div>
      </ModalAction>
    </>
  );
};

export default ProductsAndSubscriptions;

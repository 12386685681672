import { useEffect, useState } from 'react';
import { ProjectCdgData, RevenueAndCostsData } from './useGetAndMapManagementToolProjects';

const useGetExportableExcelData = (
  projects: Partial<ProjectCdgData>[],
  revenueAndCostsData: Partial<RevenueAndCostsData>[]
) => {
  const [excelData, setExcelData] = useState<any[]>([]);

  useEffect(() => {
    getExportableExcelData();
  }, [projects]);

  const getExportableExcelData = () => {
    const mappedProjects = projects.map((item) => {
      return {
        [`${item.id?.label}`]: item.id?.value,
        [`${item.name?.label}`]: item.name?.value,
        [`${item.clientLead?.label}`]: item.clientLead?.value,
        [`${item.projectLead?.label}`]: item.projectLead?.value,
      };
    });
    const mappedProjectsWithRevenueAndCosts = mappedProjects.map((item, index) => {
      return {
        ...item,
        [`${revenueAndCostsData[index].actualRevenue?.label} ${revenueAndCostsData[index].actualRevenue?.month}`]:
          revenueAndCostsData[index].actualRevenue?.value,
        [`${revenueAndCostsData[index].actualOtherCosts?.label} ${revenueAndCostsData[index].actualOtherCosts?.month}`]:
          revenueAndCostsData[index].actualOtherCosts?.value,
        [`${revenueAndCostsData[index].actualHrCosts?.label} ${revenueAndCostsData[index].actualHrCosts?.month}`]:
          revenueAndCostsData[index].actualHrCosts?.value,
      };
    });
    setExcelData(mappedProjectsWithRevenueAndCosts);
  };
  return { excelData };
};

export default useGetExportableExcelData;

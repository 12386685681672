import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import {
  Configuration,
  ControllerUserApi,
  DeleteDesignatedPersonForProjectRequest,
  DeleteDesignatedPersonForUserRequest,
  DesignatedPersonForProjectListRequest,
  InsertDesignatedPersonForProjectRequest,
  InsertDesignatedPersonForUserRequest,
  LoggedUserDto,
  SearchUserResponseDto,
} from '@api/client';
import getConfiguration, { errorHandler } from 'core/store/config';

enum UserActions {
  getUserInfo = '[User] Get User Info',
  getDelegatesProject = '[User] Get Delegates Project',
  insertDelegateProject = '[User] Insert Delegate Project',
  deleteDelegateProject = '[User] Delete Delegate Project',
  getDelegatesHcc = '[User] Get Delegates Hcc',
  insertDelegateHcc = '[User] Insert Delegate Hcc',
  deleteDelegateHcc = '[User] Delete Delegate Hcc',
}

export const getUserInfo = createAsyncThunk<LoggedUserDto | undefined, void, ThunkApiConfig>(
  UserActions.getUserInfo,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerUserApi(getConfiguration(Configuration, extra, getState)).userInfo();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getDelegatesProject = createAsyncThunk<
  SearchUserResponseDto | undefined,
  DesignatedPersonForProjectListRequest,
  ThunkApiConfig
>(UserActions.getDelegatesProject, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerUserApi(getConfiguration(Configuration, extra, getState)).designatedPersonForProjectList(
      payload
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const insertDelegateProject = createAsyncThunk<
  string | undefined,
  InsertDesignatedPersonForProjectRequest,
  ThunkApiConfig
>(UserActions.insertDelegateProject, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerUserApi(
      getConfiguration(Configuration, extra, getState)
    ).insertDesignatedPersonForProject(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const deleteDelegateProject = createAsyncThunk<
  SearchUserResponseDto | undefined,
  DeleteDesignatedPersonForProjectRequest,
  ThunkApiConfig
>(UserActions.deleteDelegateProject, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerUserApi(
      getConfiguration(Configuration, extra, getState)
    ).deleteDesignatedPersonForProject(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getDelegatesHcc = createAsyncThunk<SearchUserResponseDto | undefined, void, ThunkApiConfig>(
  UserActions.getDelegatesHcc,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerUserApi(
        getConfiguration(Configuration, extra, getState)
      ).designatedPersonForUserList();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const insertDelegateHcc = createAsyncThunk<
  string | undefined,
  InsertDesignatedPersonForUserRequest,
  ThunkApiConfig
>(UserActions.insertDelegateHcc, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerUserApi(getConfiguration(Configuration, extra, getState)).insertDesignatedPersonForUser(
      payload
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const deleteDelegateHcc = createAsyncThunk<
  SearchUserResponseDto | undefined,
  DeleteDesignatedPersonForUserRequest,
  ThunkApiConfig
>(UserActions.deleteDelegateHcc, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerUserApi(getConfiguration(Configuration, extra, getState)).deleteDesignatedPersonForUser(
      payload
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { InsertDateAndRoleRequest, DeleteStaffRequest, DettaglioProjectDto, UpdateCostsRequest } from '@api/client';
import {
  closeRendicontazione,
  deleteStaff,
  insertStaff,
  replanning,
  sendPlanning,
  syncStaffIntranet,
  updateStaff,
} from '../../_redux/actions';
import React, { useEffect } from 'react';
import { trackButtonClickGa4 } from 'shared/utils/gtag';
import { costsDataSelector, revenueProgressSelector, teamDataSelector } from '../../_redux/selectors';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { useTranslation } from 'react-i18next';

const useProjectActions = (
  projectDetails: DettaglioProjectDto,
  setProjectDetails: React.Dispatch<React.SetStateAction<DettaglioProjectDto | null>>,
  id: number,
  ruoliTeam: any[],
  dateRange: [Date, Date] | null,
  handleCloseModal: () => void,
  getProjectDetail: () => void,
  isPlanning: boolean,
  costDescriptionValidation: boolean,
  associatedCostsValidation: boolean,
  revenueProgressValidation: boolean
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const fullAllocations = useAppSelector(teamDataSelector);
  const costsData = useAppSelector(costsDataSelector);
  const revenueProgress = useAppSelector(revenueProgressSelector);

  const createTeam = () => {
    if (!id || !dateRange) return;
    const request: InsertDateAndRoleRequest = {
      idProject: Number(id),
      insertByRolesDto: {
        inizio: dateRange[0],
        fine: dateRange[1],
        ruoliTeam: ruoliTeam.map((ruolo: any) => ({
          idPps: ruolo.idPps,
          descrizioneProfiloProfessionale: ruolo.descrizionePps,
          seniorityPpt: {
            idCostoProfilo: ruolo.seniority?.ppt,
            descrizioneCostoProfilo: ruolo.seniority?.descrizionePpt,
            costo: ruolo.seniority?.costo,
            ricavo: ruolo.seniority?.ricavo,
          },
          giorniUomo: ruolo.giorniUomo,
          numPersone: ruolo.numPersone,
        })),
      },
    };

    dispatch(insertStaff(request)).then((res) => {
      if (res?.meta?.requestStatus === 'fulfilled') {
        getProjectDetail();
        handleCloseModal();
      }
    });
  };

  const consolidaPlanning = () => {
    if (!id) return;

    const actionType = isPlanning ? 'send' : 'save';
    const consolidaAction = isPlanning ? sendPlanning : replanning;
    trackButtonClickGa4(`${actionType}_schedule_button`);

    dispatch(consolidaAction(Number(id)))
      .unwrap()
      .then((res) => {
        if (res) {
          getProjectDetail();
        }
      });
  };

  const saveAndConsolidaPlanning = () => {
    if (!id) return;
    if (
      fullAllocations?.some(
        (el) => !el.idPps && !el.idPpt && !el.userId && el.allocazioni?.some((el) => el.actualAllocation !== 0)
      )
    ) {
      AppToastService.error(t('project-detail.toast-select-profile-before-save'));
      return;
    }
    if (!costDescriptionValidation) {
      AppToastService.error(t('project-detail.error-description-other-costs-validation'));
      return;
    }
    if (!associatedCostsValidation) {
      AppToastService.error(
        t("The total amount of an other cost can't be greater than the total amount of its associated contract")
      );
      return;
    }
    if (!revenueProgressValidation) {
      AppToastService.error(t('The planned revenues have exceeded the offered amount'));
      return;
    }

    const actionType = isPlanning ? 'send' : 'save';
    const consolidaAction = isPlanning ? sendPlanning : replanning;
    trackButtonClickGa4(`${actionType}_schedule_button`);

    let request: UpdateCostsRequest = {
      idProject: id,
      updateCostsInputDto: {
        fullAllocations: fullAllocations
          ?.map((el) => ({
            idPps: Number(el.idPps),
            idPpt: Number(el.idPpt),
            userId: el.userId,
            allocazioni: el.allocazioni?.map((el) => ({
              id: el.id,
              month: el.month,
              year: el.year,
              actualAllocation: el.actualAllocation,
              sellingPrice: el.sellingPrice,
            })),
          }))
          .filter((el) => el.idPps || el.idPpt || el.userId || el.allocazioni?.some((el) => el.actualAllocation !== 0)),
        otherCosts: costsData || undefined,
        revenueDaConsolidareMonthList: revenueProgress || undefined,
      },
    };
    // console.log(request);
    dispatch(updateStaff(request))
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(consolidaAction(Number(id)))
            .unwrap()
            .then((res) => {
              if (res) {
                getProjectDetail();
              }
            });
        }
      });
  };

  const deleteStaffRole = (index: number) => {
    if (!id) return;
    const request: DeleteStaffRequest = {
      idProject: Number(id),
      deleteStaffInputDto: ruoliTeam[index].descrizionePps || '',
    };
    dispatch(deleteStaff(request)).then(() => {});
  };

  const closeRendicontazioneProject = () => {
    if (!id) return;
    dispatch(closeRendicontazione(Number(id)));
  };

  const sendPlanningProject = () => {
    if (!id) return;
    dispatch(sendPlanning(Number(id)));
  };

  const isConfermaEnabled = () => {
    return (
      ruoliTeam.every(
        (ruolo) =>
          ruolo.idPps !== null &&
          ruolo.descrizionePps !== null &&
          ruolo.seniority?.ppt !== null &&
          ruolo.seniority?.costo !== null &&
          ruolo.giorniUomo !== null &&
          ruolo.numPersone !== null
      ) && dateRange !== null
    );
  };

  const startPlanningInTable = () => {
    let currentId = -Math.floor(Math.random() * 100000);
    const newStaff = [
      {
        idPps: undefined,
        idPpt: undefined,
        userId: undefined,
        allocazioni: projectDetails.mesiProgetto?.map((el) => ({
          id: currentId--,
          month: el.mese,
          year: el.anno,
          actualAllocation: 0,
          editEnable: !el.consolidato,
        })),
      },
    ];
    setProjectDetails({
      ...projectDetails,
      staffList: newStaff,
    });
  };

  const syncStaffOnIntranet = () => {
    if (!id) return;
    dispatch(syncStaffIntranet(Number(id))).then((res) => {
      if (res.payload) AppToastService.success(t('project-detail.toast-sync-staff-success'));
    });
  };

  useEffect(() => {
    if (projectDetails && projectDetails.staffList?.length === 0 && projectDetails.revenuePlan?.length !== 0) {
      startPlanningInTable();
    }
  }, [projectDetails]);

  return {
    createTeam,
    consolidaPlanning,
    saveAndConsolidaPlanning,
    deleteStaffRole,
    closeRendicontazioneProject,
    sendPlanningProject,
    isConfermaEnabled,
    startPlanningInTable,
    syncStaffOnIntranet,
  };
};

export default useProjectActions;

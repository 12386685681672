import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useAppSelector } from 'core/store/hooks';
import { ContractOrderOutputDto, ContractOrderOutputListDto } from '@api/client';
import { formatCurrency } from 'shared/utils/common.utils';
import { activeOrdersSfSelector } from '../_redux/selectors';

interface MappedOrderSf {
  id: MappedFieldOrderSf;
  idExternalContract: MappedFieldOrderSf;
  amount: MappedFieldOrderSf;
  actualRevenues: MappedFieldOrderSf;
  sales: MappedFieldOrderSf;
  status: MappedFieldOrderSf;
  clAm: MappedFieldOrderSf;
  legalEntity: MappedFieldOrderSf;
  azione: MappedFieldOrderSf;
  signatureDate: MappedFieldOrderSf;
}

interface MappedFieldOrderSf {
  label: string;
  value: string | number | undefined;
  visible: boolean;
  statusBadge?: string;
  isButton?: boolean;
  icon?: string;
  className?: string;
  tooltip?: string;
}

const useGetAndMapOrdersSf = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<MappedOrderSf[]>([]);
  const [paginatorData, setPaginatorData] = useState<Partial<ContractOrderOutputListDto> | undefined>(undefined);

  const filteredOrders = useAppSelector(activeOrdersSfSelector);

  useEffect(() => {
    const orderList = filteredOrders?.contractOrderOutputDtoList;
    if (orderList?.length) {
      setOrders(
        mapOrders(orderList)?.sort((a, b) =>
          new Date(a.signatureDate.value!).getTime() < new Date(b.signatureDate.value!).getTime() ? 1 : -1
        )
      );
      setPaginatorData({
        totalElements: filteredOrders?.totalElements,
        totalPages: filteredOrders?.totalPages,
        page: filteredOrders?.page,
      });
    } else {
      setOrders([]);
    }
  }, [filteredOrders]);

  const mapOrders = (orders: ContractOrderOutputDto[]): MappedOrderSf[] => {
    return orders.map((order) => {
      return {
        id: { label: t('project-list.id'), value: order.pkOrderSF, visible: false },
        idExternalContract: { label: t('Order'), value: order.idExternalContract, visible: true },
        object: {
          label: t('Order name'),
          value: order.object || '-',
          visible: true,
        },
        account: {
          label: t('Account'),
          value: order.denominazioneCliente || '-',
          visible: true,
        },
        poNumber: {
          label: t('PO number'),
          value: order.ordPoNumber || '-',
          visible: true,
        },
        amount: {
          label: t('Contract value (€)'),
          value: (order.contractValue && formatCurrency(order.contractValue)) || '-',
          visible: true,
          className: 'text-end',
        },
        actualRevenues: {
          label: t('A. Revenues (€)'),
          value: (order.contractValue && formatCurrency(order.ordARicavi)) || '-',
          visible: true,
          className: 'text-end',
        },
        sales: {
          label: t('Sales (€)'),
          value: (order.ordFattureEmesse && formatCurrency(order.ordFattureEmesse)) || '-',
          visible: true,
          className: 'text-end',
        },
        status: {
          label: t('Status'),
          value: order.ordStato || '-',
          visible: true,
          // statusBadge: order.contractStatus?.id === 1 ? 'success' : 'danger',
        },
        clAm: {
          label: t('CL/AM'),
          value: order.strutturaFunzione?.nome || '-',
          visible: true,
        },
        legalEntity: {
          label: t('Legal entity'),
          value: `${order.legalEntity?.nome || '-'}`,
          visible: true,
        },
        signatureDate: {
          label: t('Signature date'),
          value: order?.signatureDate ? format(new Date(order?.signatureDate), 'dd/MM/yyyy') : '-',
          visible: false,
        },
        azione: {
          label: t('project-list.action'),
          value: '',
          visible: true,
          isButton: true,
          icon: 'fa fas fa-arrow-right',
          className: 'text-center',
          tooltip: t('project-list.action-tooltip'),
        },
      };
    });
  };
  return { orders, paginatorData };
};

export default useGetAndMapOrdersSf;

import { AssociaFatturaProgettoRequest } from '@api/client';
import { useAppDispatch } from 'core/store/hooks';
import { associateFatturaProject } from '../_redux/actions';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';

const useSaveActiveCycleAssociation = () => {
  const dispatch = useAppDispatch();

  const saveAssociation = (id: string, idOrdine: number, idProject: number) => {
    const payload: AssociaFatturaProgettoRequest = {
      associaFattureProgettoDtoList: {
        fattureProgettoDtoList: [
          {
            pkFattura: id,
            dettagli: [
              {
                idOrdine,
                idProject,
              },
            ],
          },
        ],
      },
    };
    dispatch(associateFatturaProject(payload))
      .unwrap()
      .then((res) => {
        if (res) {
          AppToastService.success('Association saved');
        }
      });
  };

  return { saveAssociation };
};

export default useSaveActiveCycleAssociation;

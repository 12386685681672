import React, { useState } from 'react';
import { Accordion, Card, Col } from 'react-bootstrap';
import './styles.scss';

interface Props {
  header: string;
  isDefaultOpened?: boolean;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
}

const CommonAccordion: React.FC<Props> = ({ header, isDefaultOpened = false, headerContent, children }: Props) => {
  const [isOpened, setIsOpened] = useState<boolean>(isDefaultOpened);

  return (
    <Accordion activeKey={isOpened ? '0' : ''} className="acc-btn my-3">
      <Card className="border-left-primary acc-btn">
        <Card.Header
          className={`d-flex justify-content-between align-items-center cursor-pointer ${
            isOpened ? 'card-header-border' : 'row'
          } headerCard `}
          onClick={() => setIsOpened(!isOpened)}
        >
          <Col sm="auto">
            <b className="text-blue-links align-items-center my-auto me-3">{header}</b>
          </Col>
          <>{headerContent}</>
          <i
            className={` fa-solid fa-chevron-${
              isOpened ? 'up' : 'down'
            } notification-header cursor-pointer my-auto align-items-center`}
            style={{ textAlign: 'right', width: '40px' }}
          ></i>
        </Card.Header>
        {isOpened && <Card.Body className="mx-2">{children}</Card.Body>}
      </Card>
    </Accordion>
  );
};

export default CommonAccordion;

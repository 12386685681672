/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractOrderFromJSON, ContractOrderToJSON, } from './ContractOrder';
import { LightProjectFromJSON, LightProjectToJSON, } from './LightProject';
/**
 * Check if a given object implements the ActiveOrderProject interface.
 */
export function instanceOfActiveOrderProject(value) {
    return true;
}
export function ActiveOrderProjectFromJSON(json) {
    return ActiveOrderProjectFromJSONTyped(json, false);
}
export function ActiveOrderProjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'contractOrder': json['contractOrder'] == null ? undefined : ContractOrderFromJSON(json['contractOrder']),
        'project': json['project'] == null ? undefined : LightProjectFromJSON(json['project']),
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'lastmodifiedDate': json['lastmodifiedDate'] == null ? undefined : (new Date(json['lastmodifiedDate'])),
    };
}
export function ActiveOrderProjectToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'orderId': value['orderId'],
        'projectId': value['projectId'],
        'contractOrder': ContractOrderToJSON(value['contractOrder']),
        'project': LightProjectToJSON(value['project']),
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'lastmodifiedDate': value['lastmodifiedDate'] == null ? undefined : ((value['lastmodifiedDate']).toISOString()),
    };
}

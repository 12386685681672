import BreadCrumb from '../breadcrumb';
import './styles.scss';

const AppHeader: React.FC = () => {
  return (
    <nav className=" mt-3 static-top ">
      <BreadCrumb />
    </nav>
  );
};
export default AppHeader;

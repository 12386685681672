import { ElencoAssetDto, ProjectElencoAssetDto } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import { getAssets, getProjectAssetById } from './actions';

interface InitialState {
  assets: ElencoAssetDto[];
  projectAssets: ProjectElencoAssetDto[];
}

const initialState: InitialState = {
  assets: [],
  projectAssets: [],
};

export const productsAndSubscriptionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAssets.fulfilled, (state, action) => {
      state.assets = action.payload?.elencoAssetDtoList ?? [];
    })
    .addCase(getProjectAssetById.fulfilled, (state, action) => {
      state.projectAssets = action.payload?.projectElencoAssetDtoList ?? [];
    });
});

import { createReducer } from '@reduxjs/toolkit';
import { CategoriaNoteDto, NoteDto } from '@api/client';
import {
  fetchProjectMessages,
  insertProjectMessage,
  updateProjectMessage,
  deleteProjectMessage,
  getProjectMessageCategories,
} from './actions';

interface ProjectMessagesState {
  messages: NoteDto[];
  categories: CategoriaNoteDto[] | undefined;
}

const initialState: ProjectMessagesState = {
  messages: [],
  categories: undefined,
};

export const projectMessagesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProjectMessages.fulfilled, (state, action) => {
      state.messages = action.payload?.note || [];
    })
    .addCase(insertProjectMessage.fulfilled, (state, action) => {
      if (action.payload) {
        state.messages.push(action.payload);
      }
    })
    .addCase(updateProjectMessage.fulfilled, (state, action) => {
      const index = state.messages.findIndex((msg) => msg.idProject === action.payload?.idProject);
      if (index !== -1 && action.payload) {
        state.messages[index] = action.payload;
      }
    })
    .addCase(deleteProjectMessage.fulfilled, (state, action) => {
      state.messages = state.messages.filter((msg) => msg.idProject !== action.payload);
    })
    .addCase(getProjectMessageCategories.fulfilled, (state, action) => {
      state.categories = action.payload?.categoriaNoteDtoList || [];
    });
});

import { DimAlberoCommercialeL1Dto, DimAlberoDeliveryL1Dto } from '@api/client';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { allUsersSelector } from '../../project-list/project-detail/_redux/selectors';
import { crudGetAlberaturaBusinessNew, crudGetAlberaturaDeliveryNew } from '../_redux/actions';
import { StructureTypes } from 'enums/global.enum';

const useGetStructureTrees = (structureType: StructureTypes) => {
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector(allUsersSelector);
  const [businessTrees, setBusinessTrees] = useState<DimAlberoCommercialeL1Dto[] | undefined>(undefined);
  const [mappedBusinessTrees, setMappedBusinessTrees] = useState<any[] | undefined>(undefined);
  const [deliveryTrees, setDeliveryTrees] = useState<DimAlberoDeliveryL1Dto[] | undefined>(undefined);
  const [mappedDeliveryTrees, setMappedDeliveryTrees] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    getStructures();
  }, []);

  useEffect(() => {
    if (structureType === StructureTypes.BUSINESS && businessTrees) {
      const mapped = businessTrees.map((tree, indexL1) => {
        const l2Child =
          tree.l2Children?.filter((el) => el.l2 !== undefined)?.length !== 0
            ? tree.l2Children
                ?.filter((el) => el.l2 !== undefined)
                ?.map((l2, indexL2) => {
                  return {
                    key: `${indexL1}-${indexL2}-0`,
                    data: {
                      idStrutturaFunzione: l2.l2,
                      nomeStrutturaFunzione: l2.l2NomeStrutturaFunzione,
                      nomeResp: allUsers?.find((u) => u.id === l2.l2IdUtenteResp)?.nominativo,
                      emailResp: l2.l2EmailResp,
                      idResp: l2.l2IdUtenteResp,
                    },
                    children:
                      l2.l3Children?.filter((el) => el.l3 !== undefined)?.length !== 0
                        ? l2.l3Children
                            ?.filter((el) => el.l3 !== undefined)
                            ?.map((l3, indexL3) => {
                              return {
                                key: `${indexL1}-${indexL2}-${indexL3}-0-0`,
                                data: {
                                  idStrutturaFunzione: l3.l3,
                                  nomeStrutturaFunzione: l3.l3NomeStrutturaFunzione,
                                  nomeResp: allUsers?.find((u) => u.id === l3.l3IdUtenteResp)?.nominativo,
                                  emailResp: l3.l3EmailResp,
                                  idResp: l3.l3IdUtenteResp,
                                },
                              };
                            })
                        : [],
                  };
                })
            : [];

        return {
          key: `${indexL1}`,
          data: {
            idStrutturaFunzione: tree.idStrutturaFunzione,
            nomeStrutturaFunzione: tree.nomeStrutturaFunzione,
            nomeResp: allUsers?.find((u) => u.id === tree.l1IdUtenteResp)?.nominativo,
            emailResp: tree.l1EmailResp,
            idResp: tree.l1IdUtenteResp,
          },
          children: l2Child,
        };
      });
      setMappedBusinessTrees(mapped);
    }
  }, [businessTrees]);

  useEffect(() => {
    if (structureType === StructureTypes.DELIVERY && deliveryTrees) {
      const mapped = deliveryTrees.map((tree, indexL1) => {
        const l2Child =
          tree.l2Children?.filter((el) => el.l2 !== undefined)?.length !== 0
            ? tree.l2Children
                ?.filter((el) => el.l2 !== undefined)
                ?.map((l2, indexL2) => {
                  return {
                    key: `${indexL1}-${indexL2}-0`,
                    data: {
                      idStrutturaFunzione: l2.l2,
                      nomeStrutturaFunzione: l2.l2NomeStrutturaFunzione,
                      nomeResp: allUsers?.find((u) => u.id === l2.l2IdUtenteResp)?.nominativo,
                      emailResp: l2.l2EmailResp,
                      idResp: l2.l2IdUtenteResp,
                    },
                    children:
                      l2.l3Children?.filter((el) => el.l3 !== undefined)?.length !== 0
                        ? l2.l3Children
                            ?.filter((el) => el.l3 !== undefined)
                            ?.map((l3, indexL3) => {
                              return {
                                key: `${indexL1}-${indexL2}-${indexL3}-0-0`,
                                data: {
                                  idStrutturaFunzione: l3.l3,
                                  nomeStrutturaFunzione: l3.l3NomeStrutturaFunzione,
                                  nomeResp: allUsers?.find((u) => u.id === l3.l3IdUtenteResp)?.nominativo,
                                  emailResp: l3.l3EmailResp,
                                  idResp: l3.l3IdUtenteResp,
                                },
                              };
                            })
                        : [],
                  };
                })
            : [];

        return {
          key: `${indexL1}`,
          data: {
            idStrutturaFunzione: tree.idStrutturaFunzione,
            nomeStrutturaFunzione: tree.nomeStrutturaFunzione,
            nomeResp: allUsers?.find((u) => u.id === tree.l1IdUtenteResp)?.nominativo,
            emailResp: tree.l1EmailResp,
            idResp: tree.l1IdUtenteResp,
          },
          children: l2Child,
        };
      });
      setMappedDeliveryTrees(mapped);
    }
  }, [deliveryTrees]);

  const getStructures = () => {
    const action =
      structureType === StructureTypes.BUSINESS ? crudGetAlberaturaBusinessNew({}) : crudGetAlberaturaDeliveryNew({});
    dispatch(action)
      .unwrap()
      .then((res) => {
        if (structureType === StructureTypes.DELIVERY) setDeliveryTrees(res);
        if (structureType === StructureTypes.BUSINESS) setBusinessTrees(res);
      });
  };

  return { mappedBusinessTrees, mappedDeliveryTrees, getStructures };
};

export default useGetStructureTrees;

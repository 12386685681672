import { useTranslation } from 'react-i18next';
import { MonthOption } from '../../_models/monthOption';
import ModalAction from '../modal-action';
import { useState } from 'react';
import MonthCheckList from '../months-check-list';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import LinksButton from 'shared/design-system/components/links-button';

interface Props {
  monthOptionsObj: MonthOption[];
  readOnly: boolean | undefined;
  checkedList: CheckboxValueType[];
  years: (number | undefined)[];
  removeMonths: () => void;
  onChangeCheckedList: (list: any[]) => void;
  onCheckAllChange: (e: CheckboxChangeEvent) => void;
}

const DeleteMonthComponent: React.FC<Props> = ({
  monthOptionsObj,
  readOnly,
  checkedList,
  years,
  removeMonths,
  onChangeCheckedList,
  onCheckAllChange,
}) => {
  const { t } = useTranslation();
  const [showModalDeleteMonths, setShowModalDeleteMonths] = useState(false);

  return (
    <>
      {monthOptionsObj.length > 0 && (
        <LinksButton
          className="btn-sm btn btn-outline-danger action-button px-1"
          isDisabled={readOnly}
          onClick={() => setShowModalDeleteMonths(true)}
        >
          <div className="d-flex align-items-center">
            <i className="icon-calendar-delete" />
          </div>
        </LinksButton>
      )}
      <ModalAction
        title={t('project-detail.delete-months')}
        actionLabel={t('common.delete')}
        action={removeMonths}
        setShow={(value) => setShowModalDeleteMonths(value)}
        show={showModalDeleteMonths}
        size="lg"
      >
        <p>
          <b>{t('project-detail.delete-months')}</b>
        </p>
        <MonthCheckList
          monthOptionsObj={monthOptionsObj}
          checkedList={checkedList}
          onChange={onChangeCheckedList}
          onCheckAllChange={onCheckAllChange}
          years={years}
        />
      </ModalAction>
    </>
  );
};

export default DeleteMonthComponent;

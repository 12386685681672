import React from 'react';
import { InputNumber } from 'antd';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface PriceRangeInputProps {
  value: any;
  onChange: (value: [number | null, number | null]) => void;
  onClick?: any;
  children?: React.ReactNode;
}

const PriceRangeInput: React.FC<PriceRangeInputProps> = ({ children, value, onChange, onClick }) => {
  const { t } = useTranslation();

  const handleChangeMin = (minValue: number | null) => {
    onChange([minValue, value[1]]);
  };

  const handleChangeMax = (maxValue: number | null) => {
    onChange([value[0], maxValue]);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <InputNumber
          className="col-5 minimo-input"
          onClick={onClick}
          placeholder={t('project-list.price-range-min')}
          value={value[0]}
          size="large"
          onChange={handleChangeMin}
          style={{ border: '0px', borderEndEndRadius: '0', borderTopRightRadius: '0' }}
          formatter={(value) => (value ? `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '')}
          parser={(value: any) => (value ? value.replace(/€\s?|(,*)/g, '') : '')}
        />
        <svg
          viewBox="0 0 1024 1024"
          focusable="false"
          data-icon="swap-right"
          width="1em"
          height="1em"
          fill="lightgrey"
          aria-hidden="true"
        >
          <path d="M873.1 596.2l-164-208A32 32 0 00684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z"></path>
        </svg>
        <InputNumber
          onClick={onClick}
          className="col-5 ps-2 massimo-input"
          placeholder={t('project-list.price-range-max')}
          value={value[1]}
          size="large"
          onChange={handleChangeMax}
          style={{ border: '0px', borderRadius: '0' }}
          formatter={(value) => (value ? `to € ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '')}
          parser={(value: any) => (value ? value.replace(/€\s?|(,*)/g, '') : '')}
        />
        <div className="ms-auto pe-2 me-1">
          <span
            className=" cursor-pointer ant-select-arrow"
            unselectable="on"
            aria-hidden="true"
            style={{ userSelect: 'none' }}
          >
            <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                fill="lightgrey"
                height="13px"
                width="13px"
                version="1.1"
                id="Capa_1"
                viewBox="0 0 310.75 310.75"
                xmlSpace="preserve"
              >
                <path d="M183.815,265.726c-32.444,0-60.868-21.837-76.306-54.325h102.101v-45.023H95.643c-0.284-3.642-0.437-7.29-0.437-11.016  c0-3.691,0.152-7.384,0.437-10.977h113.969V99.353H107.51c15.438-32.485,43.861-54.315,76.306-54.315  c31.01,0,60.21,20.759,76.2,54.152l40.626-19.418C277.091,30.554,232.329,0,183.815,0c-36.47,0-70.51,16.665-95.851,46.966  C75.219,62.209,65.481,79.995,59.079,99.353H10.108v45.031h40.39c-0.217,3.617-0.329,7.311-0.329,10.977  c0,3.704,0.112,7.351,0.329,11.016h-40.39V211.4h48.971c6.402,19.356,16.14,37.122,28.886,52.351  c25.341,30.303,59.381,46.999,95.851,46.999c48.515,0,93.275-30.55,116.826-79.767l-40.626-19.454  C244.025,244.965,214.825,265.726,183.815,265.726z" />
              </svg>
            </span>
          </span>
        </div>{' '}
      </div>
    </>
  );
};

export default PriceRangeInput;

import { useTranslation } from 'react-i18next';
import { ContractOrderOutputDto, UserDto } from '@api/client';
import OrderDataStatic from './order-data-static';
import { Tooltip } from 'antd';
import { useState } from 'react';
import OrderDataEdit from './order-data-edit';
import { Accordion, Card, Col } from 'react-bootstrap';

interface Props {
  order: ContractOrderOutputDto;
  allUsers: UserDto[] | null;
}

const OrderData: React.FC<Props> = ({ order, allUsers }) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [isOpened, setIsOpened] = useState<boolean>(true);
  const HeaderElement = (
    <Tooltip placement="top" title="Edit" color="teal">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpened(true);
          setEditMode(!editMode);
        }}
        className="btn-icon"
        style={{ height: '25px' }}
      >
        <i className={'icon-edit text-teal-links me-1'} />
      </button>
    </Tooltip>
  );

  return (
    <Accordion activeKey={isOpened ? '0' : ''} className="acc-btn my-3">
      <Card className="border-left-primary acc-btn">
        <Card.Header
          className={`d-flex justify-content-between align-items-center cursor-pointer ${
            isOpened ? 'card-header-border' : ''
          } headerCard `}
          onClick={() => setIsOpened(!isOpened)}
        >
          <Col sm="auto">
            <b className="text-blue-links align-items-center my-auto me-3">{t('Order data')}</b>
          </Col>
          <div className="d-flex justify-content-end align-items-center">
            <>{HeaderElement}</>
            <div>
              <i
                className={` fa-solid fa-chevron-${isOpened ? 'up' : 'down'} `}
                style={{ textAlign: 'right', width: '20px' }}
              />
            </div>
          </div>
        </Card.Header>
        {isOpened && (
          <Card.Body className="mx-2">
            {editMode ? (
              <OrderDataEdit order={order} allUsers={allUsers} setEditMode={setEditMode} />
            ) : (
              <OrderDataStatic order={order} allUsers={allUsers} />
            )}
          </Card.Body>
        )}
      </Card>
    </Accordion>
  );
};

export default OrderData;

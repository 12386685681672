import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { getAssets } from '../_redux/actions';
import { useEffect, useState } from 'react';
import { assetsSelector } from '../_redux/selectors';

const useGetAssets = () => {
  const dispatch = useAppDispatch();
  const assetsFromRedux = useAppSelector(assetsSelector);
  const [assets, setAssets] = useState(assetsFromRedux);

  useEffect(() => {
    if (!assets.length) dispatch(getAssets());
  }, [assets]);

  useEffect(() => {
    setAssets(assetsFromRedux);
  }, [assetsFromRedux]);

  const getAllAssets = () => {
    dispatch(getAssets());
  };

  return { assets, setAssets, getAllAssets };
};

export default useGetAssets;

import {
  CicloPassivoFieldsDto,
  ContractOrderOutputDto,
  ContractOrderOutputListDto,
  ContractOrderSFSearchInputDto,
  FatturaEmessaDto,
  FatturaEmessaSearchDto,
  ProjectCicloPassivoOutputDto,
} from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import {
  getActiveCycleFattureEmesse,
  getActiveCycleOptions,
  getAssociableProjects,
  saveFiltersActiveAssociableInvoicesUsed,
  saveFiltersOrdersSfUsed,
  searchActiveOrders,
  searchActiveOrdersSf,
} from './actions';

interface InitialState {
  activeCycleOptions: CicloPassivoFieldsDto | undefined;
  activeOrdersSf: ContractOrderOutputListDto | undefined;
  activeCycleOrdersAttivi: ContractOrderOutputDto[]; //  searchOrdersAttivi
  activeCycleOrdersAttiviForBills: ContractOrderOutputDto[]; // searchOrdersAttivi
  activeCycleFattureEmesse: FatturaEmessaDto[];
  associableProjects: ProjectCicloPassivoOutputDto[];
  associableInvoices: FatturaEmessaDto[];
  filtersOrdersSfUsed: ContractOrderSFSearchInputDto | undefined;
  filtersActiveAssociableInvoicesUsed: FatturaEmessaSearchDto | undefined;
}

const initialState: InitialState = {
  activeCycleOptions: undefined,
  activeOrdersSf: undefined,
  activeCycleOrdersAttivi: [],
  activeCycleOrdersAttiviForBills: [],
  activeCycleFattureEmesse: [],
  associableProjects: [],
  associableInvoices: [],
  filtersOrdersSfUsed: undefined,
  filtersActiveAssociableInvoicesUsed: undefined,
};

export const activeCycleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getActiveCycleOptions.fulfilled, (state, action) => {
      state.activeCycleOptions = action.payload;
    })
    .addCase(searchActiveOrdersSf.fulfilled, (state, action) => {
      state.activeOrdersSf = action.payload;
    })
    .addCase(searchActiveOrders.fulfilled, (state, action) => {
      if (action.meta.arg.contractOrderSearchDto.isLight) {
        state.activeCycleOrdersAttiviForBills = action.payload?.contractOrderOutputDtoList || [];
      } else {
        state.activeCycleOrdersAttivi = action.payload?.contractOrderOutputDtoList || [];
      }
    })
    .addCase(getActiveCycleFattureEmesse.fulfilled, (state, action) => {
      const type = action.meta.arg.type;
      switch (type) {
        case 'associated':
          state.activeCycleFattureEmesse = action.payload?.content || [];
          break;
        case 'associable':
          state.associableInvoices = action.payload?.content || [];
          break;
      }
    })
    .addCase(getAssociableProjects.fulfilled, (state, action) => {
      state.associableProjects = action.payload?.projectCicloPassivoOutputDtoList || [];
    })
    .addCase(saveFiltersOrdersSfUsed, (state, action) => {
      state.filtersOrdersSfUsed = action.payload;
    })
    .addCase(saveFiltersActiveAssociableInvoicesUsed, (state, action) => {
      state.filtersActiveAssociableInvoicesUsed = action.payload;
    });
});

import { FatturaRicevutaDto, ProjectExternalCost } from '@api/client';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NoData from 'shared/design-system/components/no-data-box';
import { formatCurrency } from 'shared/utils/common.utils';
import AssociateCostsToPassiveInvoiceModal from '../associate-costs-to-passive-invoice-modal';
import { useAppDispatch } from 'core/store/hooks';
import { disassociateInvoice } from 'pages/private/active-cycle/active-cycle-order-list/_redux/actions';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import ModalAction from 'pages/private/project-list/project-detail/_components/modal-action';
import { getFattureRicevute } from 'pages/private/active-cycle/active-cycle-bills/_redux/actions';

interface Props {
  invoices: FatturaRicevutaDto[];
  associatedProjects?: ProjectExternalCost[];
  idOrdine?: number | undefined;
  readOnly: boolean;
}

type FatturaRicevutaDtoWithProject = FatturaRicevutaDto & { projectId: number | undefined };

const PassiveOrderAssociatedInvoicesTable: React.FC<Props> = ({ invoices, associatedProjects, idOrdine, readOnly }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [invoicesList, setInvoicesList] = useState<FatturaRicevutaDtoWithProject[]>([]);
  const [isAssociateCostToInvoiceModalVisible, setIsAssociateCostToInvoiceModalVisible] = useState(false);
  const [currentInvoiceId, setCurrentInvoiceId] = useState<string | undefined>(undefined);
  const [dissociateInvoiceModalVisible, setDissociateInvoiceModalVisible] = useState<boolean>(false);
  const [invoiceToDeleteId, setInvoiceToDeleteId] = useState<(number | undefined)[]>([]);

  useEffect(() => {
    if (invoices) {
      setInvoicesList(invoices.map((invoice) => ({ ...invoice, projectId: undefined })));
    }
  }, [invoices]);

  const handleDissociateInvoice = async () => {
    if (!invoiceToDeleteId.length) return;

    try {
      await Promise.all(invoiceToDeleteId.filter(Boolean).map((id) => dispatch(disassociateInvoice(id as number))));

      AppToastService.success(t('Invoice dissociated successfully'));
      setDissociateInvoiceModalVisible(false);

      if (idOrdine) {
        dispatch(
          getFattureRicevute({
            request: { fatturaRicevutaSearchDto: { numeroOrdine: String(idOrdine), paging: { size: 9999, page: 0 } } },
            type: 'associated',
          })
        );
      }
    } catch (error) {
      console.error('Errore durante la dissociazione delle fatture:', error);
    }
  };

  if (invoicesList.length) {
    return (
      <div className="table-responsive table-bordered my-3">
        <Table className={'mt-0 mb-0'}>
          <thead className="bg-red">
            <tr>
              <th scope="col">
                <span>{'N.Invoice'}</span>
              </th>
              <th scope="col">
                <span>{'Amount (IVA incl.)'}</span>
              </th>
              <th scope="col">
                <span>{'Amount'}</span>
              </th>
              <th scope="col">
                <span>{'N. document'}</span>
              </th>
              <th scope="col">
                <span>{'Invoice date'}</span>
              </th>
              <th scope="col">
                <span>{'Typology'}</span>
              </th>
              <th scope="col">
                <span>{'Supplier'}</span>
              </th>
              <th scope="col" style={{ width: '300px' }}>
                <span>{'Description'}</span>
              </th>
              <th className="text-center" scope="col" style={{ maxWidth: '65px' }}>
                <span>{readOnly ? 'Costs' : 'Add'}</span>
              </th>
              {readOnly && (
                <th className="text-center" scope="col" style={{ maxWidth: '65px' }}>
                  <span>{'Delete'}</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {invoicesList.map((invoice) => (
              <tr key={`invoice-${invoice.fkFatturaRicevuta}`}>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.fkFatturaRicevuta}</p>
                  </span>
                </td>
                <td className="text-end">
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{formatCurrency(invoice.frImportoTotale)}€</p>
                  </span>
                </td>
                <td className="text-end">
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{formatCurrency(invoice.frImponibile)}€</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.frNumDocumento}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.dataFattura?.toLocaleDateString('it-IT') || '-'}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.frTipologia}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.denominazioneFornitore}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto" title={invoice.frDescrizioneCausale || '-'}>
                      {invoice.frDescrizioneCausale
                        ? `${invoice.frDescrizioneCausale.slice(0, 100)}${
                            invoice.frDescrizioneCausale.length > 100 ? '…' : ''
                          }`
                        : '-'}
                    </p>
                  </span>
                </td>

                <td className="text-center">
                  <Button
                    className="btn text-blue-links border-none p-0"
                    type="button"
                    variant="icon"
                    onClick={() => {
                      setCurrentInvoiceId(invoice.fkFatturaRicevuta);
                      setIsAssociateCostToInvoiceModalVisible(true);
                    }}
                  >
                    {readOnly ? (
                      <i className="icon-eye-shows text-blue-links" />
                    ) : (
                      <i className="fas fa-plus-circle text-blue-links" />
                    )}
                  </Button>
                </td>
                {readOnly && (
                  <td className="text-center">
                    <Button
                      className="btn text-blue-links border-none p-0"
                      type="button"
                      variant="icon"
                      onClick={() => {
                        setDissociateInvoiceModalVisible(true);
                        setInvoiceToDeleteId(
                          invoice.associatedOrders?.[0].associatedProjects?.map((p) => p.associationId) || []
                        );
                      }}
                    >
                      <i className="fas fa-xmark text-danger" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <AssociateCostsToPassiveInvoiceModal
          isOpen={isAssociateCostToInvoiceModalVisible}
          onClose={() => setIsAssociateCostToInvoiceModalVisible(false)}
          associatedProjects={associatedProjects}
          idOrdine={idOrdine}
          currentInvoiceId={currentInvoiceId}
          readOnly={readOnly}
        />
        <ModalAction
          title={t('Dissociate invoice')}
          actionLabel={t('common.confirm')}
          cancelLabel={t('common.cancel')}
          action={() => {
            handleDissociateInvoice();
          }}
          setShow={(value) => setDissociateInvoiceModalVisible(value)}
          show={dissociateInvoiceModalVisible}
        >
          <span className="text-danger">
            <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
          </span>
          <div className="p-1">
            <p>{t('Are you sure you want to dissociate the invoice?')}</p>
          </div>
        </ModalAction>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center mt-3">
        <NoData msg={t('No invoices found')} />
      </div>
    );
  }
};

export default PassiveOrderAssociatedInvoicesTable;

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StrutturaFunzioneFromJSON, StrutturaFunzioneToJSON, } from './StrutturaFunzione';
/**
 * Check if a given object implements the LightProject interface.
 */
export function instanceOfLightProject(value) {
    return true;
}
export function LightProjectFromJSON(json) {
    return LightProjectFromJSONTyped(json, false);
}
export function LightProjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'pkProgetto': json['pkProgetto'] == null ? undefined : json['pkProgetto'],
        'fkProgettoIntranet': json['fkProgettoIntranet'] == null ? undefined : json['fkProgettoIntranet'],
        'denominazioneProgetto': json['denominazioneProgetto'] == null ? undefined : json['denominazioneProgetto'],
        'denominazioneResponsabileProgetto': json['denominazioneResponsabileProgetto'] == null ? undefined : json['denominazioneResponsabileProgetto'],
        'oftRicavi': json['oftRicavi'] == null ? undefined : json['oftRicavi'],
        'prjARicavi': json['prjARicavi'] == null ? undefined : json['prjARicavi'],
        'denominazioneStatoProgetto': json['denominazioneStatoProgetto'] == null ? undefined : json['denominazioneStatoProgetto'],
        'strutturaFunzione': json['strutturaFunzione'] == null ? undefined : StrutturaFunzioneFromJSON(json['strutturaFunzione']),
    };
}
export function LightProjectToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'pkProgetto': value['pkProgetto'],
        'fkProgettoIntranet': value['fkProgettoIntranet'],
        'denominazioneProgetto': value['denominazioneProgetto'],
        'denominazioneResponsabileProgetto': value['denominazioneResponsabileProgetto'],
        'oftRicavi': value['oftRicavi'],
        'prjARicavi': value['prjARicavi'],
        'denominazioneStatoProgetto': value['denominazioneStatoProgetto'],
        'strutturaFunzione': StrutturaFunzioneToJSON(value['strutturaFunzione']),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the FatturaEmessaTabDTO interface.
 */
export function instanceOfFatturaEmessaTabDTO(value) {
    return true;
}
export function FatturaEmessaTabDTOFromJSON(json) {
    return FatturaEmessaTabDTOFromJSONTyped(json, false);
}
export function FatturaEmessaTabDTOFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idRowFattura': json['idRowFattura'] == null ? undefined : json['idRowFattura'],
        'pkFattura': json['pkFattura'] == null ? undefined : json['pkFattura'],
        'idProgetto': json['idProgetto'] == null ? undefined : json['idProgetto'],
        'numeroFattura': json['numeroFattura'] == null ? undefined : json['numeroFattura'],
        'tipoFattura': json['tipoFattura'] == null ? undefined : json['tipoFattura'],
        'descrizione': json['descrizione'] == null ? undefined : json['descrizione'],
        'cliente': json['cliente'] == null ? undefined : json['cliente'],
        'imponibile': json['imponibile'] == null ? undefined : json['imponibile'],
        'dataFattura': json['dataFattura'] == null ? undefined : (new Date(json['dataFattura'])),
        'dataPagamento': json['dataPagamento'] == null ? undefined : (new Date(json['dataPagamento'])),
        'dataScadenza': json['dataScadenza'] == null ? undefined : (new Date(json['dataScadenza'])),
        'fattStatoContabile': json['fattStatoContabile'] == null ? undefined : json['fattStatoContabile'],
        'fattStato': json['fattStato'] == null ? undefined : json['fattStato'],
    };
}
export function FatturaEmessaTabDTOToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idRowFattura': value['idRowFattura'],
        'pkFattura': value['pkFattura'],
        'idProgetto': value['idProgetto'],
        'numeroFattura': value['numeroFattura'],
        'tipoFattura': value['tipoFattura'],
        'descrizione': value['descrizione'],
        'cliente': value['cliente'],
        'imponibile': value['imponibile'],
        'dataFattura': value['dataFattura'] == null ? undefined : ((value['dataFattura']).toISOString().substring(0, 10)),
        'dataPagamento': value['dataPagamento'] == null ? undefined : ((value['dataPagamento']).toISOString().substring(0, 10)),
        'dataScadenza': value['dataScadenza'] == null ? undefined : ((value['dataScadenza']).toISOString().substring(0, 10)),
        'fattStatoContabile': value['fattStatoContabile'],
        'fattStato': value['fattStato'],
    };
}

import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import {
  ContractOrderExternalCostDto,
  ContractOrderOutputDto,
  ProjectCicloPassivoOutputDto,
  UpdateContractAndAssociateExternalCostsRequest,
} from '@api/client';
import { ExternalCostWithAmount, ProjectCicloPassivoOutputWithAmount } from '../_models/AssociableProjects';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { associableOtherCostsProjectsSelector } from 'pages/private/passive-cycle/_redux/selectors';
import { searchOrders, updateOrder } from 'pages/private/passive-cycle/_redux/actions';
import { useParams } from 'react-router-dom';

const useHandleAssociableProjects = (
  currentOrder: ContractOrderOutputDto | undefined,
  setIsAssociateNewProjectVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: any }>();
  const associableProjectsSelector = useAppSelector(associableOtherCostsProjectsSelector);
  const [associableProjects, setAssociableProjects] = useState<ProjectCicloPassivoOutputWithAmount[]>([]);
  const [externalCostsToAdd, setExternalCostsToAdd] = useState<ContractOrderExternalCostDto[]>([]);

  useEffect(() => {
    if (associableProjectsSelector.length) {
      // setAssociableProjects(associableProjectsSelector);
      setAssociableProjects(
        associableProjectsSelector.map((project) => {
          return {
            ...project,
            otherCostList: {
              monthWithCostsOrPlan: project.otherCostList?.monthWithCostsOrPlan,
              otherCosts: project.otherCostList?.otherCosts?.map((cost) => {
                return {
                  ...cost,
                  amount:
                    project.otherCostList?.otherCosts
                      ?.find((item) => item.costType?.id === cost.costType?.id && item.groupId === cost.groupId)
                      ?.costAllocation?.map((allocation) => allocation.actualCost)
                      .reduce((a, b) => (a ?? 0) + (b ?? 0), 0) || 0,
                };
              }),
            },
          };
        })
      );
    }
  }, [associableProjectsSelector]);

  const handleAssociateCost = (
    e: ChangeEvent<HTMLInputElement>,
    cost: ExternalCostWithAmount,
    project: ProjectCicloPassivoOutputDto
  ) => {
    const targetProject = associableProjects.find((item) => item.projectId === project.projectId);
    setAssociableProjects((prev) => {
      return prev?.map((item) => {
        if (item.projectId === targetProject?.projectId) {
          return {
            ...item,
            otherCostList: {
              monthWithCostsOrPlan: item.otherCostList?.monthWithCostsOrPlan,
              otherCosts: item.otherCostList?.otherCosts?.map((costItem) => {
                if (costItem.costType?.id === cost.costType?.id && costItem.groupId === cost.groupId) {
                  return {
                    ...costItem,
                    isAssociated: e.target.checked,
                  };
                }
                return costItem;
              }),
            },
          };
        }
        return item;
      });
    });
    setExternalCostsToAdd((prev) => {
      const newCost: ContractOrderExternalCostDto = {
        costTypeId: cost.costType?.id,
        groupId: cost.groupId,
        projectId: project.projectId,
        amount: cost.amount,
        externalCostDescription: cost.description,
      };
      const projectInArray = prev.find(
        (el) => el.costTypeId === cost.costType?.id && el.groupId === cost.groupId && el.projectId === project.projectId
      );
      if (projectInArray) {
        // remove cost
        return prev.filter(
          (item) =>
            item.costTypeId !== projectInArray.costTypeId ||
            item.groupId !== projectInArray.groupId ||
            item.projectId !== projectInArray.projectId
        );
      } else {
        return [...prev, newCost];
      }
    });
  };

  const handleChangeAmount = (
    e: number | undefined,
    cost: ExternalCostWithAmount,
    project: ProjectCicloPassivoOutputDto
  ) => {
    const targetProject = associableProjects.find((item) => item.projectId === project.projectId);
    setAssociableProjects((prev) => {
      return prev?.map((item) => {
        if (item.projectId === targetProject?.projectId) {
          return {
            ...item,
            otherCostList: {
              monthWithCostsOrPlan: item.otherCostList?.monthWithCostsOrPlan,
              otherCosts: item.otherCostList?.otherCosts?.map((costItem) => {
                if (costItem.costType?.id === cost.costType?.id && costItem.groupId === cost.groupId) {
                  return {
                    ...costItem,
                    amount: e || 0,
                  };
                }
                return costItem;
              }),
            },
          };
        }
        return item;
      });
    });
    setExternalCostsToAdd((prev) => {
      return prev.map((item) => {
        if (
          item.costTypeId === cost.costType?.id &&
          item.groupId === cost.groupId &&
          item.projectId === project.projectId
        ) {
          return {
            ...item,
            amount: e || 0,
          };
        }
        return item;
      });
    });
  };

  const handleChangeDescription = (
    e: string | undefined,
    cost: ExternalCostWithAmount,
    project: ProjectCicloPassivoOutputDto
  ) => {
    const targetProject = associableProjects.find((item) => item.projectId === project.projectId);
    setAssociableProjects((prev) => {
      return prev?.map((item) => {
        if (item.projectId === targetProject?.projectId) {
          return {
            ...item,
            otherCostList: {
              monthWithCostsOrPlan: item.otherCostList?.monthWithCostsOrPlan,
              otherCosts: item.otherCostList?.otherCosts?.map((costItem) => {
                if (costItem.costType?.id === cost.costType?.id && costItem.groupId === cost.groupId) {
                  return {
                    ...costItem,
                    description: e || '',
                  };
                }
                return costItem;
              }),
            },
          };
        }
        return item;
      });
    });
    setExternalCostsToAdd((prev) => {
      return prev.map((item) => {
        if (
          item.costTypeId === cost.costType?.id &&
          item.groupId === cost.groupId &&
          item.projectId === project.projectId
        ) {
          return {
            ...item,
            externalCostDescription: e || '',
          };
        }
        return item;
      });
    });
  };

  const associateExternalCosts = () => {
    const hasEmptyDescription = externalCostsToAdd.some((cost) => !cost.externalCostDescription);
    if (hasEmptyDescription) {
      return AppToastService.error('All associated costs must have a description');
    }
    const payload: UpdateContractAndAssociateExternalCostsRequest = {
      createContractOrderDto: [
        {
          contractOrderId: currentOrder?.id || 0,
          contractOrderExternalCostDtoList: externalCostsToAdd,
        },
      ],
    };
    dispatch(updateOrder(payload))
      .unwrap()
      .then((res) => {
        setIsAssociateNewProjectVisible(false);
        dispatch(
          searchOrders({
            request: { contractOrderSearchDto: { orderId: Number(id), paging: { page: 0, size: 10 } } },
            currentOrderId: Number(id),
          })
        );
      });
  };

  return {
    associableProjects,
    externalCostsToAdd,
    handleAssociateCost,
    handleChangeAmount,
    associateExternalCosts,
    handleChangeDescription,
  };
};

export default useHandleAssociableProjects;

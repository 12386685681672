import { DeleteStaffRequest, StaffExtendedDto, UserDto } from '@api/client';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { deleteStaff, saveTeamData } from '../../_redux/actions';
import { getProjectDetails } from 'pages/private/project-list/_redux/actions';
import { feeRateTableSelector } from '../../_redux/selectors';

const useStaffManagement = (
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined,
  setFullAllocations: React.Dispatch<React.SetStateAction<Partial<StaffExtendedDto>[] | null | undefined>>,
  collaborators: UserDto[],
  filterAllocationsByYear: (year: number | 'all', allocations: Partial<StaffExtendedDto>[] | null | undefined) => void,
  currentyear: number | 'all',
  setTotalGUPlanned: React.Dispatch<React.SetStateAction<number[]>>,
  setAveragePricePlanned: React.Dispatch<React.SetStateAction<number[]>>,
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  setYears: React.Dispatch<React.SetStateAction<Array<number | undefined>>>,
  dateRange: number[]
) => {
  const dispatch = useAppDispatch();
  const feeRateTable = useAppSelector(feeRateTableSelector);

  const handleChangeAllocation = (value: number | null, id: number | undefined) => {
    let newAllocations: Partial<StaffExtendedDto>[] | null | undefined = [];
    switch (feeRateTable) {
      case 'allocations':
        newAllocations = fullAllocations?.map((el) => ({
          ...el,
          allocazioni: el.allocazioni?.map((el) => ({
            ...el,
            actualAllocation: el.id === id ? Number(value) : el.actualAllocation,
          })),
        }));
        break;
      case 'selling_price':
        newAllocations = fullAllocations?.map((el) => ({
          ...el,
          allocazioni: el.allocazioni?.map((el) => ({
            ...el,
            sellingPrice: el.id === id ? Number(value) : el.sellingPrice,
          })),
        }));
        break;
    }
    setFullAllocations(newAllocations);
    filterAllocationsByYear(currentyear, newAllocations);
    setDraftValidation(false);
  };

  const handleChangeTotalGU = (value: number | null, index: number) => {
    if (feeRateTable === 'allocations') {
      setTotalGUPlanned((prev) => {
        const newTotalGU = [...prev];
        newTotalGU[index] = value ?? 0;
        return newTotalGU;
      });
    } else {
      setAveragePricePlanned((prev) => {
        const newAveragePrice = [...prev];
        newAveragePrice[index] = value ?? 0;
        return newAveragePrice;
      });
    }
  };

  const handleChangeProfile = (e: any, index: number, type: 'idPps' | 'idPpt') => {
    const newAllocations = fullAllocations?.map((el, i) => {
      const updatedValue = e === undefined ? undefined : String(e);
      return {
        ...el,
        [type]: i === index ? updatedValue : el[type],
        userId: i === index ? undefined : el.userId,
      };
    });

    setFullAllocations(newAllocations);
    filterAllocationsByYear(currentyear, newAllocations);
    setDraftValidation(false);
  };

  const handleChangeCollaborator = (e: any, index: number) => {
    const targetCollaborator = collaborators?.find((el) => el.id === e);
    const idPps = targetCollaborator?.idPps ? String(targetCollaborator.idPps) : undefined;
    const idPpt = targetCollaborator?.idPpt ? String(targetCollaborator.idPpt) : undefined;
    const name = targetCollaborator?.nominativo;

    const newAllocations = fullAllocations?.map((el, i) => ({
      ...el,
      userId: i === index ? (e ? String(e) : undefined) : el.userId,
      name: i === index ? name : el.name,
      idPps: i === index ? String(idPps) : el.idPps,
      idPpt: i === index ? String(idPpt) : el.idPpt,
    }));
    setFullAllocations(newAllocations);
    filterAllocationsByYear(currentyear, newAllocations);
    setDraftValidation(false);
  };

  const getOptions = (idPps: number | string | undefined, idPpt: number | string | undefined) => {
    if (!collaborators || collaborators.length === 0) return [];
    const selectedIds = fullAllocations?.map((el) => el.userId);
    const filteredColalborators = collaborators?.filter((el) => !selectedIds?.includes(String(el.id)));
    if (!idPps || !idPpt) {
      return filteredColalborators.map((el) => ({ label: el?.nominativo, value: el?.id }));
    }
    const options = filteredColalborators?.filter((u) => u.idPps == idPps && u.idPpt == idPpt);
    return options?.map((el) => ({ label: el?.nominativo, value: el?.id })) ?? [];
  };

  const addCollaborator = () => {
    let currentId = -Math.floor(Math.random() * 100000);
    const newAllocations = [
      {
        idPps: undefined,
        idPpt: undefined,
        userId: undefined,
        allocazioni: fullAllocations?.[0]?.allocazioni?.map((el) => ({
          id: currentId--,
          month: el.month,
          year: el.year,
          actualAllocation: 0,
          sellingPrice: undefined,
          editEnable: el.editEnable,
        })),
      },
      ...(fullAllocations ?? []),
    ];
    setFullAllocations(newAllocations);
    filterAllocationsByYear(currentyear, newAllocations);
    setDraftValidation(false);
  };

  const addMonths = () => {
    if (dateRange.length === 0) return;
    const startMonth = dateRange[0];
    const startYear = dateRange[1];
    const endMonth = dateRange[2];
    const endYear = dateRange[3];
    const newAllocations = fullAllocations?.map((el) => {
      const newMonths = [];
      for (let year = startYear; year <= endYear; year++) {
        for (let month = year === startYear ? startMonth : 1; month <= (year === endYear ? endMonth : 12); month++) {
          if (!el.allocazioni?.some((alloc) => alloc.year === year && alloc.month === month)) {
            newMonths.push({
              id: -Math.floor(Math.random() * 100000),
              month: month,
              year: year,
              actualAllocation: 0,
              sellingPrice: undefined,
              editEnable: true,
            });
          }
        }
      }
      return {
        ...el,
        allocazioni: [...(el.allocazioni ?? []), ...newMonths],
      };
    });

    newAllocations?.forEach((el) => {
      el.allocazioni?.sort((a, b) => {
        if (a.year !== b.year) {
          return (a.year ?? 0) - (b.year ?? 0);
        }
        return (a.month ?? 0) - (b.month ?? 0);
      });
    });
    setFullAllocations(newAllocations);
    filterAllocationsByYear(currentyear, newAllocations);
    setDraftValidation(false);
    setYears((prevYears) => {
      const updatedYears = [...prevYears];
      for (let year = startYear; year <= endYear; year++) {
        if (!updatedYears.includes(year)) {
          updatedYears.push(year);
        }
      }
      return updatedYears;
    });
  };
  const disableAllocatedMonths = (current: { year: () => number; month: () => number }) => {
    if (!current || !fullAllocations) {
      return false;
    }
    const year = current.year();
    const month = current.month() + 1;
    return fullAllocations?.some((allocation) =>
      allocation.allocazioni?.some(
        (alloc) => (alloc.year! > year || (alloc.year === year && alloc.month! >= month)) && alloc.editEnable === false
      )
    );
  };

  const deleteCollaborator = (index: number, idProject: number | undefined) => {
    if (!fullAllocations) return;
    if (fullAllocations[index].allocazioni?.every((el) => el.id && el.id < 0)) {
      const newFullAllocations = [...fullAllocations];
      newFullAllocations.splice(index, 1);
      setFullAllocations(newFullAllocations);
      filterAllocationsByYear(currentyear, newFullAllocations);
      setDraftValidation(false);
    } else {
      if (!idProject) return;
      const request: DeleteStaffRequest = {
        idProject: idProject,
        deleteStaffInputDto: {
          userIdToDelete: [fullAllocations[index]?.userId ?? ''],
        },
      };
      dispatch(deleteStaff(request)).then((response) => {
        if (response) {
          refreshData(idProject);
        }
      });
    }
  };

  const refreshData = (idProject: number) => {
    dispatch(getProjectDetails(idProject))
      .unwrap()
      .then((res) => {
        if (res) {
          setRefresh(true);
          if (res.staffList?.length === 0) {
            dispatch(saveTeamData(null));
          }
        }
      });
  };

  return {
    handleChangeAllocation,
    handleChangeTotalGU,
    handleChangeCollaborator,
    handleChangeProfile,
    getOptions,
    addMonths,
    addCollaborator,
    deleteCollaborator,
    disableAllocatedMonths,
  };
};
export default useStaffManagement;

import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DatePicker, Input, Select } from 'antd';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import LinksButton from 'shared/design-system/components/links-button';
import useGetPassiveCycleOptions from 'pages/private/passive-cycle/_hooks/useGetPassiveCycleOptions';
import { filtersPassiveAssociableInvoicesUsedSelector } from 'pages/private/active-cycle/active-cycle-bills/_redux/selectors';
import { saveFiltersPassiveAssociableInvoicesUsed } from 'pages/private/active-cycle/active-cycle-bills/_redux/actions';
import dayjs from 'dayjs';
import { filterOption } from 'shared/utils/common.utils';
import PriceRange from 'shared/design-system/components/custom-priceRange';
import { useState } from 'react';

export const associablePassiveInvoicesFormData: any = {
  fkFatturaRicevuta: undefined,
  startEndRange: [],
  supplier: undefined,
  legalEntity: undefined,
  frNumDocumento: undefined,
  priceRange: [null, null],
};

interface Props {
  onSubmitInvoices: any;
  handleResetInvoices: () => void;
}
const AssociablePassiveInvoicesFilterForm = ({ onSubmitInvoices, handleResetInvoices }: Props) => {
  const { RangePicker } = DatePicker;
  const dispatch = useAppDispatch();
  const filterUsed = useAppSelector(filtersPassiveAssociableInvoicesUsedSelector);
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions('passive');
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: associablePassiveInvoicesFormData,
    values: filterUsed,
  });
  const [clearPR, setClearPR] = useState(false);

  const clearPriceRange = () => {
    setClearPR(!clearPR);
  };

  const Reset = () => {
    reset();
    handleResetInvoices();
    clearPriceRange();
  };

  const handleFormSubmit = async (formData: any) => {
    dispatch(saveFiltersPassiveAssociableInvoicesUsed(formData));
    try {
      await onSubmitInvoices(formData);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Col sm="3">
          <Form.Label>{t('ID Invoice')}</Form.Label>
          <Controller
            name="fkFatturaRicevuta"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('Search ID Invoice')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          {' '}
          <Form.Label>{t('Start/End date')}</Form.Label>
          <Controller
            name="startEndRange"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <RangePicker
                placeholder={[
                  `${t('project-list.start-date-placeholder')}`,
                  `${t('project-list.end-date-placeholder')}`,
                ]}
                className="w-100 createDateRangePicker"
                popupClassName="createDateRangePicker"
                {...field}
                ref={field.ref}
                name={field.name}
                onBlur={field.onBlur}
                format="DD/MM/YYYY"
                value={field.value && field.value.length === 2 ? [dayjs(field.value[0]), dayjs(field.value[1])] : null}
                size="large"
                onChange={(dates: any) => {
                  field.onChange(dates ? dates.map((date: any) => date.format('YYYY-MM-DD')) : []);
                }}
              />
            )}
          />
        </Col>
        <Col sm="3">
          {' '}
          <Form.Label>{t('Supplier')}</Form.Label>
          <Controller
            name="supplier"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder={t('Select supplier')}
                size="large"
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                className="w-100"
                {...field}
              >
                {mappedPassiveCycleOptions?.fornitoriList?.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.denominazione}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col sm="3">
          {' '}
          <Form.Label>{t('Legal entity')}</Form.Label>
          <Controller
            name="legalEntity"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder={t('Select legal entity')}
                size="large"
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                className="w-100"
                {...field}
              >
                {mappedPassiveCycleOptions?.aziendaList?.map((entity) => (
                  <Select.Option key={entity.id} value={entity.id}>
                    {entity.nome}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="3">
          <Form.Label>{t('Document number')}</Form.Label>
          <Controller
            name="frNumDocumento"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('Search document number')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('Causal description')}</Form.Label>
          <Controller
            name="frDescrizioneCausale"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('Search causal description')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('project-list.import')}</Form.Label>
          <div>
            <Controller
              name="priceRange"
              control={control}
              render={({ field }) => (
                <PriceRange
                  value={field.value}
                  onPriceRangeChange={(newValue) => field.onChange(newValue)}
                  clear={clearPR}
                />
              )}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex mt-3">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};
export default AssociablePassiveInvoicesFilterForm;

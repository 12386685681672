import { useTranslation } from 'react-i18next';
import { ManagementToolsTabs, Session } from 'enums/project.enum';
import { TabsProps } from 'antd';
import { useState } from 'react';

const useCdgTabs = (session: Session) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>(ManagementToolsTabs.SENDING_PROGRESS_DATA);

  const cdgTabs: TabsProps['items'] =
    session === Session.CDG
      ? [
          {
            key: ManagementToolsTabs.SENDING_PROGRESS_DATA,
            label: t('common.closing-data'),
          },
          { key: ManagementToolsTabs.REFRESH_HR_COSTS, label: t('common.refresh-hr-costs') },
        ]
      : [];

  const onChangeTab = (key: string) => {
    setSelectedTab(key);
  };

  return { selectedTab, cdgTabs, onChangeTab };
};

export default useCdgTabs;

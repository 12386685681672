/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectCLDtoFromJSON, ProjectCLDtoToJSON, } from './ProjectCLDto';
/**
 * Check if a given object implements the ListProjectCLDto interface.
 */
export function instanceOfListProjectCLDto(value) {
    return true;
}
export function ListProjectCLDtoFromJSON(json) {
    return ListProjectCLDtoFromJSONTyped(json, false);
}
export function ListProjectCLDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectList': json['projectList'] == null ? undefined : (json['projectList'].map(ProjectCLDtoFromJSON)),
    };
}
export function ListProjectCLDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectList': value['projectList'] == null ? undefined : (value['projectList'].map(ProjectCLDtoToJSON)),
    };
}

import { Radio } from 'antd';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { feeRateTableSelector } from '../../_redux/selectors';
import { useTranslation } from 'react-i18next';
import { setFeeRateTable } from '../../_redux/actions';

interface Props {
  setVisibleColumns: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}

const FeeRateSwitch: React.FC<Props> = ({ setVisibleColumns }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const feeRateTable = useAppSelector(feeRateTableSelector);
  const [selectedValue, setSelectedValue] = useState<string>(feeRateTable);

  const options: { label: string; value: string }[] = [
    { label: t('Allocations'), value: 'allocations' },
    { label: t('Selling price'), value: 'selling_price' },
  ];

  useEffect(() => {
    dispatch(setFeeRateTable(selectedValue));
    setVisibleColumns((prev) => ({
      ...prev,
      worked: selectedValue === 'allocations',
      totalPlanned: selectedValue === 'allocations',
    }));
  }, [selectedValue]);

  return (
    <Radio.Group
      options={options}
      defaultValue="allocations"
      optionType="button"
      className="ms-1"
      onChange={(e) => setSelectedValue(e.target.value)}
    />
  );
};

export default FeeRateSwitch;

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractOrderFromJSON, ContractOrderToJSON, } from './ContractOrder';
import { ExternalCostFromJSON, ExternalCostToJSON, } from './ExternalCost';
import { LightProjectFromJSON, LightProjectToJSON, } from './LightProject';
/**
 * Check if a given object implements the FattureProgetto interface.
 */
export function instanceOfFattureProgetto(value) {
    return true;
}
export function FattureProgettoFromJSON(json) {
    return FattureProgettoFromJSONTyped(json, false);
}
export function FattureProgettoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'pkFattura': json['pkFattura'] == null ? undefined : json['pkFattura'],
        'idGroup': json['idGroup'] == null ? undefined : json['idGroup'],
        'idQuote': json['idQuote'] == null ? undefined : json['idQuote'],
        'idCostType': json['idCostType'] == null ? undefined : json['idCostType'],
        'idProject': json['idProject'] == null ? undefined : json['idProject'],
        'idOrdine': json['idOrdine'] == null ? undefined : json['idOrdine'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'lastModifiedDate': json['lastModifiedDate'] == null ? undefined : (new Date(json['lastModifiedDate'])),
        'associatedOrders': json['associatedOrders'] == null ? undefined : ContractOrderFromJSON(json['associatedOrders']),
        'associatedProjects': json['associatedProjects'] == null ? undefined : LightProjectFromJSON(json['associatedProjects']),
        'externalCosts': json['externalCosts'] == null ? undefined : (json['externalCosts'].map(ExternalCostFromJSON)),
    };
}
export function FattureProgettoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'pkFattura': value['pkFattura'],
        'idGroup': value['idGroup'],
        'idQuote': value['idQuote'],
        'idCostType': value['idCostType'],
        'idProject': value['idProject'],
        'idOrdine': value['idOrdine'],
        'amount': value['amount'],
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'lastModifiedDate': value['lastModifiedDate'] == null ? undefined : ((value['lastModifiedDate']).toISOString()),
        'associatedOrders': ContractOrderToJSON(value['associatedOrders']),
        'associatedProjects': LightProjectToJSON(value['associatedProjects']),
        'externalCosts': value['externalCosts'] == null ? undefined : (value['externalCosts'].map(ExternalCostToJSON)),
    };
}

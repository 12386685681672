import { ProjectElencoAssetDto } from '@api/client';
import { Dispatch, SetStateAction } from 'react';

const useAddProjectAsset = (idProject: number, setProjectAssets: Dispatch<SetStateAction<ProjectElencoAssetDto[]>>) => {
  const addProjectAsset = () => {
    const newAsset: ProjectElencoAssetDto = {
      idProject,
      idElencoAsset: undefined,
      importo: undefined,
    };
    setProjectAssets((prev) => [...prev, newAsset]);
  };

  return { addProjectAsset };
};

export default useAddProjectAsset;

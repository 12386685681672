/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ProjectCLDto interface.
 */
export function instanceOfProjectCLDto(value) {
    return true;
}
export function ProjectCLDtoFromJSON(json) {
    return ProjectCLDtoFromJSONTyped(json, false);
}
export function ProjectCLDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'intranetId': json['intranetId'] == null ? undefined : json['intranetId'],
        'name': json['name'] == null ? undefined : json['name'],
        'projectLead': json['projectLead'] == null ? undefined : json['projectLead'],
        'totalBidAmount': json['totalBidAmount'] == null ? undefined : json['totalBidAmount'],
        'denominazioneCliente': json['denominazioneCliente'] == null ? undefined : json['denominazioneCliente'],
        'fkAzienda': json['fkAzienda'] == null ? undefined : json['fkAzienda'],
    };
}
export function ProjectCLDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'intranetId': value['intranetId'],
        'name': value['name'],
        'projectLead': value['projectLead'],
        'totalBidAmount': value['totalBidAmount'],
        'denominazioneCliente': value['denominazioneCliente'],
        'fkAzienda': value['fkAzienda'],
    };
}

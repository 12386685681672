/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ProjectElencoAssetDto interface.
 */
export function instanceOfProjectElencoAssetDto(value) {
    return true;
}
export function ProjectElencoAssetDtoFromJSON(json) {
    return ProjectElencoAssetDtoFromJSONTyped(json, false);
}
export function ProjectElencoAssetDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'idProject': json['idProject'] == null ? undefined : json['idProject'],
        'idElencoAsset': json['idElencoAsset'] == null ? undefined : json['idElencoAsset'],
        'costo': json['costo'] == null ? undefined : json['costo'],
        'importo': json['importo'] == null ? undefined : json['importo'],
        'titoloAsset': json['titoloAsset'] == null ? undefined : json['titoloAsset'],
    };
}
export function ProjectElencoAssetDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'idProject': value['idProject'],
        'idElencoAsset': value['idElencoAsset'],
        'costo': value['costo'],
        'importo': value['importo'],
        'titoloAsset': value['titoloAsset'],
    };
}

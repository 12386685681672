import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { getBillingFields } from '../_redux/actions';
import { billingFieldsSelector } from '../_redux/selectors';
import { BillingOption, MappedBillingOptions, mappedTipologiaBloccoEnumList } from 'enums/billingOptions';

const useGetBillingOptions = () => {
  const dispatch = useAppDispatch();
  const billingOptionsSelector = useAppSelector(billingFieldsSelector);
  const [mappedBillingOptions, setMappedBillingOptions] = useState<MappedBillingOptions | undefined>(undefined);

  useEffect(() => {
    if (!billingOptionsSelector) dispatch(getBillingFields());
  }, []);

  useEffect(() => {
    if (billingOptionsSelector) {
      const { tipologiaBloccoEnumList } = billingOptionsSelector || {};
      const mapItems = (enumList: string[] | undefined, mappedList: BillingOption[] | undefined) =>
        enumList?.map((item) => ({
          value: item,
          label: mappedList?.find((mappedItem) => mappedItem.value === item)?.label || item,
        })) || [];
      setMappedBillingOptions({
        mappedTipologiaBloccoEnumList: mapItems(tipologiaBloccoEnumList, mappedTipologiaBloccoEnumList),
      });
    }
  }, [billingOptionsSelector]);

  return { mappedBillingOptions };
};

export default useGetBillingOptions;

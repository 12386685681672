import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import {
  AggiornaCostiRendicontazioniRequest,
  CdgProjectOtherCostsRequest,
  CdgProjectOtherCostsUpdateRequest,
  ClProjectOtherCostsRequest,
  ClProjectOtherCostsUpdateRequest,
  CloseEconomicsProjectRequest,
  CompletedProjectRequest,
  Configuration,
  ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi,
  ControllerPerLeFunzioniDedicateAlControlloDiGestioneApi,
  ControllerPerLeFunzioniDedicateAlPlApi,
  FilterProjectMonthDto,
  FilterProjectMonthValuesDto,
  OtherCostListDto,
  ProjectMonthEditRequest,
  ProjectMonthListDto,
  ProjectMonthSearchRequest,
} from '@api/client';
import getConfiguration, { errorHandler } from 'core/store/config';

enum ManagementToolsActions {
  getCdgFilters = '[CDG] Get Filters',
  editCdgProjects = '[CDG] Edit Projects',
  getCdgProjects = '[CDG] Get Projects',
  saveCdgFiltersUsed = '[CDG] Save Filters Used',
  getCdgOtherCosts = '[CDG] Get Other Costs',
  saveCdgOtherCosts = '[CDG] Save Other Costs',
  refreshHrCosts = '[CDG] Refresh Hr Costs',
  completeProjectCdg = '[CDG] Complete Project',
  getClFilters = '[CL] Get Filters',
  editClProjects = '[CL] Edit Projects',
  getClProjects = '[CL] Get Projects',
  saveClFiltersUsed = '[CL] Save Filters Used',
  getClOtherCosts = '[CL] Get Other Costs',
  saveClOtherCosts = '[CL] Save Other Costs',
  closeEconomicsCl = '[CL] Close Economics',
  getPlFilters = '[PL] Get Filters',
  editPlProjects = '[PL] Edit Projects',
  getPlProjects = '[PL] Get Projects',
  savePlFiltersUsed = '[PL] Save Filters Used',
  getPlOtherCosts = '[PL] Get Other Costs',
}

/* #################### CDG #################### */

export const getCdgFilters = createAsyncThunk<FilterProjectMonthValuesDto | undefined, void, ThunkApiConfig>(
  ManagementToolsActions.getCdgFilters,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlControlloDiGestioneApi(
        getConfiguration(Configuration, extra, getState)
      ).projectMonthSearchFilters();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const editCdgProjects = createAsyncThunk<string | undefined, ProjectMonthEditRequest, ThunkApiConfig>(
  ManagementToolsActions.editCdgProjects,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlControlloDiGestioneApi(
        getConfiguration(Configuration, extra, getState)
      ).projectMonthEdit(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getCdgProjects = createAsyncThunk<
  ProjectMonthListDto | undefined,
  ProjectMonthSearchRequest,
  ThunkApiConfig
>(ManagementToolsActions.getCdgProjects, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlControlloDiGestioneApi(
      getConfiguration(Configuration, extra, getState)
    ).projectMonthSearch(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getCdgOtherCosts = createAsyncThunk<
  OtherCostListDto | undefined,
  CdgProjectOtherCostsRequest,
  ThunkApiConfig
>(ManagementToolsActions.getCdgOtherCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlControlloDiGestioneApi(
      getConfiguration(Configuration, extra, getState)
    ).cdgProjectOtherCosts(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const saveCdgOtherCosts = createAsyncThunk<
  OtherCostListDto | undefined,
  CdgProjectOtherCostsUpdateRequest,
  ThunkApiConfig
>(ManagementToolsActions.saveCdgOtherCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlControlloDiGestioneApi(
      getConfiguration(Configuration, extra, getState)
    ).cdgProjectOtherCostsUpdate(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const refreshHrCosts = createAsyncThunk<string | undefined, AggiornaCostiRendicontazioniRequest, ThunkApiConfig>(
  ManagementToolsActions.refreshHrCosts,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlControlloDiGestioneApi(
        getConfiguration(Configuration, extra, getState)
      ).aggiornaCostiRendicontazioni(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const completeProjectCdg = createAsyncThunk<string | undefined, CompletedProjectRequest, ThunkApiConfig>(
  ManagementToolsActions.completeProjectCdg,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlControlloDiGestioneApi(
        getConfiguration(Configuration, extra, getState)
      ).completedProject(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

/* #################### CL #################### */

export const getClFilters = createAsyncThunk<FilterProjectMonthValuesDto | undefined, void, ThunkApiConfig>(
  ManagementToolsActions.getClFilters,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi(
        getConfiguration(Configuration, extra, getState)
      ).clProjectMonthSearchFilters();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const editClProjects = createAsyncThunk<string | undefined, ProjectMonthEditRequest, ThunkApiConfig>(
  ManagementToolsActions.editClProjects,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi(
        getConfiguration(Configuration, extra, getState)
      ).clProjectMonthEdit(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getClProjects = createAsyncThunk<
  ProjectMonthListDto | undefined,
  ProjectMonthSearchRequest,
  ThunkApiConfig
>(ManagementToolsActions.getClProjects, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi(
      getConfiguration(Configuration, extra, getState)
    ).clProjectMonthSearch(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getClOtherCosts = createAsyncThunk<
  OtherCostListDto | undefined,
  ClProjectOtherCostsRequest,
  ThunkApiConfig
>(ManagementToolsActions.getClOtherCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi(
      getConfiguration(Configuration, extra, getState)
    ).clProjectOtherCosts(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const saveClOtherCosts = createAsyncThunk<
  OtherCostListDto | undefined,
  ClProjectOtherCostsUpdateRequest,
  ThunkApiConfig
>(ManagementToolsActions.saveClOtherCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi(
      getConfiguration(Configuration, extra, getState)
    ).clProjectOtherCostsUpdate(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const closeEconomicsCl = createAsyncThunk<string | undefined, CloseEconomicsProjectRequest, ThunkApiConfig>(
  ManagementToolsActions.closeEconomicsCl,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi(
        getConfiguration(Configuration, extra, getState)
      ).closeEconomicsProject(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

/* #################### PL #################### */

export const getPlFilters = createAsyncThunk<FilterProjectMonthValuesDto | undefined, void, ThunkApiConfig>(
  ManagementToolsActions.getPlFilters,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlPlApi(
        getConfiguration(Configuration, extra, getState)
      ).plProjectMonthSearchFilters();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const editPlProjects = createAsyncThunk<string | undefined, ProjectMonthEditRequest, ThunkApiConfig>(
  ManagementToolsActions.editPlProjects,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlPlApi(
        getConfiguration(Configuration, extra, getState)
      ).plProjectMonthEdit(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getPlProjects = createAsyncThunk<
  ProjectMonthListDto | undefined,
  ProjectMonthSearchRequest,
  ThunkApiConfig
>(ManagementToolsActions.getPlProjects, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlPlApi(
      getConfiguration(Configuration, extra, getState)
    ).plProjectMonthSearch(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getPlOtherCosts = createAsyncThunk<
  OtherCostListDto | undefined,
  ClProjectOtherCostsRequest,
  ThunkApiConfig
>(ManagementToolsActions.getPlOtherCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlPlApi(
      getConfiguration(Configuration, extra, getState)
    ).plProjectOtherCosts(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const saveCdgFiltersUsed = createAction<FilterProjectMonthDto>(ManagementToolsActions.saveCdgFiltersUsed);
export const saveClFiltersUsed = createAction<FilterProjectMonthDto>(ManagementToolsActions.saveClFiltersUsed);
export const savePlFiltersUsed = createAction<FilterProjectMonthDto>(ManagementToolsActions.savePlFiltersUsed);

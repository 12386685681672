import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'core/store/hooks';
import { useState } from 'react';
import { completeProjectCdg } from '../_redux/actions';
import { CompletedProjectRequest } from '@api/client';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';

const useCompleteProject = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showConfirmCompleteProjectModal, setShowConfirmCompleteProjectModal] = useState(false);
  const [idToComplete, setIdToComplete] = useState<number | null>(null);

  const completeProject = () => {
    setShowConfirmCompleteProjectModal(false);
    const payload: CompletedProjectRequest = {
      projectId: idToComplete as number,
    };
    dispatch(completeProjectCdg(payload)).then((res) => {
      if (res) {
        AppToastService.success(t('common.action-completed-successfully'));
      }
    });
  };

  return {
    completeProject,
    showConfirmCompleteProjectModal,
    setShowConfirmCompleteProjectModal,
    setIdToComplete,
  };
};

export default useCompleteProject;

import { Row, Col, Badge } from 'react-bootstrap';
import './styles.scss';
import { DettaglioProjectDto } from '@api/client';
import { useState } from 'react';
import useProjectFields, { Field, verifycolor } from './_hooks/useProjectFields';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import CommonAccordion from 'shared/design-system/components/common-accordion';
import { typologiesData } from 'enums/typologies';

interface Props {
  projectDetails: DettaglioProjectDto | null;
}

const ProjectData: React.FC<Props> = ({ projectDetails }: Props) => {
  const { t } = useTranslation();
  const currentStatus = projectDetails?.dettaglioOfferta?.stato;
  const fields = useProjectFields(projectDetails, currentStatus);

  return (
    <CommonAccordion
      header={t('project-detail.project-data')}
      isDefaultOpened={false}
      headerContent={
        <>
          {fields.map((field: Field, index) => (
            <Col sm={'auto'} key={field.value + index} className="g-0">
              <div className="d-flex align-items-baseline">
                <div className="big-value">
                  {field.value !== null && field.value !== undefined ? (
                    <>
                      <span className={`${verifycolor(field)} mr-1 fw-bold`}>{field.value.split('/')[0]}</span>{' '}
                      {field.value.includes('/') && <span>/</span>}
                      <span className="fs-8"> {field.value.split('/')[1]}</span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
                {field.span && field.value !== null && field.value !== undefined && field.value !== '-' && (
                  <span className="text-xs">{field.span}</span>
                )}
                <span className="ms-2">{field.icon && <i className={field.icon as string}></i>}</span>
              </div>
              <div className="head notification-header">{field.label}</div>
            </Col>
          ))}
        </>
      }
    >
      <Row>
        <Col xs={8} className="vertical-divider">
          <div className="d-flex flex-column">
            <Row className="">
              <Col xs={4}>
                <div className="span-values">
                  <div className="head">{t('project-detail.id-opportunity')}</div>
                  <div className="value">
                    {projectDetails?.dettaglioOfferta?.externalOpportunityId ? (
                      <Tooltip title={t('project-detail.link-tooltip')} color="white">
                        <a
                          href={`https://links.lightning.force.com/lightning/r/Opportunity/${projectDetails.dettaglioOfferta.externalOpportunityId}/view`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#22519e' }}
                        >
                          {projectDetails.dettaglioOfferta.externalOpportunityId}
                        </a>
                      </Tooltip>
                    ) : (
                      '-'
                    )}
                  </div>{' '}
                </div>
              </Col>
              <Col xs={4}>
                <div className="span-values">
                  <div className="head">{t('project-detail.opportunity-name')}</div>
                  <div className="value">{projectDetails?.dettaglioOfferta?.name || '-'}</div>
                </div>
              </Col>
              <Col xs={4}>
                <div className="span-values">
                  <div className="head">{t('project-detail.customer')}</div>
                  <div className="value">{projectDetails?.dettaglioOfferta?.cliente || '-'}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <div className="span-values mb-0">
                  <div className="head">{t('project-detail.id-quote')}</div>
                  <div className="value">
                    {projectDetails?.dettaglioOfferta?.externalQuoteId ? (
                      <Tooltip title={t('project-detail.link-tooltip')} color="white">
                        <a
                          href={`https://links.lightning.force.com/lightning/r/Quote/${projectDetails.dettaglioOfferta.externalQuoteId}/view`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#22519e' }}
                        >
                          {projectDetails.dettaglioOfferta.externalQuoteId}
                        </a>
                      </Tooltip>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={4}>
                <div className="span-values mb-0">
                  <div className="head">{t('project-detail.project-category')}</div>
                  <div className="">
                    {
                      <Badge className="badge-teal">
                        {typologiesData
                          .find((el) => el.name === projectDetails?.dettaglioOfferta?.categoria)
                          ?.label?.toUpperCase() || '-'}
                      </Badge>
                    }
                  </div>
                </div>
              </Col>
              <Col xs={4}>
                <div className="span-values mb-0">
                  <div className="head">{t('project-detail.period')}</div>
                  <div className="value">{projectDetails?.dettaglioOfferta?.periodo || '-'}</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={4}>
          <Row className="ms-3 my-2">
            <Col xs={6}>
              <div className="span-values">
                <div className="head">{t('project-detail.competence-center')}</div>
                <div className="value">{projectDetails?.dettaglioOfferta?.competenceCenters || '-'}</div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="d-flex mb-3 ">
                <button
                  disabled={!projectDetails?.dettaglioOfferta?.hasOda}
                  className="download btn btn-sm btn-links-no-border d-flex align-items-top p-0"
                >
                  <i className="icon-file-download me-2"></i>
                  <p className="">{t('project-detail.download-documentation')}</p>
                </button>
              </div>
            </Col>
          </Row>
          <Row className="ms-3">
            <Col xs={6}>
              <div className="span-values">
                <div className="head">{t('project-detail.account-manager')}</div>
                <div className="value">{projectDetails?.dettaglioOfferta?.accountManager || '-'}</div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="span-values">
                <div className="head">{t('project-detail.project-manager')}</div>
                <div className="value">{projectDetails?.dettaglioOfferta?.projectManager || '-'}</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonAccordion>
  );
};

export default ProjectData;

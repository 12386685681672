import { Trans } from 'react-i18next';

interface ServerSidePaginatorProps {
  currentPage: number;
  totalPages: number;
  totalElements?: number;
  elementsToShow?: number;
  onPageChange: (page: number) => void;
}

const ServerSidePaginator = ({
  currentPage,
  totalPages,
  totalElements,
  elementsToShow,
  onPageChange,
}: ServerSidePaginatorProps) => {
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  return (
    <div className="row">
      <div className="col-6 d-flex align-items-center">
        <Trans i18nKey="common.paginator.results-found" values={{ totalElements }} components={{ b: <b /> }} />
      </div>

      <div className="col-6 d-flex  justify-content-end mt-2">
        <div className="me-3 mt-2">
          <Trans
            i18nKey="common.paginator.displayed"
            values={{ elementsToShow }}
            components={{ b: <b className="text-blue-links" /> }}
          />
        </div>
        <button
          className="me-2 border-primary btn text-blue-links btn-links-pagination "
          onClick={() => handlePageChange(0)}
          disabled={currentPage === 0}
        >
          <i className="fa fa-angle-double-left" aria-hidden="true"></i>
        </button>

        <button
          className="me-2 border-primary btn text-blue-links btn-links-pagination "
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </button>

        <button
          className="ms-2 border-primary btn text-blue-links btn-links-pagination "
          disabled={currentPage === 0}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          {currentPage + 1}
        </button>

        {currentPage + 1 < totalPages && (
          <button
            className="ms-2 border-primary btn text-blue-links btn-links-pagination "
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {currentPage + 2}
          </button>
        )}

        <button
          className="ms-2 border-primary btn text-blue-links btn-links-pagination "
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages - 1 || totalPages === 0}
        >
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </button>

        <button
          className="ms-2 border-primary btn text-blue-links btn-links-pagination "
          onClick={() => handlePageChange(totalPages - 1)}
          disabled={currentPage === totalPages - 1 || totalPages === 0}
        >
          <i className="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
};

export default ServerSidePaginator;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

const productsAndSubscriptionsReducer = (state: RootState) => state.private.productsAndSubscriptions;

export const assetsSelector = createSelector(
  productsAndSubscriptionsReducer,
  (productsAndSubscriptions) => productsAndSubscriptions.assets
);
export const projectAssetsSelector = createSelector(
  productsAndSubscriptionsReducer,
  (productsAndSubscriptions) => productsAndSubscriptions.projectAssets
);

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Table } from 'react-bootstrap';
import { formatCurrency } from 'shared/utils/common.utils';
import { useEffect, useState } from 'react';
import NoData from 'shared/design-system/components/no-data-box';
import { useTranslation } from 'react-i18next';
import { PathEnum } from 'core/router/path.enum';
import usePagination from 'core/hooks/usePagination';
import AppPaginator from 'shared/design-system/components/app-paginator';
import { StorageKeysEnum } from 'enums/storageKeys.enum';
import AppAccordion from 'shared/design-system/components/app-accordion';
import useFormDashboardProjects from 'shared/design-system/components/app-accordion/_hooks/useFormDashboardProjects';
import FiltersDashboardProjects, { filtersDashboardProjectsData } from '../_components/filters-dashboard-project';
import useGetProjectTotals, { TotalsGroup } from '../_hooks/projects-hooks/useGetProjectTotals';
import './styles.scss';
import { DashboardProjectsPeriod } from 'enums/project.enum';
import useGetDashboardProjects from '../_hooks/projects-hooks/useGetDashboardProjects';
import useSortDashboardProjects from '../_hooks/projects-hooks/useSortDashboardProjects';
import TotalsCard from '../_components/totals-card';
import useGetDashboardProjectsExcelData from '../_hooks/projects-hooks/useGetDashboardProjectsExcelData';
import ExcelExport from 'shared/design-system/components/excel-export';
import { LocationFilterEnum } from 'enums/global.enum';

interface Props {
  period: DashboardProjectsPeriod;
}

const ProjectTable: React.FC<Props> = ({ period }) => {
  const { t } = useTranslation();
  const itemsPerPage = 50;

  const [sort, setSort] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useFormDashboardProjects(
    filtersDashboardProjectsData,
    period
  );

  const { projects, setProjects, tableProps } = useGetDashboardProjects(period);
  const { excelData } = useGetDashboardProjectsExcelData(projects);

  const {
    pageNumber: pageNumberProject,
    setPageNumber: setPageNumberProject,
    totalPages: totalPagesProject,
    visibleItems: visibleProjects,
  } = usePagination(projects, itemsPerPage, null, sort);

  const [visProj, setVisProj] = useState(visibleProjects);

  const { totals } = useGetProjectTotals(period);

  const { sortItems } = useSortDashboardProjects(projects, setProjects);

  useEffect(() => {
    if (visibleProjects) {
      setVisProj(visibleProjects);
    }
  }, [visibleProjects]);

  const handleReset = () => {
    onReset();
    chipsFormData && setChipsFormData([]);
  };

  return (
    <div>
      <AppAccordion
        formData={filtersDashboardProjectsData}
        location={LocationFilterEnum.DASHBOARD_PROJECTS}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        chipsFormData={chipsFormData}
        setChipsFormData={setChipsFormData}
        reset={reset}
      >
        <FiltersDashboardProjects onSubmit={onSubmit} handleReset={handleReset} />
      </AppAccordion>
      {projects.length === 0 ? (
        <div className="mt-3">
          <NoData msg={t('project-list.no-projects')} />
        </div>
      ) : (
        <>
          {projects.length && (
            <div className="d-flex justify-content-end mb-3">
              <ExcelExport data={excelData} fileName="Projects" />
            </div>
          )}
          <div className="d-flex justify-content-between">
            {totals.map((totals: TotalsGroup, index) => (
              <TotalsCard totalsGroup={totals} key={index} />
            ))}
          </div>

          <div className="dashboard-project-table-container mt-3">
            <Table bordered hover className="table-bordered">
              <thead>
                <tr>
                  {tableProps.columns.map((column: any) => (
                    <th
                      className="align-content-center"
                      key={column.key}
                      style={{
                        background: column.headerColor,
                        fontSize: '12px',
                        fontWeight: column.headerFontWeight,
                        color: column.textHeaderColor,
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div>{column.title}</div>{' '}
                        <i
                          className={`fas fa-sort ${column.textHeaderColor ? 'text-white' : 'text-blue-links'} ms-2`}
                          role="button"
                          onClick={() => sortItems(column.key)}
                        ></i>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {visProj.map((row: any, index: number) => (
                  <tr key={index}>
                    {tableProps.columns.map((column: any) => (
                      <td
                        key={column.key}
                        style={{ background: column.color, fontSize: '12px' }}
                        className={['amount', 'perc'].includes(column.type) ? 'text-end' : ''}
                      >
                        {column.key === 'intranetId' ? (
                          <a
                            href={`${window.location.origin}${window.location.pathname}#/${PathEnum.PRIVATE}/${PathEnum.PROGETTI}/${row?.projectId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-links"
                          >
                            {row[column.key]}
                          </a>
                        ) : column.type === 'perc' ? (
                          formatCurrency(row[column.key] * 100)
                        ) : column.type === 'amount' ? (
                          formatCurrency(row[column.key])
                        ) : (
                          row[column.key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {visibleProjects?.length > 0 ? (
            <AppPaginator
              keyPaginator={StorageKeysEnum.CURRENT_PAGE_DASHBOARD_PROJECTS}
              currentPage={pageNumberProject}
              totalPages={totalPagesProject}
              totalElements={projects.length}
              pageSize={itemsPerPage}
              onPageChange={setPageNumberProject}
            />
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default ProjectTable;

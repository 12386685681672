import React from 'react';
import { RouterProvider, Navigate, createHashRouter } from 'react-router-dom';
import AuthGuard from './core/guards/auth.guard';
import PrivatePage from './pages/private';
import AuthPage from './pages/auth';
import { PathEnum } from './core/router/path.enum';
import { HOSTNAME_PRODUCTION, HOSTNAME_WEB_SVIL } from 'env';

const router = createHashRouter([
  {
    path: '/',
    element: <Navigate to={PathEnum.PRIVATE} />,
  },
  {
    path: '*',
    element: <Navigate to={PathEnum.PRIVATE} />,
  },
  ...(window.location.hostname !== HOSTNAME_PRODUCTION && window.location.hostname !== HOSTNAME_WEB_SVIL
    ? [
        {
          path: `${PathEnum.AUTH}/*`,
          element: <AuthPage />,
        },
      ]
    : []),
  {
    path: `${PathEnum.AUTHSSO}/*`,
    element: <AuthPage />,
  },
  {
    element: <AuthGuard />,
    children: [
      {
        path: `${PathEnum.PRIVATE}/*`,
        element: <PrivatePage />,
      },
    ],
  },
]);

const AppRouter: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;

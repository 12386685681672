import { FilterDtoStatoEnum, FilterProjectMonthDto, FilterProjectMonthValuesDto, ProjectMonthDto } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import {
  editCdgProjects,
  editClProjects,
  editPlProjects,
  getCdgFilters,
  getCdgProjects,
  getClFilters,
  getClProjects,
  getPlFilters,
  getPlProjects,
  saveCdgFiltersUsed,
  saveCdgOtherCosts,
  saveClFiltersUsed,
  saveClOtherCosts,
  savePlFiltersUsed,
} from './actions';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';

const defaultStatesPl = [
  FilterDtoStatoEnum.ReportingClosed,
  FilterDtoStatoEnum.WorkInProgress,
  // FilterDtoStatoEnum.WorkInProgressDraft,
];

const defaultStatesCl = [
  FilterDtoStatoEnum.ControlloCl,
  FilterDtoStatoEnum.ReportingClosed,
  FilterDtoStatoEnum.WorkInProgress,
  // FilterDtoStatoEnum.WorkInProgressDraft,
];

const defaultStatesCdg = [FilterDtoStatoEnum.ControlloCdg, FilterDtoStatoEnum.ReportingClosed];

interface InitialState {
  filtersCdg: FilterProjectMonthValuesDto | null | undefined;
  cdgProjects: ProjectMonthDto[];
  filtersCdgUsed: FilterProjectMonthDto | null | undefined;
  filtersCl: FilterProjectMonthValuesDto | null | undefined;
  clProjects: ProjectMonthDto[];
  filtersClUsed: FilterProjectMonthDto | null | undefined;
  filtersPl: FilterProjectMonthValuesDto | null | undefined;
  plProjects: ProjectMonthDto[];
  filtersPlUsed: FilterProjectMonthDto | null | undefined;
}

const initialState: InitialState = {
  filtersCdg: null,
  cdgProjects: [],
  filtersCdgUsed: {
    stati: defaultStatesCdg,
  },
  filtersCl: null,
  clProjects: [],
  filtersClUsed: {
    stati: defaultStatesCl,
  },
  filtersPl: null,
  plProjects: [],
  filtersPlUsed: {
    stati: defaultStatesPl,
  },
};

export const managementToolsReducer = createReducer(initialState, (builder) => {
  builder
    /* #################### CDG #################### */
    .addCase(getCdgFilters.fulfilled, (state, action) => {
      state.filtersCdg = action.payload;
    })
    .addCase(getCdgProjects.fulfilled, (state, action) => {
      state.cdgProjects =
        action.payload?.projectList?.sort((a, b) => (Number(a.intranetId)! > Number(b.intranetId)! ? 1 : -1)) || [];
    })
    .addCase(editCdgProjects.fulfilled, (state, action) => {
      AppToastService.success('Projects edited successfully');
    })
    .addCase(saveCdgFiltersUsed, (state, action) => {
      state.filtersCdgUsed = action.payload;
    })
    .addCase(saveCdgOtherCosts.fulfilled, (state, action) => {
      AppToastService.success('Other costs saved successfully');
    })
    /* #################### CL #################### */
    .addCase(getClFilters.fulfilled, (state, action) => {
      state.filtersCl = action.payload;
    })
    .addCase(getClProjects.fulfilled, (state, action) => {
      state.clProjects =
        action.payload?.projectList?.sort((a, b) => (Number(a.intranetId)! > Number(b.intranetId)! ? 1 : -1)) || [];
    })
    .addCase(editClProjects.fulfilled, (state, action) => {
      AppToastService.success('Projects edited successfully');
    })
    .addCase(saveClFiltersUsed, (state, action) => {
      state.filtersClUsed = action.payload;
    })
    .addCase(saveClOtherCosts.fulfilled, (state, action) => {
      AppToastService.success('Other costs saved successfully');
    })
    /* #################### PL #################### */
    .addCase(getPlFilters.fulfilled, (state, action) => {
      state.filtersPl = action.payload;
    })
    .addCase(getPlProjects.fulfilled, (state, action) => {
      state.plProjects =
        action.payload?.projectList?.sort((a, b) => (Number(a.intranetId)! > Number(b.intranetId)! ? 1 : -1)) || [];
    })
    .addCase(editPlProjects.fulfilled, (state, action) => {
      AppToastService.success('Projects edited successfully');
    })
    .addCase(savePlFiltersUsed, (state, action) => {
      state.filtersPlUsed = action.payload;
    });
});

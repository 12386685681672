/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MeseAnnoLightFromJSON, MeseAnnoLightToJSON, } from './MeseAnnoLight';
/**
 * Check if a given object implements the UserListFilterDto interface.
 */
export function instanceOfUserListFilterDto(value) {
    return true;
}
export function UserListFilterDtoFromJSON(json) {
    return UserListFilterDtoFromJSONTyped(json, false);
}
export function UserListFilterDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'plUserId': json['plUserId'] == null ? undefined : json['plUserId'],
        'personaId': json['personaId'] == null ? undefined : json['personaId'],
        'projectName': json['projectName'] == null ? undefined : json['projectName'],
        'idProject': json['idProject'] == null ? undefined : json['idProject'],
        'allProject': json['allProject'] == null ? undefined : json['allProject'],
        'personaIdList': json['personaIdList'] == null ? undefined : json['personaIdList'],
        'mese': json['mese'] == null ? undefined : json['mese'],
        'anno': json['anno'] == null ? undefined : json['anno'],
        'mesiAnni': json['mesiAnni'] == null ? undefined : (json['mesiAnni'].map(MeseAnnoLightFromJSON)),
        'isUserActive': json['isUserActive'] == null ? undefined : json['isUserActive'],
        'page': json['page'] == null ? undefined : json['page'],
        'size': json['size'] == null ? undefined : json['size'],
    };
}
export function UserListFilterDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'plUserId': value['plUserId'],
        'personaId': value['personaId'],
        'projectName': value['projectName'],
        'idProject': value['idProject'],
        'allProject': value['allProject'],
        'personaIdList': value['personaIdList'],
        'mese': value['mese'],
        'anno': value['anno'],
        'mesiAnni': value['mesiAnni'] == null ? undefined : (value['mesiAnni'].map(MeseAnnoLightToJSON)),
        'isUserActive': value['isUserActive'],
        'page': value['page'],
        'size': value['size'],
    };
}

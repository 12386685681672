import { AssociaFatturaProgettoRequest, ExternalCostDto, FatturaDettaglioDto, ProjectExternalCost } from '@api/client';
import React, { ChangeEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PassiveCycleProjectInvoiceCard from '../passive-cycle-project-invoice-card';
import LinksButton from 'shared/design-system/components/links-button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'core/store/hooks';
import {
  associateFatturaProject,
  getFattureRicevute,
} from 'pages/private/active-cycle/active-cycle-bills/_redux/actions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  associatedProjects: ProjectExternalCost[] | undefined;
  idOrdine: number | undefined;
  currentInvoiceId: string | undefined;
  readOnly: boolean;
}

const AssociateCostsToPassiveInvoiceModal: React.FC<Props> = ({
  isOpen,
  onClose,
  associatedProjects,
  idOrdine,
  currentInvoiceId,
  readOnly,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [externalCostsToAssociate, setExternalCostsToAssociate] = useState<FatturaDettaglioDto[]>([]);

  const handleAssociateCost = (
    e: ChangeEvent<HTMLInputElement>,
    cost: ExternalCostDto,
    project: ProjectExternalCost
  ) => {
    setExternalCostsToAssociate((prev) => {
      const newCost: FatturaDettaglioDto = {
        idOrdine,
        idCostType: cost.costType?.id,
        idGroup: cost.groupId,
        idProject: project.projectId,
        amount: cost.contractAmount,
      };
      const projectInArray = prev.find(
        (el) => el.idCostType === cost.costType?.id && el.idGroup === cost.groupId && el.idProject === project.projectId
      );
      if (projectInArray) {
        // remove cost
        return prev.filter(
          (item) =>
            item.idCostType !== projectInArray.idCostType ||
            item.idGroup !== projectInArray.idGroup ||
            item.idProject !== projectInArray.idProject
        );
      } else {
        return [...prev, newCost];
      }
    });
  };

  const saveAssociation = () => {
    const payload: AssociaFatturaProgettoRequest = {
      associaFattureProgettoDtoList: {
        fattureProgettoDtoList: [
          {
            pkFattura: currentInvoiceId,
            dettagli: externalCostsToAssociate,
          },
        ],
      },
    };
    dispatch(associateFatturaProject(payload))
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(
            getFattureRicevute({
              request: {
                fatturaRicevutaSearchDto: { numeroOrdine: String(idOrdine), paging: { size: 9999, page: 0 } },
              },
              type: 'associated',
            })
          );
          onClose();
        }
      });
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Associate Costs to Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '75vh', maxHeight: '75vh', overflowY: 'auto' }}>
        {associatedProjects?.map((project) => {
          console.log('project', project);
          return (
            <React.Fragment key={`associate_project_${project.projectId}`}>
              <PassiveCycleProjectInvoiceCard
                readOnly={readOnly}
                project={project}
                handleChangeAssociated={handleAssociateCost}
              />
            </React.Fragment>
          );
        })}
      </Modal.Body>
      {!readOnly && (
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <LinksButton className="btn btn-links-outline" onClick={onClose}>
              {t('common.cancel')}
            </LinksButton>
            <LinksButton
              className="btn-primary btn-links"
              onClick={saveAssociation}
              isDisabled={externalCostsToAssociate.length === 0}
            >
              {t('common.associate')}
            </LinksButton>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AssociateCostsToPassiveInvoiceModal;

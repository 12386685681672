/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectElencoAssetDtoFromJSON, ProjectElencoAssetDtoToJSON, } from './ProjectElencoAssetDto';
/**
 * Check if a given object implements the ProjectElencoAssetDtoList interface.
 */
export function instanceOfProjectElencoAssetDtoList(value) {
    return true;
}
export function ProjectElencoAssetDtoListFromJSON(json) {
    return ProjectElencoAssetDtoListFromJSONTyped(json, false);
}
export function ProjectElencoAssetDtoListFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectElencoAssetDtoList': json['projectElencoAssetDtoList'] == null ? undefined : (json['projectElencoAssetDtoList'].map(ProjectElencoAssetDtoFromJSON)),
    };
}
export function ProjectElencoAssetDtoListToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectElencoAssetDtoList': value['projectElencoAssetDtoList'] == null ? undefined : (value['projectElencoAssetDtoList'].map(ProjectElencoAssetDtoToJSON)),
    };
}
